import React from 'react';

/**
 *
 * Notes
 *
 * Facets for...
 * Taxon rank: between [rank] and [rank]?
 * Subtaxa of: value
 * Authority: value
 * Phenology???
 * Habitat: values [AND/OR]
 * Elevation: between [low] and [high] || [above|below] [value]
 * Introduced: boolean
 * Special Status: value
 */

/**
 * This was implemented by copy-pasting the existing faceted search and 
 * adding another results section, filtered like this...

  const localResults = taxaResults.filter(result => {
    return allLocalTaxa.findIndex(lt => lt.entity === result.taxon.value) > -1;
  });
  const otherResults = taxaResults.filter(result => {
    return (
      allLocalTaxa.findIndex(lt => lt.entity === result.taxon.value) === -1
    );
  });

*/

export default function LocalFacetedSearch() {
  return <div>To be re-implemented</div>;
}

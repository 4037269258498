import { fetchAllResults } from './fetch-results';
import { APIResponse, BoundsLike } from './types';

export default async function fetchTaxonOccurrencesInBounds(
  taxonKey: number,
  bounds: BoundsLike,
): Promise<APIResponse> {
  const wktBounds = [
    bounds.getSouthWest(),
    bounds.getSouthEast(),
    bounds.getNorthEast(),
    bounds.getNorthWest(),
    bounds.getSouthWest(),
  ]
    .reduce(
      (wktArr, lngLat) => wktArr.concat(`${lngLat.lng} ${lngLat.lat}`),
      [] as string[],
    )
    .join(',');

  return fetchAllResults(
    `https://api.gbif.org/v1/occurrence/search?geometry=POLYGON((${wktBounds}))&kingdomKey=6&taxonKey=${taxonKey}`,
  );
}

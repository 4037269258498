import React from 'react';
import styled from 'styled-components';
import { Tab, Menu } from 'semantic-ui-react';

import { getPID, getQID, getUID } from 'actions/floracommons/pid-uid';
import {
  getClaimProvenances,
  groupClaimsByProvenance,
  groupPropertyClaimsByProvenance,
} from 'actions/floracommons/provenance';
import EntityLabel from 'components/wikibase-entity-label';
import Tabbed, { TabPaneProps } from './tabbed-provenance';
import Inline from './inline-provenance';
import { Claim } from 'wikibase-types/source';

const Container = styled.div``;

const PaneContainer = styled.div``;

const InlineHeader = styled.b`
  margin-right: 5px;
`;

type Props = {
  allClaims: Record<string, Claim[]>;
  hideProvenances: string[];
  provenances: string[];
};
export default function GeographyData(props: Props) {
  const { allClaims, hideProvenances, provenances } = props;

  if (
    !allClaims['taxon/distribution'] ||
    !allClaims['taxon/distribution'].length
  ) {
    return null;
  }

  return (
    <Tabbed
      allClaims={allClaims}
      provenances={provenances}
      hideProvenances={hideProvenances}
      paneComponent={GeographyDataPane}
    />
  );
}

function GeographyDataPane(props: TabPaneProps) {
  if (!props.allClaims) {
    throw new Error('allClaims not defined');
  }
  const fcProvId = getQID('provenance/fc');
  const provGroupedClaims = groupPropertyClaimsByProvenance(props.allClaims);

  const getClaims = (property: string) =>
    (props.provenance === getQID('provenance/fc')
      ? props.allClaims![property]
      : provGroupedClaims[props.provenance][property]) ?? [];

  const distributionClaims = getClaims('taxon/distribution');
  const elevationClaims = getClaims('taxon/elevation');
  const habitatClaims = getClaims('taxon/habitat');

  return (
    <Tab.Pane attached="top">
      {!distributionClaims.length || (
        <div>
          <InlineHeader>Distribution</InlineHeader>
          <Inline
            claims={distributionClaims}
            showToken={props.provenance === fcProvId}
          />
        </div>
      )}
      {!elevationClaims.length || (
        <div>
          <InlineHeader>Elevation</InlineHeader>
          <Inline
            claims={elevationClaims}
            showToken={props.provenance === fcProvId}
          />
        </div>
      )}
      {!habitatClaims.length || (
        <div>
          <InlineHeader>Habitat</InlineHeader>
          <Inline
            claims={habitatClaims}
            showToken={props.provenance === fcProvId}
          />
        </div>
      )}
    </Tab.Pane>
  );
}

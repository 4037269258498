import { fcEndpoint } from 'constants/endpoints';
import { wbApi } from '../_init';
import { getPID, getQID, getUID } from './pid-uid';

export type MorphStructureId = string;
export type MorphStructure = string;
export type MorphCharacterId = string;
export type MorphCharacter = string;
export type MorphValue = string;

/**
 * Fetch all plant structure properties without a [subproperty of] statement
 * @return {Array}      An array of results
 */
type TopLevelStructures = {
  structure: {
    value: MorphStructureId;
    label: MorphStructure;
    description: string;
    lowerCount: number;
  };
};

export async function getTopLevelStructures(
  queryOptions: { breakCache: boolean } = { breakCache: false },
): Promise<TopLevelStructures[]> {
  const url = wbApi.sparqlQuery(`
  SELECT ?structure ?structureLabel ?structureDescription (count(?sub) as ?structureLowerCount){
    SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
    ?structure wdt:${getPID('core/instance of')} "plant superstructure".

    OPTIONAL {?structure ^wdt:${getPID('core/substructure of')} ?sub.}

    OPTIONAL { ?structure schema:description ?structureDescription. }
    FILTER( NOT EXISTS {
      ?structure wdt:${getPID('core/substructure of')} ?parentStructure.
    })
 }
 GROUP BY ?structure ?structureLabel ?structureDescription
 ORDER BY ASC(?structureLabel)
`);

  return await fetch(`${url}${queryOptions.breakCache ? '&nocache=true' : ''}`)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());

      return data;
    })
    .catch(err => {
      console.error(err);
    });
}

/**
 * Return a list of characters with no parent (via `related character`)
 */
type TopLevelCharacters = {
  character: {
    value: MorphCharacterId;
    label: MorphCharacter;
  };
};
export async function getTopLevelCharacters(): Promise<TopLevelCharacters> {
  const url =
    wbApi.sparqlQuery(`# Get top level characters and their related superstructure  
  SELECT DISTINCT ?character ?characterLabel  WHERE {
    ?character wdt:${getPID('core/instance of')} "plant character".
    ?character rdfs:label ?characterLabel.

    FILTER( NOT EXISTS {
      ?character wdt:${getPID('core/subcharacter of')} ?noParent.
    })
  }
  ORDER BY ASC(?characterLabel)
`);

  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      /* {
      character: Object { value: "PID", label: "String" }
    } */
      return data;
    })
    .catch(err => {
      console.error(err);
    });
}

/**
 * Return a list of characters grouped by the top level related structure
 */
type TopLevelCharactersOfStructure = {
  character: {
    value: MorphCharacterId;
    label: MorphCharacter;
  };
};
export async function getTopLevelCharactersOfStructure(
  structureId: string,
): Promise<TopLevelCharactersOfStructure[]> {
  // this query is much faster, but returns all characters, so needs some filtering
  /* eslint-disable */
  const url = wbApi.sparqlQuery(`# 
    # Get top level characters and their related superstructure  
    SELECT DISTINCT ?character ?characterLabel WHERE {
      ?character wdt:${getPID('core/instance of')} "plant character".
      ?character ^wdt:${getPID("core/related character")} ?relatedStructure.
      ?relatedStructure wdt:${getPID("core/substructure of")}* wd:${structureId}.

      ?character rdfs:label ?characterLabel.

      FILTER( NOT EXISTS {
        ?character wdt:${getPID('core/subcharacter of')} ?noParent.
      })
    }
    ORDER BY ASC(?characterLabel)
`);
  /* eslint-enable */
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      /* {
      character: Object { value: "PID", label: "String" }
    } */
      // console.log('getTopLevelCharacters', data)
      return data; //.map(row => row.character);
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

type AllStructureCharacterValues = {
  value: MorphValue;
}[];

export async function getAllValuesForStructureAndCharacter(
  structureId: string,
  characterId: string,
  taxaIds: string[] = [],
): Promise<AllStructureCharacterValues> {
  /* eslint-disable */
  const url = wbApi.sparqlQuery(`
   SELECT DISTINCT ?value WHERE {
    ${taxaIds.length ? `
    VALUES ?taxa {${taxaIds.map(id => `wd:${id}`).join(' ')}}
    ?taxa ^wdt:${getPID('taxon/parent taxon')}*/p:${getPID('taxon/morphology statement')}  _:st.` : ''}
    _:st pq:${getPID("taxon/morphology statement value")} ?value.
    _:st pq:${getPID("taxon/morphology statement structure")}/wdt:${getPID("core/substructure of")}* wd:${structureId}.
    _:st pq:${getPID("taxon/morphology statement character")}/wdt:${getPID('core/subcharacter of')}* wd:${characterId}.
  } 
  ORDER BY ASC(?value)
   `);
   /* eslint-enable */
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      /* {
      value: "value string"
    } */
      console.log('Values', data);
      return data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

type SubPropertyList = {
  id: string;
  label: string;
  description: string;
}[];

export async function fetchSubMorphOf(
  type: string,
  parentId: string,
  all: boolean = false,
): Promise<SubPropertyList> {
  const subMorphProperty =
    type === 'character'
      ? getPID('core/subcharacter of')
      : getPID('core/substructure of');
  /* eslint-disable */
    const url = wbApi.sparqlQuery(`
    SELECT DISTINCT 
    ?id ?label ?description
      WHERE {
        ?id wdt:${subMorphProperty} wd:${parentId}.
        ?id rdfs:label ?label;
        FILTER(LANG(?label) = "en")
        OPTIONAL {
          ?id schema:description ?description.
          FILTER(LANG(?description) = "en")
        }
      }
      ORDER BY ASC(?label)`);
    /* eslint-enable */
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      return data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

type StructureHierarchySimplifiedResult = {
  structure: {
    value: string;
    label: string;
    description: string;
  };
  parent: string;
  numSub: number;
  isSuper: string;
};
export type Structure = {
  id: string;
  label: string;
  isSuper: boolean;
  parentId: string;
  description: string;
  substructureCount: number;
  substructures: Structure[];
  parent?: Structure;
  statementCount: number;
};
export type StructureTree = Structure[];
/**
 * Fetch and assemble a structure hierarchy tree
 * @returns Structure hierarchy tree
 */
export async function fetchStructureHierarchy(): Promise<StructureTree> {
  const url = wbApi.sparqlQuery(`
  SELECT ?structure ?structureLabel ?structureDescription ?isSuper ?parent (count(?sub) as ?numSub) {
    SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
    ?structure wdt:${getPID('core/instance of')} "plant structure".
    OPTIONAL {?structure wdt:${getPID('core/substructure of')} ?parent.}

    OPTIONAL {?structure ^wdt:${getPID('core/substructure of')} ?sub.}
    
    OPTIONAL { ?structure schema:description ?structureDescription. }

    OPTIONAL {
      ?structure wdt:P5 "plant superstructure" .
      BIND("yes" as ?isSuper)
  }

  }
  GROUP BY ?structure ?structureLabel ?parent ?isSuper ?structureDescription
  ORDER BY ASC(?structureLabel)`);

  const mainRequest = fetch(url).catch(err => {
    console.error(err);
    throw new Error(err);
  });

  return Promise.all([mainRequest, fetchStructuresWithStatementCounts()]).then(
    async ([response, statementCounts]) => {
      const statementCountMap = new Map<string, number>(
        statementCounts.map(row => [row.structureId, row.statementCount]),
      );

      const data = wbApi.simplify.sparqlResults(
        await response.json(),
      ) as StructureHierarchySimplifiedResult[];

      // build a hash table indexed by structure id
      const hashTable: Record<string, Structure> = Object.create(null);
      data.forEach(
        row =>
          (hashTable[row.structure.value] = {
            id: row.structure.value,
            label: row.structure.label,
            isSuper: !!row.isSuper,
            parentId: row.parent,
            description: row.structure.description,
            substructureCount: row.numSub,
            substructures: [],
            statementCount: statementCountMap.get(row.structure.value) || 0,
          }),
      );
      const tree: Structure[] = [];
      data.forEach(row => {
        // add substructures to their parent structure
        if (row.parent) {
          hashTable[row.parent].substructures.push(
            hashTable[row.structure.value],
          );
          hashTable[row.structure.value].parent = hashTable[row.parent];
          // add top level structures to the top of the tree
        } else tree.push(hashTable[row.structure.value]);
      });

      return tree;
    },
  );
}

type MorphologyForTaxonRow = {
  st: string;
  structure: {
    value: string;
    label: string;
    constraint: string;
  };
  superStructure: {
    value: string;
    label: string;
  };
  character: {
    value: string;
    label: string;
  };
  value: string;
  valueFrom: string;
  valueTo: string;
};

export async function fetchMorpologyForTaxon(
  taxonId: string,
): Promise<MorphologyForTaxonRow[]> {
  const url = wbApi.sparqlQuery(`
SELECT ?st ?structure ?structureLabel ?structureConstraint ?superStructure ?superStructureLabel ?character ?characterLabel ?valueType ?valueModifier ?value ?valueFrom ?valueTo WHERE {
  SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
  BIND(<${fcEndpoint.instance}/entity/${taxonId}> AS ?entity)
  ?entity p:${getPID('taxon/morphology statement')} ?st.
  ?st 
    wdt:${getPID('taxon/morphology statement')} ?valueType.
    pq:${getPID('taxon/morphology statement structure')} ?structure;
    pq:${getPID('taxon/morphology statement character')} ?character;
    pq:${getPID('taxon/morphology statement value ')} ?value.
  
  OPTIONAL {
    ?structure wdt:${getPID('core/substructure of')}* ?superStructure;
              wdt:${getPID('core/instance of')} "plant superstructure".
      FILTER( NOT EXISTS {
        ?superStructure wdt:${getPID('core/substructure of')} ?noParent.
      })
    }
  OPTIONAL { ?st pq:${getPID(
    'taxon/morphology statement value to',
  )} ?valueModifier. }
  OPTIONAL { 
    ?st pq:${getPID(
      'taxon/morphology statement structure constraint',
    )} ?structureConstraint.
  } 
  OPTIONAL { 
    ?st pq:${getPID('taxon/morphology statement value from')} ?valueFrom; 
        pq:${getPID('taxon/morphology statement value to')} ?valueTo
  }
}`);

  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(
        await response.json(),
      ) as MorphologyForTaxonRow[];

      return data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

type AllCharactersForStructureRow = {
  id: string;
  label: string;
};

export async function fetchAllCharactersForStructure(
  structureId: string,
  includeSubstructures: boolean = true,
): Promise<AllCharactersForStructureRow[]> {
  const url = wbApi.sparqlQuery(`
  SELECT DISTINCT 
   ?character ?characterLabel WHERE {
      wd:${structureId} 
        ${
          includeSubstructures
            ? `^wdt:${getPID('core/substructure of')}* /`
            : ''
        } 
        ^pq:${getPID('taxon/morphology statement structure')} ?st.
      ?st ps:${getPID('taxon/morphology statement')} wd:${getQID(
    'taxon/simple value',
  )}.
      ?st pq:${getPID('taxon/morphology statement structure')} ?structure.
      ?st pq:${getPID('taxon/morphology statement character')} ?character.
      ?structure rdfs:label ?structureLabel.
      ?character rdfs:label ?characterLabel.
    }
    ORDER BY ?characterLabel
  `);

  return fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());

      return data.map((row: any) => ({
        id: row.character.value,
        label: row.character.label,
      }));
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

type StructureWithStatementCount = {
  structureId: string;
  statementCount: number;
};
export async function fetchStructuresWithStatementCounts(): Promise<
  StructureWithStatementCount[]
> {
  const url = wbApi.sparqlQuery(`
   SELECT DISTINCT ?structure (COUNT(?st) AS ?count) WHERE {
    ?structure ^pq:${getPID('taxon/morphology statement structure')} ?st;
  } GROUP BY ?structure
  `);

  return fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());

      return data.map((row: any) => ({
        structureId: row.structure,
        statementCount: row.count,
      }));
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

import { fetchAllResults, fetchPagedResults } from './fetch-results';
import { APIResponse, BoundsLike } from './types';

export async function fetchImageryForTaxon(
  taxonKey: number,
  numImages: number = 50,
): Promise<APIResponse> {
  return fetchPagedResults(
    `https://api.gbif.org/v1/occurrence/search?taxonKey=${taxonKey}&mediaType=StillImage`,
    0,
    numImages,
  );
}

export async function fetchAllImageryForTaxon(
  taxonKey: number,
): Promise<APIResponse> {
  return fetchAllResults(
    `https://api.gbif.org/v1/occurrence/search?taxonKey=${taxonKey}&mediaType=StillImage`,
  );
}

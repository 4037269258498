import React from 'react';
import styled from 'styled-components';
import { Dropdown, Header, DropdownProps } from 'semantic-ui-react';

const Container = styled.div`
  margin-bottom: 10px;
`;
const Title = styled(Header)`
  margin-bottom: 0;
`;

export type FacetDropdownValue = {
  key: string;
  text: string;
  value: string;
};

interface Props {
  title: string;
  helpText?: string;
  values: FacetDropdownValue[];
  selectedValues: string[];
  loading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}

interface SingleProps extends Props {
  multiple: false | undefined;
  onChange: (value: string) => void;
}

interface MultiProps extends Props {
  multiple: true;
  onChange: (values: string[]) => void;
}
export default function ValueDropdownFacet(props: SingleProps | MultiProps) {
  const {
    title,
    helpText,
    values,
    selectedValues,
    multiple = false,
    loading = false,
    disabled = false,
    onChange,
  } = props;

  const handleValueChange = (e: any, { value }: any) => {
    onChange(value);
  };

  return (
    <Container>
      <Title as="h5">{title}</Title>
      <Dropdown
        placeholder="Values"
        fluid
        multiple={multiple}
        search
        selection
        disabled={disabled}
        loading={loading}
        options={values.map(value =>
          typeof value === 'string'
            ? {
                key: value,
                text: value,
                value: value,
              }
            : value,
        )}
        value={selectedValues}
        onChange={handleValueChange}
      />
    </Container>
  );
}

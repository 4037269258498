import React, { useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Segment, Placeholder } from 'semantic-ui-react';

import { fcEndpoint } from 'constants/endpoints';
import { FillBox } from 'components/ui/Box';
import LayoutWidth from 'components/layout-width';
import NavFrame from 'components/nav-frame';
import ErrorBoundary from 'components/error-boundary';
// import { getClaimProvenances } from "actions/floracommons/provenance";
import { fetchTaxonById, TaxonDetail } from 'actions/floracommons/get-taxon';

import TaxonHierarchy from './hierarchy';
import InlineProvenance from './inline-provenance';
import TabbedProvenance from './tabbed-provenance';
import ProvenanceFilter from './provenance-filter';
import Description from './description';
import Geography from './geography';
import MorphData from './morph-data';
import OtherData from './other-data';
import DistributionMap from './map';
import Media from './images';

import {
  ClaimSnakEntityValue,
  ClaimSnakStringValue,
} from 'wikibase-types/source';
import { getClaimProvenances } from 'actions/floracommons/provenance';
import { getPID, getQID } from 'actions/floracommons/pid-uid';

export default function Taxon() {
  const { id } = useParams();
  if (id === undefined) {
    return null;
  }
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [taxonData, setTaxonData] = useState<TaxonDetail | undefined>(
    undefined,
  );
  const [provenanceFilters, setProvenanceFilters] = useState({});

  useEffect(() => {
    setLoading(true);
    async function load() {
      fetchTaxonById(id as string)
        .then(applyTaxonData)
        .catch(e => setError(true))
        .finally(() => setLoading(false));
    }
    load();
  }, [id]);

  const applyTaxonData = (data: TaxonDetail) => {
    setTaxonData(data);
    setProvenanceFilters(
      Object.fromEntries(data.provenances.map(p => [p, true])),
    );
  };

  const Prov = ({ id, taxonData }: { id: string; taxonData: TaxonDetail }) => {
    return <span>{taxonData.provenances.indexOf(id) + 1}</span>;
  };

  const hideProvenances = Object.entries(provenanceFilters)
    .filter(([id, enabled]) => enabled === false)
    .map(([id]) => id);

  // const presentIn = ['CA-BC'];
  const presentIn: string[] = [];

  //TODO move this into get-taxon, direct manipulation of wikibase claims should be abstracted
  if (taxonData && taxonData.claims['taxon/distribution']?.length) {
    presentIn.push(
      ...taxonData.claims['taxon/distribution'].reduce(
        (values: string[], claim) => {
          const provs = getClaimProvenances(claim);
          const iso31662QID = getQID('standard/ISO3166-2');
          const standard = getPID('core/format');
          // filter out any values which aren't ISO3166-2 strings
          if (
            !claim?.qualifiers?.[standard] ||
            !claim?.qualifiers?.[standard].find(
              val =>
                (val?.datavalue as ClaimSnakEntityValue)?.value?.id ===
                iso31662QID,
            )
          ) {
            return values;
          }
          // console.log(claim.mainsnak);
          const distValue = (claim.mainsnak.datavalue as ClaimSnakStringValue)
            ?.value;
          if (hideProvenances.indexOf(provs[0].id) === -1) {
            values.push(distValue);
          }
          return values;
        },
        [] as string[],
      ),
    );
  }

  const externalLinks: [string, string][] = [];
  if (taxonData?.identifiers?.vascan)
    externalLinks.push([
      `https://data.canadensys.net/vascan/taxon/${taxonData.identifiers.vascan}?lang=en`,
      'VASCAN',
    ]);
  if (taxonData?.identifiers?.gbif)
    externalLinks.push([
      `https://www.gbif.org/species/${taxonData.identifiers.gbif}`,
      'GBIF',
    ]);

  function renderTaxon() {
    if (!taxonData) {
      return null;
    }
    return (
      <NavFrame>
        <TaxonContainer>
          <LayoutWidth>
            <a id="top"></a>
            <TaxonId>
              <TaxonName>{taxonData.name}</TaxonName>
              {taxonData['taxon/authority'] && (
                <TaxonAuthority>
                  {taxonData['taxon/authority'].value}
                </TaxonAuthority>
              )}
            </TaxonId>

            <TaxonHierarchy taxonId={id as string} />

            {/* {!taxonData['taxon/rank'] || <TaxonRank>
          <InlineHeader>Rank</InlineHeader>
          <EntityLabel id={taxonData['taxon/rank'].id} />
        </TaxonRank>} */}

            {/* <em>{taxonData.commonName || 'No common name'}</em> */}
            {/* {!taxonData.claims['taxon/synonym']?.length || (
              <Synonyms>
                <InlineHeader>Synonyms</InlineHeader>
                <InlineProvenance
                  claims={taxonData.claims['taxon/synonym']}
                  hideProvenances={hideProvenances}
                />
              </Synonyms>
            )} */}
            {/* {!taxonData.claims['taxon/basionym']?.length || (
              <Synonyms>
                <InlineHeader>Basionym</InlineHeader>
                <InlineProvenance
                  claims={taxonData.claims['taxon/basionym']}
                  hideProvenances={hideProvenances}
                />
              </Synonyms>
            )} */}
            {!taxonData.claims['taxon/common name']?.length || (
              <Synonyms>
                <InlineHeader>Common Names</InlineHeader>
                <InlineProvenance
                  claims={taxonData.claims['taxon/common name']}
                  hideProvenances={hideProvenances}
                />
              </Synonyms>
            )}

            <ProvenanceList>
              <InlineHeader>External Links</InlineHeader>
              {/* {taxonData.provenances.map(id => (
                <EntityLabel id={id} key={id}></EntityLabel>
              ))} */}

              {externalLinks.map(([link, label]) => (
                <ExternalLink
                  key={label}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {label}
                </ExternalLink>
              ))}
            </ProvenanceList>

            <br />
            {taxonData.identifiers.gbif && <Media taxonData={taxonData} />}
            <PageNavigation>
              <h3>Jump To</h3>
              <ol>
                <PageNavigationItem to="top">Top</PageNavigationItem>
                <PageNavigationItem to="description">
                  Description
                </PageNavigationItem>
                <PageNavigationItem to="distribution">
                  Distribution
                </PageNavigationItem>
                <PageNavigationItem to="discussion">
                  Discussion
                </PageNavigationItem>
                <PageNavigationItem to="morphology-data">
                  Morphology Data
                </PageNavigationItem>
                <PageNavigationItem to="other-data">
                  Other Data
                </PageNavigationItem>
                <PageNavigationItem to="sources">Sources</PageNavigationItem>
              </ol>

              <ProvenanceFilter
                provenances={provenanceFilters}
                onChange={setProvenanceFilters}
              />
            </PageNavigation>

            {(taxonData.identifiers.gbif ||
              taxonData.claims['taxon/distribution']?.length) && (
              <Section id="distribution-map">
                <ErrorBoundary>
                  <DistributionMap
                    hideProvenances={hideProvenances}
                    presentIn={presentIn}
                    taxonData={taxonData}
                  />
                </ErrorBoundary>
              </Section>
            )}

            <Section id="description">
              <SectionHeader>Description</SectionHeader>
              {taxonData.claims['taxon/description/fragment'] ? (
                <Description
                  allClaims={taxonData.claims}
                  claims={taxonData.claims['taxon/description/fragment']}
                  hideProvenances={hideProvenances}
                />
              ) : (
                <TabbedNoData>None.</TabbedNoData>
              )}
            </Section>

            <Section id="distribution">
              <SectionHeader>
                Distribution, Elevation &amp; Habitat
              </SectionHeader>
              <Geography
                allClaims={taxonData.claims}
                provenances={taxonData.provenances}
                hideProvenances={hideProvenances}
              />
            </Section>

            <Section id="discussion">
              <SectionHeader>Discussion</SectionHeader>
              {taxonData.claims['taxon/discussion'] ? (
                <TabbedProvenance
                  claims={taxonData.claims['taxon/discussion']}
                  hideProvenances={hideProvenances}
                />
              ) : (
                <TabbedNoData>None.</TabbedNoData>
              )}
            </Section>

            <Section id="morphology-data">
              <SectionHeader>Morphology Data</SectionHeader>
              {taxonData.claims['taxon/morphology statement'] ? (
                <MorphData
                  claims={taxonData.claims['taxon/morphology statement']}
                  hideProvenances={hideProvenances}
                />
              ) : (
                <TabbedNoData>None.</TabbedNoData>
              )}
            </Section>

            <Section id="other-data">
              <SectionHeader>Other Data</SectionHeader>
              <OtherData
                allClaims={taxonData.claims}
                hideProvenances={hideProvenances}
              />
            </Section>

            <Section id="wikibase">
              Raw data can be viewed, and edits submitted, at the FloraCommons
              WikiBase Entity{' '}
              <a
                href={`${fcEndpoint.instance}/wiki/Item:${id}`}
                target="_blank"
                rel="noreferrer"
              >
                {id}
              </a>
            </Section>
          </LayoutWidth>
        </TaxonContainer>
      </NavFrame>
    );
  }

  if (isError) {
    return <Main>Failed to load taxon.</Main>;
  }

  return (
    <>
      <Helmet>
        <title>
          {isLoading || !taxonData ? id : `${taxonData.name} (${id})`} |
          FloraCommons
        </title>
      </Helmet>
      {isLoading ? <TaxonPlaceholder /> : renderTaxon()}
    </>
  );
}

const Main = styled(FillBox)`
  display: flex;
  flex-direction: column;
`;
const Section = styled.section`
  margin-top: 3em;
`;
const SectionHeader = styled.h2``;
const SectionBody = styled.div``;

const TaxonPlaceholder = styled(({ className, children, ...props }) => (
  <Placeholder fluid className={className}>
    {new Array(20).fill(true).map((a, i) => (
      <Placeholder.Header key={i}>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    ))}
  </Placeholder>
))``;

const TaxonContainer = styled.div`
  width: 100%;
`;

const PageNavigation = styled.nav`
  position: sticky;
  top: 0px;
  z-index: 5;
  background: #eee;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  padding: 5px 0;
  align-items: center;
  h3 {
    margin: 0;
    padding: 0;
    margin-right: 1em;

    font-size: 1em;
    text-transform: uppercase;
    font-weight: normal;
  }
  ol {
    flex: 1;
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin: 0 1em 0 0;
    padding: 0;
  }
`;
const PageNavigationItem = ({
  to,
  children,
  ...props
}: {
  to: string;
  children: ReactNode;
}) => {
  return (
    <li {...props}>
      <a href={`#${to}`}>{children}</a>
    </li>
  );
};

const TaxonId = styled.header`
  display: flex;
  flex-direction: row;
  margin-top: 3em;
  line-height: 3em;
  align-items: baseline;
`;
const TaxonName = styled.h1`
  font-size: 3em;
  /* flex: 1; */
  font-weight: normal;
  margin: 0 10px 0 0;
`;
const TaxonAuthority = styled.h4`
  font-size: 1.8em;
  line-height: 3em;
  margin: 0;
  font-weight: normal;
`;
// const TaxonDescription = styled.div`

const InlineHeader = styled.b`
  margin-right: 5px;
`;
const ProvenanceList = styled.div`
  span {
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '.';
    }
  }
`;
const Synonyms = styled.div``;

const TabbedNoData = styled(Segment)`
  background: #fff;
`;

const ExternalLink = styled.a`
  ::after {
    content: ', ';
  }
  :last-child::after {
    content: '.';
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Icon,
  Dropdown,
  List,
  Placeholder,
  Loader,
  Label,
  Input,
  Card,
  DropdownItemProps,
  StrictDropdownProps,
} from 'semantic-ui-react';

import { getAllValuesForStructureAndCharacter } from 'actions/floracommons/morphology';
import { MorphFacet } from 'actions/floracommons/taxa-facets';
import { FacetDropdownValue } from './facet-creators/value-dropdown';

const FacetRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
`;

export type MorphFacetFields = [
  string | undefined,
  string | undefined,
  string[],
];

interface Props {
  allStructures: { id: string; label: string }[];
  allCharacters: { id: string; label: string }[];
  structureCharacters: Record<string, string[]>;
  structure: string | undefined;
  character: string | undefined;
  values: string[] | undefined;
  loading: boolean;
  onChange: (facet: MorphFacetFields) => void;
  onRemove: () => void;
  fetchValues: (structureId: string, characterId: string) => Promise<string[]>;
}

// interface DropdownProps extends StrictDropdownProps {
//   value: string;
// }
// interface MultiDropdownProps extends StrictDropdownProps {
//   value: string[];
// }

export default function FacetRow(props: Props) {
  const {
    allStructures,
    allCharacters,
    structureCharacters,
    structure,
    character,
    values,
    loading,
    onChange,
    onRemove,
    fetchValues,
  } = props;

  const [isFetchingValues, setFetchingValues] = useState(false);

  const [structures, setStructures] = useState<FacetDropdownValue[]>([]);
  const [characters, setCharacters] = useState<FacetDropdownValue[]>([]);
  const [structureCharacterValues, setStructureCharacterValues] = useState<
    string[]
  >([]);

  const [selectedStructure, setSelectedStructure] = useState<
    string | undefined
  >();
  const [selectedCharacter, setSelectedCharacter] = useState<
    string | undefined
  >();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  // set values on mount
  useEffect(() => {
    setStructures(selectStructures());
    const reset = () => {
      setSelectedStructure(undefined);
      setCharacters([]);
      setSelectedCharacter(undefined);
      setStructureCharacterValues([]);
      setSelectedValues([]);
    };

    // try to update the structure and character lists, while keeping the selected
    // value if it's still in the list
    // otherwise unset the selected value
    if (selectedStructure) {
      if (structureCharacters[selectedStructure]) {
        setCharacters(selectStructureCharacters(selectedStructure));
        if (
          selectedCharacter &&
          structureCharacters[selectedStructure].indexOf(selectedCharacter) > -1
        ) {
          // structure and character are both in the updated lists
          populateValues(selectedStructure, selectedCharacter).then(
            availableValues => {
              if (selectedValues.length && availableValues.length) {
                const newValues = selectedValues.filter(
                  v => availableValues.indexOf(v) > -1,
                );
                if (newValues.length) {
                  setSelectedValues(newValues);
                  // structure, character and at least one value are all still valid,
                  // trigger a change event
                  onChange([selectedStructure, selectedCharacter, newValues]);
                }
              }
            },
          );
        } else {
          setSelectedCharacter(undefined);
        }
      } else {
        reset();
      }
    } else {
      reset();
    }
  }, [structureCharacters]);

  // set structure and character on mount
  useEffect(() => {
    if (structure) {
      setSelectedStructure(structure);
    }
    if (structure && character) {
      setCharacters(selectStructureCharacters(structure));
      setSelectedCharacter(character);
    }
  }, [structure, character, values]);

  // set values on mount
  useEffect(() => {
    const init = async () => {
      if (structure && character && values) {
        setSelectedValues(values);
        await populateValues(structure, character);
      }
    };
    init();
  }, [structure, character]);

  const selectStructures = () => {
    const availableStructures = Object.keys(structureCharacters);
    return allStructures
      .filter(structure => availableStructures.indexOf(structure.id) > -1)
      .map(structure => ({
        key: structure.id,
        text: structure.label,
        value: structure.id,
      }));
  };

  const selectStructureCharacters = (structureId: string) => {
    return structureCharacters[structureId].map(characterId => ({
      key: characterId,
      value: characterId,
      text:
        allCharacters.find(c => c.id === characterId)?.label || 'Label Missing',
    }));
  };

  const populateValues = async (structureId: string, characterId: string) => {
    setFetchingValues(true);
    // fetch all values of the selected property and its subproperties
    const allValues = await fetchValues(structureId, characterId);
    if (!allValues) {
      setFetchingValues(false);
      throw new Error('Query for values returned no results');
    }
    setStructureCharacterValues(allValues);
    setFetchingValues(false);
    return allValues;
  };

  const handleStructureSelect = (e: any, data: StrictDropdownProps) => {
    const structureId = data.value as string;
    setSelectedStructure(structureId);
    setSelectedCharacter(undefined);
    setStructureCharacterValues([]);
    setSelectedValues([]);
    // setFetchingSub(true);
    setCharacters(selectStructureCharacters(structureId));
  };

  const handleCharacterSelect = (e: any, data: StrictDropdownProps) => {
    if (!selectedStructure) return;
    const characterId = data.value as string;
    setSelectedCharacter(characterId);
    setStructureCharacterValues([]);
    setSelectedValues([]);
    populateValues(selectedStructure, characterId);
  };
  const handleValueChange = (e: any, data: StrictDropdownProps) => {
    if (!selectedStructure || !selectedCharacter) return;
    const values = data.value as string[];
    setSelectedValues(values);
    onChange([selectedStructure, selectedCharacter, values]);
  };

  const handleRemoveClick = () => {
    onRemove();
  };

  return (
    <FacetRowContainer>
      <Dropdown
        placeholder="Structure"
        search
        selection
        options={structures}
        loading={loading}
        disabled={loading}
        value={selectedStructure}
        onChange={handleStructureSelect}
      />
      <Dropdown
        placeholder="Character"
        search
        selection
        options={characters}
        disabled={loading}
        loading={loading}
        value={selectedCharacter}
        onChange={handleCharacterSelect}
      />
      <Dropdown
        placeholder="Values"
        multiple
        search
        selection
        disabled={!structureCharacterValues.length}
        loading={isFetchingValues}
        options={structureCharacterValues.map(value => ({
          key: value,
          text: value,
          value,
        }))}
        value={selectedValues}
        onChange={handleValueChange}
        style={{ flex: 1 }}
      />
      <Button icon="remove" onClick={handleRemoveClick} />
      {/* <Dropdown
        text='Sub structure'
        disabled={!selectedStructure}
        icon='filter'
        floating
        labeled
        button
        className='icon'
      >
        <Dropdown.Menu>
          <Input icon='search' iconPosition='left' className='search' />
          <Dropdown.Divider />
          <Dropdown.Menu scrolling>
            {renderSubPropertyTreeDropDown(subPropertyTree)}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown> */}
    </FacetRowContainer>
  );
}

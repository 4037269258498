import { wbApi } from '../_init';
import { getPID } from './pid-uid';

type TaxonResult = {
  taxon: {
    value: string;
    name: string;
  };
  authority: string;
};
export async function searchTaxaByName(
  partialTaxonName: string,
): Promise<TaxonResult[]> {
  const url = wbApi.sparqlQuery(`SELECT ?taxon ?taxonName ?authority{
    ?taxon wdt:${getPID('core/instance of')} "taxon". 
    ?taxon wdt:${getPID('taxon/name')} ?taxonName.
    ?taxon wdt:${getPID('taxon/authority')} ?authority.
    BIND (STRLEN(?taxonName) AS ?strlen)
    FILTER (REGEX(?taxonName, "${partialTaxonName}", "i"))
  } ORDER BY ASC(?strlen) ASC(?taxonName) LIMIT 20 `);
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      console.log('Taxon', data);
      return data;
    })
    .catch(err => {
      console.error(err);
    });
}

import { getHuntsTerm } from 'actions/floracommons/hunts';
import fetchEntityLabel from 'actions/wikibase/fetch-entity-label';
import React, { useEffect, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

export default function HuntsTerm(props: { children: string }) {
  const [showDefinition, setShowDefinition] = useState<boolean>(false);
  return (
    <Popup
      trigger={<Outer>{props.children}</Outer>}
      on="click"
      content={<Definition term={props.children} />}
      // popperDependencies={[tab]}
      wide="very"
    />
  );
  //  (
  //   <Outer onClick={() => setShowDefinition(!showDefinition)}>
  //     {props.children}
  //   </Outer>
  // );
}

function Definition(props: { term: string }) {
  const [definitions, setDefinitions] =
    useState<Awaited<ReturnType<typeof getHuntsTerm>>>();
  const [isLoaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(false);
    setDefinitions(undefined);
    getHuntsTerm(props.term).then(results => {
      if (results.length) {
        setDefinitions(results);
      }
      setLoaded(true);
    });
  }, [props.term]);

  return (
    <div>
      {isLoaded ? (
        definitions ? (
          <div>
            {definitions.map((d, i) => (
              <p key={i}>
                <b>{d.term}</b>
                {d.termNumber && <span>({d.termNumber})</span>}: {d.definition}
              </p>
            ))}
          </div>
        ) : (
          'No definition'
        )
      ) : (
        <Icon loading name="spinner" />
      )}
    </div>
  );
}

const Outer = styled.dfn`
  font-style: normal;
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: help;
`;

import listOfTerms from 'cache/terms.json';
//terms.json is a list of terms only, with a small file size to embed in bundle
//public/hunts/* containts the full glossary, which is ~500kb and is loaded asynchronously
// when it's first required

type HuntsTermRow = [
  string, //term
  string, //catgeory
  string, //term_index
  string, //term_alt
  string, //term_plural
  string, //term_notes
  string, //synonym
  string, //limitation
  string, //definition
  string, //term_original
];

type HuntsTerm = {
  term: string;
  category: string;
  termNumber: string;
  alternate: string;
  plural: string;
  notes: string;
  synyonym: string;
  limitation: string;
  definition: string;
  original: string;
};

const huntsTermsList: string[] = listOfTerms;

// hold copy of hunts terms outside of
let hunts: HuntsTerm[] = [];
let huntsTerms = new Map<string, HuntsTerm[]>();
let huntsCategories = new Map<string, HuntsTerm[]>();
let fetchPromise: Promise<any> | undefined;

export function isHuntsTerm(term: string) {
  if (!term || !term.toLocaleLowerCase) {
    return false;
  }
  // simple string match
  // @TODO : add prefix/suffix matching, string normalisation
  return huntsTermsList.indexOf(term.trim().toLowerCase()) > -1;
}

export async function getHuntsTerm(term: string, category?: string) {
  await fetchHunts();
  const matchingTerms = hunts.filter(entry =>
    category
      ? entry.category === category && entry.term === term
      : entry.term === term,
  );
  if (matchingTerms) {
    return matchingTerms;
  }
  return [];
}

export async function getHuntsTerms(category?: string): Promise<HuntsTerm[]> {
  await fetchHunts();
  if (category) {
    if (!huntsCategories.has(category)) {
      throw new Error(`Invalid hunts category: ${category}`);
    }
    return huntsCategories.get(category) || [];
  }
  return hunts;
}

export async function getHuntsCategories(): Promise<string[]> {
  await fetchHunts();
  return [...huntsCategories.keys()];
}

export async function fetchHunts() {
  // ensure multiple calls to fetchHunts only result in one request
  if (!hunts.length) {
    if (!fetchPromise) {
      fetchPromise = fetch('/hunts/hunts-modified.json')
        .then((response: any) => response.json())
        .then((terms: HuntsTermRow[]) => {
          hunts.push(
            ...terms.map(row => ({
              term: row[0],
              category: row[1],
              termNumber: row[2],
              alternate: row[3],
              plural: row[4],
              notes: row[5],
              synyonym: row[6],
              limitation: row[7],
              definition: row[8],
              original: row[9],
            })),
          );
          hunts.forEach(term => {
            huntsTerms.set(
              term.term,
              huntsTerms.has(term.term)
                ? huntsTerms.get(term.term)!.concat([term])
                : [term],
            );
            huntsCategories.set(
              term.category,
              huntsCategories.has(term.category)
                ? huntsCategories.get(term.category)!.concat([term])
                : [term],
            );
          });
        });
    }
    await fetchPromise;
  }
  // when the request is processed, return hunts
  return hunts;
}

import React, { useState } from 'react';
import styled from 'styled-components';
import { Structure } from 'actions/floracommons/morphology';
import { fcEndpoint } from 'constants/endpoints';
import RelatedCharacters from './related-characters';
import { structureTreeHasStatements } from '.';

// replace
function formatStructureLabel(parent: Structure, structure: Structure) {
  const regexp = new RegExp(`^${parent.label} `);
  return structure.label.replace(regexp, '');
}

type Props = {
  structure: Structure;
  simplifyHierarchy: boolean;
  showUnusedStructures: boolean;
  selectedSubstructure?: Structure;
  onSelectSubstructure: (structure: Structure) => void;
};

export default function StructureWrapper(props: Props) {
  if (
    (props.showUnusedStructures && props.structure.substructureCount > 0) ||
    (!props.showUnusedStructures &&
      props.structure.substructureCount > 0 &&
      props.structure.substructures.some(structureTreeHasStatements))
  ) {
    return <WithSubstructures {...props} />;
  } else {
    return <WithoutSubstructures {...props} />;
  }
}

function WithoutSubstructures(props: Props) {
  return (
    <ContainerWithoutSubstructures>
      <StructureInfo
        structure={props.structure}
        simplifyHierarchy={props.simplifyHierarchy}
        showUnusedStructures={props.showUnusedStructures}
      />
    </ContainerWithoutSubstructures>
  );
}

function WithSubstructures(props: Props) {
  const { selectedSubstructure, simplifyHierarchy, showUnusedStructures } =
    props;

  return (
    <ContainerWithSubstructures>
      <SubstructureListContainer>
        {props.structure.substructures
          .filter(structure =>
            showUnusedStructures ? true : structureTreeHasStatements(structure),
          )
          .map(structure =>
            !simplifyHierarchy && structure.substructureCount > 0 ? (
              <SubStructureTree
                key={structure.id}
                structure={structure}
                selectedSubstructure={selectedSubstructure}
                onSelect={structure => props.onSelectSubstructure(structure)}
              />
            ) : (
              <SubstructureListItem
                key={structure.id}
                onClick={() => props.onSelectSubstructure(structure)}
                isActive={structure.id === selectedSubstructure?.id}
              >
                <div>{formatStructureLabel(props.structure, structure)}</div>
              </SubstructureListItem>
            ),
          )}
      </SubstructureListContainer>
      <StructureInfo
        structure={selectedSubstructure || props.structure}
        simplifyHierarchy={simplifyHierarchy}
        showUnusedStructures={props.showUnusedStructures}
      />
    </ContainerWithSubstructures>
  );
}

function SubStructureTree(props: {
  structure: Structure;
  selectedSubstructure?: Structure;
  onSelect: (structure: Structure) => void;
  className?: string;
  depth?: number;
}) {
  const {
    structure,
    onSelect,
    selectedSubstructure,
    className,
    depth = 0,
  } = props;

  return (
    <SubstructureTreeContainer depth={depth}>
      <SubstructureTreeItem
        onClick={() => onSelect(structure)}
        isActive={structure.id === selectedSubstructure?.id}
        depth={depth}
      >
        {formatStructureLabel(structure.parent!, structure)}
      </SubstructureTreeItem>
      {!!structure.substructures.length &&
        structure.substructures.map(structure => (
          <SubStructureTree
            key={structure.id}
            structure={structure}
            selectedSubstructure={selectedSubstructure}
            onSelect={onSelect}
            depth={depth + 1}
          />
        ))}
    </SubstructureTreeContainer>
  );
}

type InfoProps = {
  structure: Structure;
  simplifyHierarchy: boolean;
  showUnusedStructures: boolean;
};
function StructureInfo(props: InfoProps) {
  return (
    <StructureInfoContainer>
      <Header>
        <Title>{props.structure.label}</Title>
        <WikibaseId
          target="_blank"
          href={`${fcEndpoint.instance}/wiki/Item:${props.structure.id}`}
        >
          {props.structure.id}
        </WikibaseId>
        <Definition>{props.structure.description}</Definition>
      </Header>
      {/* <Media>
        <ImagePlaceholder>Radford Imagery</ImagePlaceholder>
        <ImagePlaceholder>Radford Imagery</ImagePlaceholder>
        <ImagePlaceholder>Radford Imagery</ImagePlaceholder>
      </Media>
      <RadfordNote>
        Radford imagery related to this structure will be presented here, but
        has yet to be implemented.
      </RadfordNote> */}
      <RelatedCharacters
        structure={props.structure}
        simplifyHierarchy={props.simplifyHierarchy}
      />
    </StructureInfoContainer>
  );
}

const ContainerWithoutSubstructures = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: 'info';
`;
const ContainerWithSubstructures = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: 'substructures info';
`;
const SubstructureListContainer = styled.div`
  padding: 40px 0;
  grid-area: substructures;
  background: rgba(220, 220, 220, 0.5);
`;
const SubstructureListItem = styled.div<{ isActive: boolean }>`
  font-size: 0.9em;
  padding: 3px 20px;
  margin: -2px 0px;
  cursor: pointer;
  ${props => props.isActive && `background-color: white;`}
`;
const SubstructureTreeContainer = styled.div<{ depth: number }>`
  padding: 0px;
`;
const SubstructureTreeItem = styled.div<{ isActive: boolean; depth: number }>`
  font-size: 0.9em;
  padding: 3px 0 3px ${props => (props.depth || 0) * 20 + 20}px;
  margin: -2px 0px;
  cursor: pointer;
  ${props => props.isActive && `background-color: white;`}
`;

const ParentStructureName = styled.span`
  opacity: 0.4;
  line-height: 1em;
  font-size: 0.8em;
`;
const StructureInfoContainer = styled.div`
  padding: 40px 20px;
  grid-area: info;
`;
const Header = styled.header`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-template-areas:
    'title wikibaseId'
    'definition definition';
`;
const Title = styled.h1`
  grid-area: title;
  font-size: 2em;
`;
const WikibaseId = styled.a`
  grid-area: wikibaseId;
  margin-right: 20px;
  font-size: 0.9em;
`;
const Definition = styled.em`
  grid-area: definition;
`;

const Media = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
`;
const ImagePlaceholder = styled.div`
  width: 150px;
  height: 110px;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8em;
`;
const RadfordNote = styled.em`
  display: block;
  font-size: 0.8em;
  text-align: center;
  margin-bottom: 20px;
`;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Popup, Tab } from 'semantic-ui-react';

import { getPID, getUID } from 'actions/floracommons/pid-uid';
import { getClaimProvenances } from 'actions/floracommons/provenance';
import TabbedProvenance from './tabbed-provenance';
import {
  Claim,
  ClaimSnakEntityValue,
  ClaimSnakStringValue,
} from 'wikibase-types/source';
import { MorphValue } from './morph/MorphValue';
import EntityLabel from 'components/wikibase-entity-label';

type Props = {
  allClaims: Record<string, Claim[]>;
  claims: Claim[];
  hideProvenances: string[];
};
export default function TaxonDescription(props: Props) {
  const { allClaims, claims, hideProvenances } = props;
  return (
    <TabbedProvenance
      allClaims={allClaims}
      claims={claims}
      hideProvenances={hideProvenances}
      paneComponent={DescriptionPane}
    />
  );
}

type DescriptionPaneProps = {
  allClaims: Record<string, Claim[]>;
  claims: Claim[];
  provenance: string[];
  hideProvenances: string[];
};
function DescriptionPane(props: DescriptionPaneProps) {
  const { provenance, hideProvenances, allClaims, claims } = props;

  const statementIdRegexp = /^d0_s([0-9]+)$/;
  const getOrderFromStatementId = (id: string) =>
    id && statementIdRegexp.test(id)
      ? parseInt(id.match(statementIdRegexp)![1])
      : 9999;

  // const morphStatementPID = getPID('taxon/morphology statement');
  const morphStatementSourcePID = getPID('taxon/statement source text');

  // group morph claims by their id in the original source text (taxon/statement source text)
  const morphClaims: Record<string, Claim[]> = allClaims[
    'taxon/morphology statement'
  ]?.length
    ? allClaims['taxon/morphology statement'].reduce(
        (claimsBySourceId: Record<string, Claim[]>, claim: Claim) => {
          // check if the statement has a reference with the source text reference
          const sourceRef = claim?.references?.find(
            ref => !!ref.snaks[morphStatementSourcePID],
          );
          if (sourceRef) {
            const statementSourceId = (
              sourceRef.snaks[morphStatementSourcePID][0]
                .datavalue as ClaimSnakStringValue
            ).value;
            claimsBySourceId[statementSourceId] = claimsBySourceId[
              statementSourceId
            ]
              ? claimsBySourceId[statementSourceId].concat(claim)
              : [claim];
          }
          return claimsBySourceId;
        },
        {},
      )
    : {};

  const description = claims
    .map((f, i) => {
      const descriptionFragmentPID = getPID('taxon/description/fragment id');
      const ref = f?.references?.find(ref => ref.snaks[descriptionFragmentPID]);
      const id = ref
        ? (
            ref.snaks[descriptionFragmentPID][0]
              .datavalue as ClaimSnakStringValue
          )?.value
        : undefined;
      return {
        text: (f.mainsnak?.datavalue as ClaimSnakStringValue)?.value,
        id: id ? id : `${i}`,
        order: id ? getOrderFromStatementId(id) : i,
        statements: id ? morphClaims[id] || [] : [],
        provenance: getClaimProvenances(f),
      };
    })
    .sort((a, b) => a.order - b.order);

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupContent, setPopupContent] = React.useState<ReactNode>();
  const [popupContextRef, setPopupContextRef] =
    React.useState<React.Ref<HTMLElement>>();

  const showPopup = (ref: React.Ref<HTMLElement>, statements: Claim[]) => {
    setPopupContextRef(ref);
    setPopupContent(
      <div>
        {statements.map(st => (
          <RenderMorphClaim key={st.id} claim={st} />
        ))}
      </div>,
    );
    setPopupOpen(true);
  };

  return (
    <Tab.Pane attached="top" onMouseLeave={() => setPopupOpen(false)}>
      {popupContextRef && (
        <Popup
          context={popupContextRef}
          content={popupContent}
          position="top center"
          open={popupOpen}
          inverted
          wide="very"
        />
      )}

      {description.map(({ id, text, statements }) => {
        const contextRef = React.useRef<HTMLElement>(null);
        return (
          <TaxonDescriptionFragment
            key={id}
            id={`stament-fragment-${id}`}
            onClick={() => showPopup(contextRef, statements)}
            ref={contextRef}
          >
            {text}
          </TaxonDescriptionFragment>
        );
      })}
    </Tab.Pane>
  );
}

function RenderMorphClaim(props: { claim: Claim }) {
  const { claim } = props;
  const structureQ =
    claim?.qualifiers?.[getPID('taxon/morphology statement structure')];
  const characterQ =
    claim?.qualifiers?.[getPID('taxon/morphology statement character')];
  const value = claim?.mainsnak?.datavalue?.value;
  if (
    structureQ &&
    structureQ.at(0)?.datavalue &&
    characterQ &&
    characterQ.at(0)?.datavalue
  ) {
    const structureId = (structureQ.at(0)!.datavalue as ClaimSnakEntityValue)
      .value.id;
    const characterId = (characterQ.at(0)!.datavalue as ClaimSnakEntityValue)
      .value.id;
    return (
      <div>
        {structureId ? (
          <span>
            <EntityLabel id={structureId} />{' '}
          </span>
        ) : (
          '[Unknown structure]'
        )}
        {characterId ? (
          <span>
            <EntityLabel id={characterId} />{' '}
          </span>
        ) : (
          '[Unknown character]'
        )}
        <b>
          <MorphValue claim={claim} />
        </b>
      </div>
    );
  }
  return <em>Error formatting statement.</em>;
}

const BoldFirstWord = React.forwardRef(function BoldFirstWord(
  props: {
    key?: string;
    children: string;
    id: string;
    className?: string;
    onClick: () => void;
  },
  ref?: any,
) {
  const { key, children, id, className, onClick } = props;
  const spaceIndex = children.indexOf(' ');
  const firstWord = children.substr(0, spaceIndex);
  const rest = children.substr(spaceIndex);
  const upperCaseFirstCharacter = /^[A-Z0-9]{1}/;
  if (upperCaseFirstCharacter.test(firstWord)) {
    return (
      <span
        ref={ref}
        key={key}
        id={`description-${id}`}
        className={className}
        onClick={onClick}
      >
        <b>{firstWord}</b> {rest}{' '}
      </span>
    );
  } else {
    return (
      <span
        ref={ref}
        key={key}
        id={`description-${id}`}
        className={className}
        onClick={onClick}
      >
        {firstWord} {rest}{' '}
      </span>
    );
  }
});

const TaxonDescriptionFragment = styled(BoldFirstWord)`
  cursor: help;
  :hover {
    background: rgba(200, 180, 100, 0.3);
  }
`;

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import LayoutWidth from 'components/layout-width';

type Props = {
  children: React.ReactNode;
};

export default function NavFrame(props: Props) {
  return (
    <Container>
      <NavContainer>
        <Nav />
      </NavContainer>
      <Content>{props.children}</Content>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-areas:
    'nav'
    'content';
  height: 100%;
`;
const NavContainer = styled.nav`
  grid-area: nav;
`;
const Content = styled.div`
  grid-area: content;
  overflow-y: auto;
`;

export function Nav() {
  return (
    <LayoutWidth>
      <ModuleNav>
        <ModuleNavItem to={`/`}>
          <Icon name="home" />
        </ModuleNavItem>
        <ModuleNavItem to={`/faceted-search`}>
          <Icon name="search" />
          Taxon Search
        </ModuleNavItem>
        <ModuleNavItem to={`/taxon-hierarchy`}>
          <Icon name="tree" />
          Taxon Hierarchy
        </ModuleNavItem>
        <ModuleNavItem to={`/morphology-explorer`}>
          <Icon name="leaf" />
          Morphology Explorer
        </ModuleNavItem>
        <ModuleNavItem to={`/map`}>
          <Icon name="map" />
          Occurrence Map
        </ModuleNavItem>
        {/* <ModuleNavItem to={`search`}>
    <Icon name="search" />
    Properties
  </ModuleNavItem> */}
        {/* <ModuleNavItem to={`${path}/media`} >
        <Icon name="map" />Photos
      </ModuleNavItem> */}
      </ModuleNav>
    </LayoutWidth>
  );
}

const ModuleNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-self: center;
  flex: 1;
  margin-bottom: 5px;
`;

const ModuleBaseNavItem = styled(NavLink)`
  margin-bottom: -2px;
  padding: 10px 20px;

  font-size: 16px;
  color: #555;

  &:hover {
    color: #333;
  }
`;

const ModuleNavItem = styled(({ className, ...props }) => (
  <ModuleBaseNavItem {...props} activeClassName={className} />
))`
  border-bottom: 2px solid #111;
  color: #111;
  font-weight: bold;
`;

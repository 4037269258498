import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Popup } from 'semantic-ui-react';

import {
  fetchAllCharactersForStructure,
  getTopLevelCharactersOfStructure,
  Structure,
} from 'actions/floracommons/morphology';
import StructureCharacterValues from './structure-character-values';
import { getHuntsTerm } from 'actions/floracommons/hunts';
import EntityLabel from 'components/wikibase-entity-label';

type Props = {
  structure: Structure;
  simplifyHierarchy: boolean;
};
export default function RelatedCharacters(props: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const cancelRequest = useRef<boolean>(null);
  const [characters, setCharacters] = useState<
    Awaited<ReturnType<typeof fetchAllCharactersForStructure>> | undefined
  >(undefined);
  const [selectedCharacterId, setSelectedCharacterId] = useState<
    string | undefined
  >();
  const [characterDefinitions, setCharacterDefinitions] =
    useState<Awaited<ReturnType<typeof getHuntsTerm>>>();

  const includeSubstructures = props.simplifyHierarchy;

  useEffect(() => {
    let mounted = true;

    // reset state when structure id changes
    setIsLoaded(false);
    setCharacters(undefined);
    setSelectedCharacterId(undefined);
    setCharacterDefinitions(undefined);

    // fetch next characters
    fetchAllCharactersForStructure(
      props.structure.id,
      includeSubstructures,
    ).then(ch => {
      if (mounted) {
        setCharacters(ch);
        console.log(ch);
        setIsLoaded(true);
      }
    });

    return () => {
      mounted = false;
    };
  }, [props.structure.id, props.simplifyHierarchy]);

  useEffect(() => {
    console.log(`selectedCharacterId changed ${selectedCharacterId}`);
    // load hunts definition when selected character changes
    if (selectedCharacterId && characters) {
      const characterRow = characters.find(
        character => character.id === selectedCharacterId,
      );
      if (characterRow) {
        getHuntsTerm(characterRow.label).then(terms => {
          if (terms.length) {
            setCharacterDefinitions(terms);
          } else {
            setCharacterDefinitions(undefined);
          }
        });
      } else {
        setCharacterDefinitions(undefined);
      }
    }
  }, [selectedCharacterId]);

  if (!isLoaded) {
    return (
      <Container>
        <Title>Related Characters</Title>
        Loading...
      </Container>
    );
  }
  return (
    <Container>
      <Title>Related Characters</Title>
      <CharacterList>
        {characters !== undefined &&
          !!characters.length &&
          characters.map(character => (
            <Character
              key={character.id}
              onClick={() => setSelectedCharacterId(character.id)}
              isActive={
                !!selectedCharacterId && selectedCharacterId === character.id
              }
            >
              {character.label}
            </Character>
          ))}
        {characters !== undefined && !characters.length && (
          <span>No related characters defined.</span>
        )}
      </CharacterList>
      {characterDefinitions && characterDefinitions.length && (
        <CharacterTerms>
          {characterDefinitions.map((term, i) => (
            <CharacterDefinition key={i}>
              <>
                <b>
                  {term.term}
                  {term.termNumber ? ` ${term.termNumber}` : ''}
                </b>{' '}
                {term.definition}
              </>
            </CharacterDefinition>
          ))}
        </CharacterTerms>
      )}
      {selectedCharacterId && (
        <StructureCharacterValues
          structureId={props.structure.id}
          characterId={selectedCharacterId}
        />
      )}
    </Container>
  );
}

const Container = styled.div``;
const CharacterList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Character = styled.span<{ isActive: boolean }>`
  padding: 5px;
  margin: 0 10px 5px 0;
  border-radius: 10px;
  background: white;
  cursor: pointer;
  ${props =>
    !!props.isActive &&
    css`
      background: #999;
      color: white;
    `}
`;
const Title = styled.h2``;
const CharacterTerms = styled.dl``;
const CharacterDefinition = styled.dd`
  font-style: italic;
  font-size: 0.9em;
  margin: 0;
`;

import pidMap from '../../cache/pids';

const uidMap: { [pid: string]: string } = Object.assign(
  {},
  ...Object.entries(pidMap).map(([a, b]) => ({ [b]: a })),
);

export function getPID(uid: string) {
  if (pidMap[uid as keyof typeof pidMap]) {
    return pidMap[uid as keyof typeof pidMap];
  }
  throw new Error(`No PID found for UID ${uid}`);
}
export function getQID(uid: string) {
  if (pidMap[uid as keyof typeof pidMap]) {
    return pidMap[uid as keyof typeof pidMap];
  }
  throw new Error(`No PID found for UID ${uid}`);
}
export function getUID(pid: string) {
  if (uidMap[pid]) {
    return uidMap[pid];
  }
  throw new Error(`No PID found for UID ${pid}`);
}

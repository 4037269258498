import React from 'react';
import styled from 'styled-components';
import { Button, Popup } from 'semantic-ui-react';
import EntityLabel from 'components/wikibase-entity-label';
import { getQID } from 'actions/floracommons/pid-uid';

type Props = {
  provenances: Record<string, boolean>;
  onChange: (provenances: Record<string, boolean>) => void;
};
export default function ProvenanceFilter(props: Props) {
  const { provenances, onChange } = props;

  const toggleProvenance = (provId: string) => {
    onChange({
      ...provenances,
      [provId]: !provenances[provId],
    });
  };

  return (
    <Popup
      trigger={<Button color="green" icon="filter" content="Filter Sources" />}
      content={Object.keys(provenances)
        // hide the floracommons provenance, it's just a bit confusing at the moment
        .filter(p => p !== getQID('provenance/fc'))
        .map(provId => (
          <div key={provId}>
            <label>
              <input
                type="checkbox"
                checked={provenances[provId]}
                onChange={ev => toggleProvenance(provId)}
              />{' '}
              <EntityLabel id={provId} />
            </label>
          </div>
        ))}
      on="click"
      position="bottom right"
    />
  );
}

import { wbApi } from '../_init';
import { getPID, getQID } from './pid-uid';
import { fcEndpoint } from 'constants/endpoints';

export type MorphFacet =
  | [string, string, string[]]
  | [string, string, string[], MorphFacetOptions];

export type MorphFacetOptions =
  | {
      querySubstructures?: boolean;
      querySubcharacters?: boolean;
    }
  | undefined;

export type SimpleFacets = {
  family?: string[];
  rank?: string[];
  distribution?: string[];
};

type WikibaseLabelServiceValue = { label: string; value: string };

export type TaxonResult = {
  taxon: {
    value: string;
    name: string;
    authority: string;
  };
  parentTaxon?: {
    value: string;
    name: string;
    authority: string;
  };
  // rank: WikibaseLabelServiceValue;
  [key: string]: undefined | string | { [key: string]: string };
};

export type TaxonGroupedResult = {
  taxon: {
    id: string;
    name: string;
    authority: string;
    rank?: string;
  };
  parent?: {
    id: string;
    name: string;
    authority: string;
  };
  morphHits: {
    type: string;
    relatedStructure: WikibaseLabelServiceValue;
    relatedCharacter: WikibaseLabelServiceValue;
    value: string;
    valueModifier: string;
    provenance: string;
  }[];
  simpleHits: {
    distribution: string[];
  };
};

let controller: AbortController;

// TREATS FACETS AS /AND/ BOOLEAN QUERIES
export function taxaFacetsQuery(
  morphFacets: MorphFacet[],
  simpleFacets: SimpleFacets,
  pageNo: number = 0,
  perPage: number = 100,
) {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();

  const defaultFacetOptions = {
    querySubstructures: true,
    querySubcharacters: true,
  };
  const mf = morphFacets
    .filter(facet => facet[0] && facet[1] && facet[2] && facet[2].length)
    .map(
      facet =>
        (facet[3] = { ...defaultFacetOptions, ...(facet[3] ?? {}) }) && facet,
    );

  console.log(`Morph facets`, mf);
  const numFacets = mf.length;
  const addIf = (condition: any, args: any[], fragment: Function) =>
    condition ? fragment(...args) : '';
  /* eslint-disable */
  const query = `#
  SELECT DISTINCT 
    ?taxon 
    ?taxonName ?taxonAuthority
    ?parentTaxon ?parentTaxonName ?parentTaxonAuthority
    ${addIf(numFacets, [numFacets], (numFacets: number) => mf.map((f,i) => `
    ?relatedStructure${i} ?relatedStructure${i}Label 
    ?relatedCharacter${i} ?relatedCharacter${i}Label 
    ?provenance${i} ?provenance${i}Label
    ?value${i}
    ?valueModifier${i}`).join("\n\n")
    )}
    #?distribution
  WHERE {
    SERVICE wikibase:label {bd:serviceParam wikibase:language "en" }
    # order of execution is important, so better slow and accurate than optimised and wrong
    hint:Query hint:optimizer "None".
    ${ addIf(numFacets, [mf], (mf: MorphFacet[]) => mf.map(([,, values], i) => `VALUES ?values_${i} {${values.map(v => `"${v}"`).join(' ')}}`).join("\n"))}
    ${ addIf(simpleFacets?.family?.length, [simpleFacets], (sf: SimpleFacets) => `VALUES ?families {${sf?.family?.map(v => `wd:${v}`).join(' ')}}`)}
    ${ addIf(simpleFacets?.rank?.length, [simpleFacets], (sf: SimpleFacets) =>`VALUES ?ranks {${sf?.rank?.map(v => `wd:${v}`).join(' ')}}`)}
    ${ addIf(simpleFacets?.distribution?.length, [simpleFacets], (sf: SimpleFacets) =>`VALUES ?distValues {${sf?.distribution?.map(v => `"${v}"`).join(' ')}}`)}

    ${ addIf(simpleFacets?.family?.length, [simpleFacets], (sf: SimpleFacets) =>`
      ?taxon wdt:${getPID("taxon/parent taxon")}+ ?families.
    `)}
    ${ addIf(simpleFacets?.rank?.length, [simpleFacets], (sf: SimpleFacets) =>`
      ?taxon wdt:${getPID("taxon/rank")} ?ranks.
    `)}
    ${ addIf(simpleFacets?.distribution?.length, [simpleFacets], (sf: SimpleFacets) =>`
      ?taxon wdt:${getPID("taxon/distribution")} ?distValues.
    `)}

    ${addIf(numFacets, [mf], (mf: MorphFacet[]) => mf.map(([structureId, characterId, v, opts], i) => `

    ?st${i} pq:${getPID("taxon/morphology statement value")} ?values_${i}.
    wd:${structureId} ${opts?.querySubstructures ? `(^wdt:${getPID("core/substructure of")})*/` : ``}(^pq:${getPID("taxon/morphology statement structure")}) ?st${i}.
    wd:${characterId} ${opts?.querySubcharacters ? `(^wdt:${getPID('core/subcharacter of')})*/` : ``}(^pq:${getPID("taxon/morphology statement character")}) ?st${i}.
    ?st${i} ^p:${getPID("taxon/morphology statement")} ?taxon.

    ?st${i} pq:${getPID("taxon/morphology statement value")} ?value${i};
            pq:${getPID("taxon/morphology statement structure")} ?relatedStructure${i};
            pq:${getPID("taxon/morphology statement character")} ?relatedCharacter${i}.

    OPTIONAL {
      ?st${i} pq:${getPID("taxon/morphology statement value modifier")} ?valueModifier${i}.
    }
    OPTIONAL {
      ?st${i} prov:wasDerivedFrom/pr:${getPID("core/provenance")} ?provenance${i}.
    }
    `).join("\n\n"))}
    ?taxon wdt:${getPID("taxon/name")} ?taxonName;
    wdt:${getPID("taxon/authority")} ?taxonAuthority.

    OPTIONAL { 
      ?taxon wdt:${getPID("taxon/parent taxon")} ?parentTaxon.
      ?parentTaxon wdt:${getPID("taxon/name")} ?parentTaxonName;
                   wdt:${getPID("taxon/authority")} ?parentTaxonAuthority.
    }
  }
  ORDER BY ASC(?taxonLabel) LIMIT ${perPage} OFFSET ${perPage * pageNo}`;
  /* eslint-enable */

  const url = wbApi.sparqlQuery(query);

  return {
    sparql: query,
    url: url,
    fetch: async function (options = {}): Promise<TaxonGroupedResult[]> {
      const opts = {
        breakCache: false,
        ...options,
      };

      return await fetch(`${url}${opts.breakCache ? '&nocache=true' : ''}`, {
        signal: controller.signal,
      }).then(async response => {
        if (response.status !== 200) {
          throw new Error('Query failed');
        }
        const data = wbApi.simplify.sparqlResults(await response.json());
        // console.log('Taxon facet results', data);
        /* {
          taxon: Object { value: "QID", label: "String" }
          parentTaxon: Object { value: "QID", label: "String" }
          rank: Object { value: "QID", label: "String" }
          superStructure: Object {value: "PID", label: "String"}
          value: String
        } */
        // console.log('Taxa', data)
        // reformat result rows to group the various printouts into an array of objects
        return Object.values<TaxonGroupedResult>(
          data
            .map((row: TaxonResult): TaxonGroupedResult => {
              const morphHits = Array.from({ length: numFacets }, (n, i) => ({
                type: 'morph',
                relatedStructure: row[
                  `relatedStructure${i}`
                ] as WikibaseLabelServiceValue,
                relatedCharacter: row[
                  `relatedCharacter${i}`
                ] as WikibaseLabelServiceValue,
                value: row[`value${i}`] as string,
                valueModifier: row[`valueModifier${i}`] as string,
                provenance: row[`provenance${i}`] as string,
              }));
              const simpleHits = {
                distribution: row.distValues
                  ? (row.distValues as string).split(',')
                  : [],
              };
              return {
                taxon: {
                  id: row.taxon.value,
                  name: row.taxon.name,
                  authority: row.taxon.authority,
                },
                parent: row.parentTaxon
                  ? {
                      id: row.parentTaxon.value,
                      name: row.parentTaxon.name,
                      authority: row.parentTaxon.authority,
                    }
                  : undefined,
                morphHits,
                simpleHits,
              };
            })
            // squash rows of the same taxon into one row with multiple search hits
            .reduce(
              (
                map: Record<string, TaxonGroupedResult>,
                row: TaxonGroupedResult,
              ): Record<string, TaxonGroupedResult> => {
                if (map[row.taxon.id]) {
                  row.morphHits.push(...map[row.taxon.id].morphHits);
                }
                map[row.taxon.id] = row;
                return map;
              },
              {},
            ),
        );
      });
    },
  };
}

export async function getTaxaByRankName(
  rankName: string,
): Promise<{ id: string; name: string }[]> {
  const rankQID = getQID(`taxon/rank/${rankName}`);
  const url = wbApi.sparqlQuery(`
    SELECT ?taxon ?taxonName {
      ?taxon wdt:${getPID('core/instance of')} "taxon";
            p:${getPID('taxon/accepted id')} ?st.
      ?st pq:${getPID('taxon/name')} ?taxonName;
          pq:${getPID('taxon/status')} "accepted";
          pq:${getPID('taxon/rank')} wd:${rankQID}.
    }
    ORDER BY ASC(?taxonName)`);
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      return data.map((row: any) => ({
        id: row.taxon.value,
        name: row.taxon.name,
      }));
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

export async function getStructureCharactersByFamily(
  familyIds: string[],
): Promise<Record<string, string[]>> {
  const url = wbApi.sparqlQuery(`
  SELECT DISTINCT ?structure ?character WHERE {
    VALUES ?family {${familyIds.map(id => `wd:${id}`).join(' ')}}
    ?st ^p:${getPID('taxon/morphology statement')} / wdt:${getPID(
    'taxon/parent taxon',
  )}* ?family;
        pq:${getPID('taxon/morphology statement structure')} / wdt:${getPID(
    'core/substructure of',
  )}* ?structure;
        pq:${getPID('taxon/morphology statement character')} / wdt:${getPID(
    'core/subcharacter of',
  )}* ?character.

    MINUS{
      ?character wdt:${getPID('core/subcharacter of')} ?parentCharacter.
    }
    MINUS{
      ?structure wdt:${getPID('core/subcharacter of')} ?structure.
    }
  } `);
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      return data.reduce((groups: Record<string, string[]>, row: any) => {
        if (!groups[row.structure]) {
          groups[row.structure] = [];
        }
        groups[row.structure].push(row.character);
        return groups;
      }, {});
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import EntityLabel from 'components/wikibase-entity-label';
import { getClaimProvenances } from 'actions/floracommons/provenance';
import getProvenanceIndex from './prov-index';
import {
  Claim,
  ClaimSnakEntityValue,
  ClaimSnakMonolingualTextValue,
  ClaimSnakStringValue,
} from 'wikibase-types/source';
import ErrorBoundary from 'components/error-boundary';

const Container = styled.ul`
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
`;
const ClaimContainer = styled.li`
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;

  &:after {
    content: ', ';
  }
  &:last-child:after {
    content: '.';
  }
`;
const Label = styled.span``;
const Ref = styled.sup`
  padding: 0 4px;
  border-radius: 50%;
  background: #ccc;
`;

type ProvenanceLabelProps = {
  provId: string;
  language?: string;
};

function ProvenanceLabel(props: ProvenanceLabelProps) {
  const { provId } = props;
  return (
    <Popup
      trigger={<Ref key={provId}>{getProvenanceIndex(provId)}</Ref>}
      content={
        <div>
          <div>
            Source: <EntityLabel id={provId} />
          </div>
          {props.language && <div>Language: {props.language}</div>}
        </div>
      }
      inverted
    />
  );
}

/**
 * Renders an inline list of claims, grouped and filterable by provenance
 * @param {*} props
 */
type InlineProvenanceProps = {
  claims: Claim[];
  className?: string;
  hideProvenances?: string[];
  showToken?: boolean;
  children?: ReactNode;
};
export default function InlineProvenance(props: InlineProvenanceProps) {
  const {
    claims,
    className,
    hideProvenances = [],
    showToken = true,
    children,
  } = props;

  const RenderComp =
    typeof children === 'function' ? children : InlineProvenanceClaim;

  if (!claims) {
    return null;
  }

  return (
    <Container className={className}>
      <ErrorBoundary>
        {claims
          ?.filter(claim => {
            const provs = getClaimProvenances(claim);
            //  hide claim if all provenances are hidden
            const allProvsHidden = provs.reduce(
              (hide, prov) => hide && hideProvenances.indexOf(prov.id) > -1,
              true,
            );
            return !allProvsHidden;
          })
          ?.map(claim => (
            <RenderComp
              claim={claim}
              key={claim.id}
              hideProvenances={hideProvenances}
              showToken={showToken}
            />
          ))}
      </ErrorBoundary>
    </Container>
  );
}

type InlineProvenanceClaimProps = {
  claim: Claim;
  hideProvenances: string[];
  showToken?: boolean;
};
export function InlineProvenanceClaim(props: InlineProvenanceClaimProps) {
  const { claim, hideProvenances, showToken } = props;
  const provenances = getClaimProvenances(claim).filter(
    prov => hideProvenances.indexOf(prov.id) < 0,
  );
  if (!claim?.mainsnak?.datatype) {
    throw new Error('Snak does not have datatype info');
  }
  switch (claim.mainsnak.datatype) {
    case 'wikibase-item':
      return (
        <ClaimContainer>
          <Label>
            <EntityLabel
              id={
                (claim?.mainsnak?.datavalue as ClaimSnakEntityValue)?.value?.id
              }
            />
          </Label>
          {!showToken ||
            provenances.map(prov => (
              <ProvenanceLabel key={prov.id} provId={prov.id} />
            ))}
        </ClaimContainer>
      );
    case 'monolingualtext':
      //eslint-disable-next-line
      let value = claim.mainsnak
        .datavalue! as unknown as ClaimSnakMonolingualTextValue;
      return (
        <ClaimContainer>
          <Label>{value.value.text}</Label>
          {!showToken ||
            provenances.map(prov => (
              <ProvenanceLabel
                key={prov.id}
                provId={prov.id}
                language={value.value.language}
              />
            ))}
        </ClaimContainer>
      );
    case 'quantity':
    case 'string':
    default:
      return (
        <ClaimContainer>
          <Label>{claim?.mainsnak?.datavalue?.value}</Label>
          {!showToken ||
            provenances.map(prov => (
              <ProvenanceLabel key={prov.id} provId={prov.id} />
            ))}
        </ClaimContainer>
      );
  }
}

import { getPID, getUID } from 'actions/floracommons/pid-uid';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, Menu, Popup, Icon } from 'semantic-ui-react';
import fetchEntityLabel from 'actions/wikibase/fetch-entity-label';

import { getClaimProvenances } from 'actions/floracommons/provenance';
import EntityLabel from 'components/wikibase-entity-label';
import { isHuntsTerm } from 'actions/floracommons/hunts';

import Tabbed from './tabbed-provenance';
import {
  Claim,
  ClaimSnakEntityValue,
  ClaimSnakQuantityValue,
  ClaimSnakStringValue,
} from 'wikibase-types/source';
import { MorphValue } from './morph/MorphValue';
import { getHuntsTerm } from 'actions/floracommons/hunts';
import HuntsTerm from '../../components/hunts/HuntsTerm';

type Props = {
  claims: Claim[];
  hideProvenances: string[];
};
export default function MorphData(props: Props) {
  const { claims, hideProvenances } = props;

  return (
    <Tabbed
      claims={claims}
      hideProvenances={hideProvenances}
      paneComponent={MorphDataPane}
    />
  );
}

type PaneProps = {
  claims: Claim[];
};
function MorphDataPane(props: PaneProps) {
  const { claims } = props;
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [structureGroups, setStructureGroups] = useState<
    Record<string, Record<string, Claim[]>>
  >({});

  const entityLabels: Record<string, string> = {};

  useEffect(() => {
    // group morph statements by structure and then character
    // e.g.  leaf.color = [green, greenish]
    const groupedClaims = claims.reduce((groups, claim) => {
      // claim qualifiers are used to define the structure and character for
      // a morphology statement
      if (claim.qualifiers) {
        const structure: string = (
          claim.qualifiers[getPID('taxon/morphology statement structure')]?.[0]
            ?.datavalue as ClaimSnakEntityValue
        )?.value?.id;
        const character: string = (
          claim.qualifiers[getPID('taxon/morphology statement character')]?.[0]
            ?.datavalue as ClaimSnakEntityValue
        )?.value?.id;
        if (!groups[structure]) {
          groups[structure] = {};
        }
        if (!groups[structure][character]) {
          groups[structure][character] = [];
        }
        groups[structure][character].push(claim);
      }
      return groups;
    }, {} as Record<string, Record<string, Claim[]>>);

    setStructureGroups(groupedClaims);

    // setLoaded(true);

    Promise.all(
      claims.reduce((labelsToFetch, claim) => {
        if (claim.qualifiers) {
          labelsToFetch.push(
            fetchEntityLabel(
              (
                claim.qualifiers[
                  getPID('taxon/morphology statement structure')
                ]?.[0]?.datavalue as ClaimSnakEntityValue
              )?.value?.id,
            ),
          );

          labelsToFetch.push(
            fetchEntityLabel(
              (
                claim.qualifiers[
                  getPID('taxon/morphology statement character')
                ]?.[0]?.datavalue as ClaimSnakEntityValue
              )?.value?.id,
            ),
          );
        }
        return labelsToFetch;
      }, [] as Promise<string | undefined>[]),
    )
      .then(res => {
        console.log(res);
        setLoaded(true);
      })
      .catch(e => {
        console.error(e);
        setLoaded(true);
      });
  }, []);
  return (
    <Tab.Pane attached="top">
      {isLoaded &&
        Object.entries(structureGroups)
          .sort((a, b) => (a[0] > b[0] ? -1 : b[0] > a[0] ? 1 : 0))
          .map(([structure, structureClaims]) => {
            return (
              <StructureGroup key={structure}>
                <StructureHeader structureId={structure} />
                <StructureClaims>
                  {Object.entries(structureClaims).map(
                    ([character, claims]) => (
                      <CharacterGroups
                        character={character}
                        claims={claims}
                        key={character}
                        showProvenance={false}
                      />
                    ),
                  )}
                </StructureClaims>
              </StructureGroup>
            );
          })}
    </Tab.Pane>
  );
}

type CharacterGroupsProps = {
  character: string; // wikibase id
  claims: Claim[];
  showProvenance: boolean;
};
function CharacterGroups(props: CharacterGroupsProps) {
  const { character, claims, showProvenance } = props;
  return (
    <CharacterGroup>
      <CharacterName>
        <CharacterHeader characterId={character} />
      </CharacterName>
      <>
        {claims.map(claim => (
          <CharacterState
            claim={claim}
            key={claim.id}
            showProvenance={showProvenance}
          />
        ))}
      </>
    </CharacterGroup>
  );
}

type CharacterStateProps = {
  claim: Claim;
  showProvenance: boolean;
};
function CharacterState(props: CharacterStateProps) {
  const { claim, showProvenance } = props;
  // const type = (claim.mainsnak?.datavalue as ClaimSnakEntityValue)?.value?.id;
  const sourceStatementId = (
    claim.references?.[0]?.snaks?.[getPID('taxon/statement source text')]?.[0]
      ?.datavalue as ClaimSnakStringValue
  )?.value;
  const provenances = getClaimProvenances(claim);

  return (
    <CharacterValue>
      <MorphValue claim={claim} />
      {!showProvenance ||
        provenances.map(prov => <Ref key={prov.id}>{prov.id}</Ref>)}
    </CharacterValue>
  );
}

type MorphClaimProps = {
  claim: Claim;
};
function MorphClaim(props: MorphClaimProps) {
  const { claim } = props;
  if (!claim.qualifiers) {
    console.warn(`No claim qualifiers for claim`, claim);
    return null;
  }
  const characterId = (
    claim.qualifiers[getPID('taxon/morphology statement character')]?.[0]
      ?.datavalue as ClaimSnakEntityValue
  )?.value?.id;
  const value = (
    claim.qualifiers[getPID('taxon/morphology statement value')]?.[0]
      ?.datavalue as ClaimSnakStringValue
  )?.value;
  return (
    <>
      <dt>
        <span>
          {characterId ? <EntityLabel id={characterId} /> : 'Unknown character'}
        </span>
      </dt>
      <dd>
        <span>{value !== undefined ? value : 'Unknown value'}</span>
      </dd>
    </>
  );
}

function StructureHeader(props: { structureId: string }) {
  const { structureId } = props;
  const [label, setLabel] = useState<string>();

  useEffect(() => {
    fetchEntityLabel(structureId).then((label: string) => setLabel(label));
  }, [props.structureId]);
  return (
    <StructureHeaderEl>
      {label ? (
        label.split(' ').map(labelPart =>
          isHuntsTerm(labelPart) ? (
            <>
              <HuntsTerm key={labelPart}>{labelPart}</HuntsTerm>{' '}
            </>
          ) : (
            `${labelPart} `
          ),
        )
      ) : (
        <Icon loading name="spinner" />
      )}
    </StructureHeaderEl>
  );
}

function CharacterHeader(props: { characterId: string }) {
  const { characterId } = props;
  const [label, setLabel] = useState<string>();

  useEffect(() => {
    fetchEntityLabel(characterId).then((label: string) => setLabel(label));
  }, [props.characterId]);
  return (
    <span>
      {label ? (
        label.split(' ').map(labelPart =>
          isHuntsTerm(labelPart) ? (
            <>
              <HuntsTerm key={labelPart}>{labelPart}</HuntsTerm>{' '}
            </>
          ) : (
            `${labelPart} `
          ),
        )
      ) : (
        <Icon loading name="spinner" />
      )}
    </span>
  );
}

const Container = styled.div``;

const PaneContainer = styled.div``;

const StructureGroup = styled.div`
  margin-bottom: 2em;
`;
const StructureHeaderEl = styled.h4`
  text-transform: uppercase;
`;
const StructureClaims = styled.div``;
const CharacterGroup = styled.dl``;
const CharacterName = styled.dt`
  display: inline-block;
  width: 30%;
`;
const CharacterValue = styled.dd`
  display: inline-block;
  padding: 0 5px;
  margin: 0 5px 0 0;
  background: #efefef;
  border-radius: 5px;
  /* &:after {
    content: ", "
  }
  &:last-child:after {
    content: "."
  } */
`;
const Ref = styled.span`
  padding: 0 4px;
  border-radius: 50%;
  background: #ccc;
`;

const DefinitionIcon = styled.span`
  cursor: help;
`;

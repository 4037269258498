export default {
  "fc-uid": "P1",
  "fc-type": "P2",
  "taxon/rank/typestrain": "Q34",
  "taxon/rank/subtypestrain": "Q35",
  "taxon/rank/supervariety": "Q36",
  "taxon/rank/variety": "Q37",
  "taxon/rank/subvarietas": "Q38",
  "taxon/rank/superforma": "Q39",
  "taxon/rank/forma": "Q40",
  "taxon/rank/subforma": "Q41",
  "taxon/rank/supergroup": "Q42",
  "taxon/rank/group": "Q43",
  "taxon/rank/subgroup": "Q44",
  "taxon/rank/unranked": "Q45",
  "core/equivalent property": "P3",
  "core/equivalent class": "P4",
  "core/instance of": "P5",
  "core/subclass of": "P6",
  "core/subproperty of": "P7",
  "core/provenance": "P8",
  "core/stated in": "P9",
  "core/inferred from": "P10",
  "core/statement inherited from taxon": "P11",
  "core/source version": "P12",
  "core/original value": "P13",
  "core/format": "P14",
  "core/order": "P15",
  "core/substructure of": "P16",
  "core/subcharacter of": "P17",
  "core/related structure": "P18",
  "core/related character": "P19",
  "core/part of": "P20",
  "core/type of": "P21",
  "identifiers/gbif": "P22",
  "identifiers/vascan": "P23",
  "taxon/name": "P24",
  "taxon/rank": "P25",
  "taxon/parent taxon": "P26",
  "taxon/family": "P27",
  "taxon/common name": "P28",
  "taxon/synonym": "P29",
  "taxon/basionym": "P30",
  "taxon/authority": "P31",
  "taxon/treatment author": "P32",
  "taxon/accepted id": "P33",
  "taxon/id": "P34",
  "taxon/status": "P35",
  "taxon/synonym of": "P36",
  "taxon/phenology": "P37",
  "taxon/habitat": "P38",
  "taxon/habit": "P39",
  "taxon/elevation": "P40",
  "taxon/distribution": "P41",
  "taxon/occurrence status": "P42",
  "taxon/establishment means": "P43",
  "taxon/publication": "P44",
  "taxon/publication date": "P45",
  "taxon/special status": "P46",
  "taxon/treatment of": "P47",
  "taxon/description": "P48",
  "taxon/description/fragment": "P49",
  "taxon/description/fragment id": "P50",
  "taxon/description/fragment order": "P51",
  "taxon/statement source text": "P52",
  "taxon/morphology statement": "P53",
  "taxon/morphology statement value": "P54",
  "taxon/morphology statement value from": "P55",
  "taxon/morphology statement value to": "P56",
  "taxon/morphology statement value modifier": "P57",
  "taxon/morphology statement structure": "P58",
  "taxon/morphology statement structure constraint": "P59",
  "taxon/morphology statement character": "P60",
  "taxon/measurement/from": "P61",
  "taxon/measurement/to": "P62",
  "taxon/import errors": "P63",
  "taxon/iucn redlist category": "P64",
  "taxon/simple value": "Q1",
  "taxon/range value": "Q2",
  "taxon/modified value": "Q3",
  "unit/meter": "Q4",
  "standard/ISO3166-2": "Q5",
  "taxon/rank/class": "Q6",
  "taxon/rank/subclass": "Q7",
  "taxon/rank/superorder": "Q8",
  "taxon/rank/order": "Q9",
  "taxon/rank/suborder": "Q10",
  "taxon/rank/infraorder": "Q11",
  "taxon/rank/superfamily": "Q12",
  "taxon/rank/family": "Q13",
  "taxon/rank/subfamily": "Q14",
  "taxon/rank/supertribe": "Q15",
  "taxon/rank/tribe": "Q16",
  "taxon/rank/subtribe": "Q17",
  "taxon/rank/supergenus": "Q18",
  "taxon/rank/genus": "Q19",
  "taxon/rank/subgenus": "Q20",
  "taxon/rank/supersection": "Q21",
  "taxon/rank/section": "Q22",
  "taxon/rank/subsection": "Q23",
  "taxon/rank/superseries": "Q24",
  "taxon/rank/series": "Q25",
  "taxon/rank/subseries": "Q26",
  "taxon/rank/superspecies": "Q27",
  "taxon/rank/species": "Q28",
  "taxon/rank/subspecies": "Q29",
  "taxon/rank/superstrain": "Q30",
  "taxon/rank/strain": "Q31",
  "taxon/rank/substrain": "Q32",
  "taxon/rank/supertypestrain": "Q33",
  "provenance/fc": "Q46",
  "provenance/fna": "Q47",
  "provenance/fbc": "Q48",
  "provenance/gbif": "Q49",
  "provenance/vascan": "Q50"
}
import React, {
  useState,
  useEffect,
  ReactNode,
  MouseEventHandler,
  FormEventHandler,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Header,
  Button,
  Icon,
  List,
  Placeholder,
  Card,
  Form,
  Checkbox,
} from 'semantic-ui-react';

import NavFrame from 'components/nav-frame';
import LayoutWidth from 'components/layout-width';
import {
  MorphFacet,
  SimpleFacets,
  taxaFacetsQuery,
  TaxonGroupedResult,
  getTaxaByRankName,
  getStructureCharactersByFamily,
} from 'actions/floracommons/taxa-facets';
import {
  getWikiDataImagesForTaxa,
  WikiDataImages,
} from 'actions/wikidata/taxon-images';
import FacetRow, { MorphFacetFields } from './facet-row';
import ValueDropdownFacet, {
  FacetDropdownValue,
} from './facet-creators/value-dropdown';

import ranks from 'cache/taxon-ranks';
import { fcEndpoint } from 'constants/endpoints';
import { getAllValuesForStructureAndCharacter } from 'actions/floracommons/morphology';
//@todo - this should be it's own constant
const queryGuiUrl = fcEndpoint.query;
/**
 *
 * Notes
 *
 * Facets for...
 * Taxon rank: between [rank] and [rank]?
 * Subtaxa of: value
 * Authority: value
 * Phenology???
 * Habitat: values [AND/OR]
 * Elevation: between [low] and [high] || [above|below] [value]
 * Introduced: boolean
 * Special Status: value
 */

const Container = styled.div`
  flex: 1;
  padding-top: 20px;

  display: grid;
  grid-template-areas: 'side morph' 'side results';
  grid-template-columns: 250px 1fr;
  grid-gap: 10px;

  height: 100%;
`;

const ResultsContainer = styled.div`
  grid-area: results;
  flex: 1;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const FacetBuilder = styled.nav`
  grid-area: morph;
  padding: 10px 10px;
  margin: 0px 0;
  background: white;
`;

const SimpleFacetColumn = styled.div`
  grid-area: side;
  display: flex;
  flex-direction: column;

  padding: 10px 10px;
  margin: 0px 0 0 0;
  background: white;
`;

const ResultsArea = styled.div`
  flex: 1;
  padding: 20px;
  background: #fff;
`;

const TaxaResultsContainer = styled(ResultsArea)``;
const TaxaResults = styled(List)``;
const TaxonResult = styled(
  ({
    className,
    children,
    ...props
  }: {
    className: string;
    children: ReactNode;
  }) => (
    <List.Item>
      <List.Content>{children}</List.Content>
    </List.Item>
  ),
)``;

const TaxaPlaceholder = styled(
  ({ className }: { className?: string; children?: ReactNode }) => (
    <Placeholder fluid className={className}>
      {new Array(20).fill(true).map((a, i) => (
        <Placeholder.Header key={i}>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      ))}
    </Placeholder>
  ),
)``;

const ErrorContainer = styled(ResultsArea)``;
const Empty = styled(ResultsArea)``;

// const AddFacetButton = styled(Button)``;

const AddFacetButton = ({ onClick }: { onClick: MouseEventHandler }) => (
  <Button icon labelPosition="left" onClick={onClick}>
    <Icon name="add" />
    Add Morphology Facet
  </Button>
);
const TaxonImageBase = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
`;
const TaxonImage = styled(TaxonImageBase)<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
`;

const NoTaxonImageContainer = styled(TaxonImageBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  i {
    opacity: 0.5;
  }
`;

const NoTaxonImage = () => (
  <NoTaxonImageContainer>
    <Icon name="file image outline" size="huge" color="grey" />
  </NoTaxonImageContainer>
);

type TaxonRankFacetProps = {
  selectedValues: string[];
  onChange: (values: string[]) => void;
  loading?: boolean;
};
const TaxonRankFacet = ({ selectedValues, onChange }: TaxonRankFacetProps) => {
  return (
    <ValueDropdownFacet
      title="Taxonomic Rank"
      values={ranks.map(({ id, label }) => ({
        key: id,
        value: id,
        text: label,
      }))}
      selectedValues={selectedValues}
      onChange={onChange}
      multiple={true}
    />
  );
};

type SimpleFacetProps = {
  loading: boolean;
  values: FacetDropdownValue[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
};

const FamilyFacet = ({
  loading,
  values,
  selectedValues,
  onChange,
}: SimpleFacetProps) => {
  return (
    <ValueDropdownFacet
      title="Member of family"
      values={values}
      loading={loading}
      selectedValues={selectedValues}
      onChange={onChange}
      multiple={true}
    />
  );
};

const DistributionFacet = ({
  loading,
  values,
  selectedValues,
  onChange,
}: SimpleFacetProps) => {
  return (
    <ValueDropdownFacet
      title="Distribution"
      values={values}
      loading={loading}
      selectedValues={selectedValues}
      onChange={onChange}
      multiple={true}
    />
  );
};

const CacheBreakerPref = ({
  onChange,
}: {
  onChange: FormEventHandler;
  checked?: boolean;
}) => {
  return (
    <Form.Field>
      <Checkbox toggle label="Avoid Query Cache" onChange={onChange} />
    </Form.Field>
  );
};

const SparlqlLink = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const allStructures: { id: string; label: string }[] = [];
const allCharacters: { id: string; label: string }[] = [];
let allStructureCharacters: Record<string, string[]> = {};

const resultsPerPage = 60;

export type FacetedSearchProps = {};

export default function FacetedSearch() {
  // const navigate = useNavigate();

  const [isInitialised, setInitialised] = useState(false);
  const [isFetchingTaxa, setFetchingTaxa] = useState(false);
  const [isError, setError] = useState(false);
  const [taxaResults, setTaxaResults] = useState<TaxonGroupedResult[]>([]);
  const [isFetchingTaxaImages, setFetchingTaxaImages] = useState(true);
  const [taxaImages, setTaxaImages] = useState({});

  const [facets, setFacets] = useState<SimpleFacets>({
    family: [],
    rank: [],
    distribution: [],
  });
  const [structureCharacters, setStructureCharacters] = useState<
    Record<string, string[]>
  >({});
  const [morphFacetRows, setMorphFacetRows] = useState<MorphFacetFields[]>([]);

  const [selectedRankValues, setSelectedRankValues] = useState<string[]>([]);

  const [familyValues, setFamilyValues] = useState<
    { key: string; text: string; value: string }[]
  >([]);
  const [selectedFamilyValues, setSelectedFamilyValues] = useState<string[]>(
    [],
  );

  const [distributionValues, setDistributionValues] = useState<
    { key: string; text: string; value: string }[]
  >([]);
  const [selectedDistributionValues, setSelectedDistributionValues] = useState<
    string[]
  >([]);

  const [queryOptions, setQueryOptions] = useState({ breakCache: false });
  const [sparlqlQuery, setSparqlQuery] = useState('');

  const [resultsPage, setResultsPage] = useState(0);

  const hasFacets =
    Object.values(facets).reduce((sum, fac) => sum + fac.length, 0) > 0 ||
    morphFacetRows.reduce(
      (sum, fac) => (fac[0] && fac[1] && fac[2].length ? sum + 1 : sum),
      0,
    ) > 0;

  // load structure list on component mount, don't re-run for renders
  useEffect(() => {
    // const fetchStructures = async () => {
    //   const data = await getTopLevelStructures();
    //   if (data) {
    //     return data.map(row => ({
    //       id: row.property.value,
    //       label: row.property.label
    //     }))
    //   } else {
    //     console.error('Oops no structures')
    //   }
    // }
    // const fetchCharacters = async () => {
    //   const characters = await getTopLevelCharacters();
    //   if (characters) {
    //     return characters;
    //   } else {
    //     console.error('Oops no characters');
    //   }
    // }
    const fetchStructureCharacterCache = async () => {
      const list = await fetch('/structure-character.json').then(r => r.json());
      console.log(list);
      return list;
    };
    const fetchTaxaCache = async () => {
      return fetch('/taxa.json').then(r => r.json());
    };
    const initialise = async () => {
      // const [structures, characters] = await Promise.all([fetchStructures(), fetchCharacters()]);

      const [strChrs, taxaCache, families]: [
        {
          structures: [string, string][];
          characters: [string, string][];
          structureToCharacters: Record<string, string[]>;
        },
        { distribution: string[] },
        { id: string; name: string }[],
      ] = await Promise.all([
        fetchStructureCharacterCache(),
        fetchTaxaCache(),
        getTaxaByRankName('family'),
      ]);
      // filter structures to only return those which have characters
      allStructures.push(
        ...strChrs.structures
          .filter(([id, label]) => strChrs.structureToCharacters[id]?.length)
          .map(([id, label]) => ({ id, label })),
      );
      allCharacters.push(
        ...strChrs.characters.map(([id, label]) => ({ id, label })),
      );
      allStructureCharacters = strChrs.structureToCharacters;
      setStructureCharacters(strChrs.structureToCharacters);

      // loadFacetsFromURL();
      setFamilyValues(
        families.map(({ id, name }) => ({ key: id, text: name, value: id })),
      );
      setDistributionValues(
        taxaCache.distribution.map(d => ({ key: d, text: d, value: d })),
      );

      setInitialised(true);
    };
    initialise();
  }, []);

  const doSearch = async (
    newMorphFacetRows: MorphFacetFields[] | undefined,
    newFacets: SimpleFacets | undefined,
  ) => {
    if (!newMorphFacetRows) {
      newMorphFacetRows = morphFacetRows;
    }

    if (!newFacets) {
      newFacets = facets;
    }
    if (
      !Object.values(newFacets).reduce((sum, fac) => sum + fac.length, 0) &&
      !newMorphFacetRows.length
    ) {
      setTaxaResults([]);
      setSparqlQuery('');
      return;
    }
    setFetchingTaxa(true);
    setFetchingTaxaImages(true);
    try {
      const facetsQuery = taxaFacetsQuery(
        newMorphFacetRows as MorphFacet[],
        newFacets,
        resultsPage,
        resultsPerPage,
      );
      const taxa = await facetsQuery.fetch(queryOptions);
      setSparqlQuery(facetsQuery.sparql);
      console.log(taxa);
      setTaxaResults(taxa);
      if (taxa.length) {
        setFetchingTaxa(false);
        setFetchingTaxaImages(true);
        const taxaImages = await getWikiDataImagesForTaxa(
          taxa.map(row => row.taxon.name),
        );
        setTaxaImages(taxaImages);
        setFetchingTaxaImages(false);
      } else {
        setFetchingTaxa(false);
        // display no results message
      }
    } catch (e: any) {
      if (e?.name === 'AbortError') {
        console.log('Aborted facet query');
      } else {
        console.log(JSON.stringify(e));
        console.error(e);
        setFetchingTaxa(false);
        setError(true);
      }
    }
  };

  // handler for the onChange event of a FacetRow
  const handleMorphFacetRowChange = (
    facetIndex: number,
    [structure, character, values]: MorphFacetFields,
  ) => {
    // copy factetRows and update with the new facet info
    const newFacetRows = [...morphFacetRows];
    newFacetRows[facetIndex] = [structure, character, values];
    setMorphFacetRows(newFacetRows);
    // persistFacetsToURL(newFacetRows);
    // run the search with the updated facet rows
    doSearch(newFacetRows, undefined);
  };

  // handler for the onRemove event of a FacetRow
  const handleMorphFacetRowRemove = (facetIndex: number) => {
    // copy morphFacetRows and update to remove the facet at index `facetIndex`
    const newFacetRows = [...morphFacetRows];
    newFacetRows.splice(facetIndex, 1);
    setMorphFacetRows(newFacetRows);
    // persistFacetsToURL(newFacetRows);
    // run the search with the updated morphFacetRows
    doSearch(newFacetRows, undefined);
  };

  const handleAddMorphFacetClick = () => {
    setMorphFacetRows([...morphFacetRows, [undefined, undefined, []]]);
  };

  const updateSimpleFacets = (updatedFacets: SimpleFacets) => {
    const newFacets = { ...facets, ...updatedFacets };
    setFacets(newFacets);
    doSearch(undefined, newFacets);
    return newFacets;
  };

  const handleRankChange = (values: string[]) => {
    updateSimpleFacets({ rank: values });
    setSelectedRankValues(values);
  };

  const handleFamilyChange = (values: string[]) => {
    //set loading
    //get structure > character for family
    //set structures and charactcers
    updateSimpleFacets({ family: values });
    setSelectedFamilyValues(values);

    if (values.length) {
      setInitialised(false);
      getStructureCharactersByFamily(values).then(structureCharacters => {
        setStructureCharacters(structureCharacters);
        setInitialised(true);
      });
    } else {
      setStructureCharacters(allStructureCharacters);
    }
  };

  const handleDistributionChange = (values: string[]) => {
    updateSimpleFacets({ distribution: values });
    setSelectedDistributionValues(values);
  };

  const handleCacheBreakerChange = () => {
    setQueryOptions({
      ...queryOptions,
      breakCache: !queryOptions.breakCache,
    });
  };

  const fetchFacetRowValues = async (
    structureId: string,
    characterId: string,
  ) => {
    return getAllValuesForStructureAndCharacter(
      structureId,
      characterId,
      selectedFamilyValues,
    ).then(rows => rows.map(row => row.value));
  };
  // const persistFacetsToURL = (facetRows) => {
  //   const filterIncomplete = ([type, data]) => type && data.length > 0;
  //   if (facetRows.length < 1 || !facetRows.filter(filterIncomplete)?.length) {
  //     navigate(`/faceted-search`);
  //   } else {
  //     const encodedFacets = facetRows.filter(filterIncomplete).map(([type, data]) => `${type}:${data.join(';')}`).join('/');
  //     navigate(`/faceted-search?${encodedFacets}`)
  //   }
  // }

  // const loadFacetsFromURL = () => {
  //   if (location.search && location.search.length > 1) {
  //     const rows = location.search.substr(1).split('/').map(r => {
  //       const row = r.split(':');
  //       if (row.length !== 3 || !row[2]) {
  //         return null;
  //       }
  //       row[2] = row[2].split(',');
  //       return row;
  //     });
  //     const validRows = rows.filter(r => r !== null);
  //     if (validRows.length) {
  //       setFacetRows(validRows);
  //       doSearch(validRows);
  //     }
  //     console.log(rows);
  //   }
  // }

  return (
    <NavFrame>
      <LayoutWidth>
        <Container>
          <SimpleFacetColumn>
            <FamilyFacet
              onChange={handleFamilyChange}
              selectedValues={selectedFamilyValues}
              values={familyValues}
              loading={!isInitialised}
            />
            <TaxonRankFacet
              onChange={handleRankChange}
              selectedValues={selectedRankValues}
              loading={!isInitialised}
            />
            <DistributionFacet
              onChange={handleDistributionChange}
              selectedValues={selectedDistributionValues}
              values={distributionValues}
              loading={!isInitialised}
            />
            <hr />
            <CacheBreakerPref onChange={handleCacheBreakerChange} />
          </SimpleFacetColumn>
          <FacetBuilder>
            <Header as="h3">Morphology</Header>
            {morphFacetRows.map((facet, i) => (
              <FacetRow
                key={i}
                allStructures={allStructures}
                allCharacters={allCharacters}
                structureCharacters={structureCharacters}
                loading={!isInitialised}
                structure={facet[0]}
                character={facet[1]}
                values={facet[2]}
                onChange={facetRowValues =>
                  handleMorphFacetRowChange(i, facetRowValues)
                }
                onRemove={() => handleMorphFacetRowRemove(i)}
                fetchValues={fetchFacetRowValues}
              />
            ))}
            <AddFacetButton onClick={handleAddMorphFacetClick} />
            {sparlqlQuery && (
              <SparlqlLink>
                <a
                  href={`${queryGuiUrl}/${encodeURI(sparlqlQuery)}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Icon name="globe" />
                  Open Sparql Query
                </a>
              </SparlqlLink>
            )}
          </FacetBuilder>
          {isError ? (
            <ErrorContainer>Oops! Something went wrong.</ErrorContainer>
          ) : hasFacets ? (
            <Results
              {...{
                isFetchingTaxa,
                taxaResults,
                isFetchingTaxaImages,
                taxaImages,
                resultsPage,
              }}
            />
          ) : (
            <Empty>Add some search facets to show results.</Empty>
          )}
        </Container>
      </LayoutWidth>
    </NavFrame>
  );
}

type ResultsProps = {
  isFetchingTaxa: boolean;
  taxaResults: TaxonGroupedResult[];
  isFetchingTaxaImages: boolean;
  taxaImages: WikiDataImages;
};
function Results(props: ResultsProps) {
  const { isFetchingTaxa, taxaResults, isFetchingTaxaImages, taxaImages } =
    props;
  return (
    <ResultsContainer>
      <TaxaResultsContainer>
        {isFetchingTaxa ? <TaxaPlaceholder /> : ''}
        {!isFetchingTaxa && taxaResults?.length ? (
          <TaxaResults>
            <Card.Group itemsPerRow={3}>
              {taxaResults.map(({ taxon, parent, morphHits, simpleHits }) => (
                <Card
                  key={taxon.id}
                  link
                  href={`/taxon/${taxon.id}`}
                  width={120}
                >
                  {isFetchingTaxaImages ? (
                    <Placeholder>
                      <Placeholder.Image square />
                    </Placeholder>
                  ) : taxaImages[taxon.name] ? (
                    <TaxonImage imageUrl={taxaImages[taxon.name]} />
                  ) : (
                    <NoTaxonImage />
                  )}
                  <Card.Content>
                    <Card.Header>
                      <TaxonName>
                        {taxon.name} <span>{taxon.authority}</span>
                      </TaxonName>
                    </Card.Header>
                    {parent && (
                      <Card.Meta>
                        <TaxonName>
                          {parent.name} <span>{parent.authority}</span>
                        </TaxonName>
                      </Card.Meta>
                    )}
                    <Card.Description>
                      {simpleHits?.distribution?.length ? (
                        <div>
                          Distribution{' '}
                          <b>{simpleHits.distribution.join(', ')}</b>
                        </div>
                      ) : null}
                      {morphHits.map((hit, i) => (
                        <div key={i}>
                          {hit.relatedStructure.label}{' '}
                          {hit.relatedCharacter.label}{' '}
                          <b>
                            {hit.valueModifier && (
                              <em>{hit.valueModifier}&nbsp;</em>
                            )}
                            {hit.value}
                          </b>
                          <br />
                          {/* {hit.provenance.label} */}
                        </div>
                      ))}
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
            {taxaResults.length === resultsPerPage ? (
              <div>
                There may be more results available for this query, but this
                interface is currently limited to show the first{' '}
                {resultsPerPage} results.
              </div>
            ) : null}
            {/* {taxaResults.length === resultsPerPage ? <Button onClick={loadNextPage}>Load More Results...</Button> : null} */}
          </TaxaResults>
        ) : (
          ''
        )}
      </TaxaResultsContainer>
    </ResultsContainer>
  );
}

const TaxonName = styled.span`
  span {
    font-size: 0.8em;
  }
`;

import { wbApi } from '../_init';
import { getPID, getUID } from './pid-uid';
import { Claim, ClaimReference, ClaimSnak } from 'wikibase-types/source';

export async function fetchAllEntityProvenances(id: string) {
  const url = wbApi.sparqlQuery(`SELECT DISTINCT ?ref ?refLabel {
    wd:Q900 (<>|!<>)*/pr:${getPID('core/provenance')} ?ref.
    ?ref rdfs:label ?refLabel.
  }`);
  return await fetch(url)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      return data;
    })
    .catch(err => {
      console.error(err);
    });
}

export function getClaimProvenances(claimObj: Claim) {
  if (!claimObj) {
    throw new Error('Invalid claim object passed to getClaimProvenances');
  }
  if (!claimObj.references || !claimObj.references.length) {
    return [];
  }
  const provenancePID = getPID('core/provenance');
  return claimObj.references.reduce(
    (provs: { id: string; ref: ClaimSnak }[], ref: ClaimReference) => {
      if (ref.snaks[provenancePID]) {
        ref.snaks[provenancePID].forEach(ref => {
          if (
            ref.datavalue?.type === 'wikibase-entityid' &&
            ref.datavalue?.value?.id
          ) {
            provs.push({
              id: ref.datavalue.value.id,
              ref,
            });
          }
        });
      }
      return provs;
    },
    [],
  );
}

export function groupPropertyClaimsByProvenance(
  claims: Record<string, Claim[]>,
): Record<string, Record<string, Claim[]>> {
  const propertyClaims = Object.entries(claims);
  const properties = Object.keys(claims);
  const groupedClaims: Record<string, Record<string, Claim[]>> = {};

  propertyClaims.forEach(([property, claims]) => {
    claims.forEach(claim => {
      const provenances = getClaimProvenances(claim);
      provenances.forEach(prov => {
        if (!groupedClaims[prov.id]) {
          groupedClaims[prov.id] = Object.fromEntries(
            properties.map(k => [k, []]),
          );
        }
        groupedClaims[prov.id][property].push(claim);
      });
    });
  });

  return groupedClaims;
}

export function groupClaimsByProvenance(claims: Claim[]) {
  return claims.reduce((groups, claim) => {
    const provenances = getClaimProvenances(claim);
    provenances.forEach(prov => {
      if (!groups[prov.id]) {
        groups[prov.id] = [];
      }
      groups[prov.id].push(claim);
    });
    return groups;
  }, {} as Record<string, Claim[]>);
}

export function filterClaimsWithProvenances(
  claims: Claim[],
  provenances: string[],
) {
  return claims.filter(claim => {
    const provs = getClaimProvenances(claim);
    return provs.some(prov => provenances.indexOf(prov.id) > -1);
  });
}
export function filterClaimsWithoutProvenances(
  claims: Claim[],
  provenances: string[],
) {
  return claims.filter(claim => {
    const provs = getClaimProvenances(claim);
    return provs.some(prov => provenances.indexOf(prov.id) === -1);
  });
}

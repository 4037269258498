import { wbApi } from '../_init';
import { getPID, getUID } from './pid-uid';

export type HigherTaxonResult = {
  id: string;
  distance: number;
  rank: string;
  rankOrder: number;
};
export async function fetchHigherTaxa(
  taxonId: string,
  queryOptions: { breakCache: boolean } = { breakCache: false },
): Promise<HigherTaxonResult[]> {
  const url =
    wbApi.sparqlQuery(`SELECT ?sub ?sup ?rank ?rankorder (count(?mid)-1 as ?distance) { 
    VALUES ?sub {wd:${taxonId}}
    ?sub wdt:${getPID('taxon/parent taxon')}* ?mid .
    ?mid wdt:${getPID('taxon/parent taxon')}* ?sup .
    ?sup wdt:${getPID('taxon/rank')} ?rank.
    ?rank wdt:${getPID('core/order')} ?rankorder.
 }
  GROUP BY ?sub ?sup ?rank ?rankorder
  ORDER BY DESC(?distance)`);
  return fetch(`${url}${queryOptions.breakCache ? '&nocache=true' : ''}`)
    .then(async response => {
      if (response.status !== 200) {
        throw new Error('Query failed');
      }
      const data = wbApi.simplify.sparqlResults(await response.json());
      console.log('taxon hierarchy', data);
      return data.map(
        (d: {
          sup: string;
          distance: string;
          rank: string;
          rankorder: string;
        }) => ({
          id: d.sup,
          distance: parseInt(d.distance),
          rank: d.rank,
          rankOrder: parseInt(d.rankorder),
        }),
      );
    })
    .catch(err => {
      console.error(err);
    });
}

export type SubTaxonResult = {
  id: string;
  name: string;
  authority: string;
  subTaxaCount: number;
};
export async function fetchSubTaxa(
  taxonId: string | undefined,
  queryOptions: { breakCache: boolean } = { breakCache: false },
): Promise<SubTaxonResult[]> {
  const url =
    wbApi.sparqlQuery(`SELECT ?taxon ?name ?authority (count(?subTaxon)-1 as ?numSubTaxa){ 
    ${
      taxonId
        ? `?taxon wdt:${getPID('taxon/parent taxon')} wd:${taxonId}.`
        : `?taxon wdt:${getPID('core/instance of')} "taxon".`
    }
    ?taxon wdt:${getPID('taxon/name')} ?name.
    OPTIONAL { ?taxon wdt:${getPID('taxon/authority')} ?authority. }
    ${
      taxonId
        ? ``
        : /*`
        ?taxon p:${getPID('taxon/accepted id')} ?acceptedIdSt.
        ?acceptedIdSt pq:${getPID('taxon/status')} "accepted".
        FILTER NOT EXISTS {?taxon wdt:${getPID('taxon/parent taxon')} ?parent}`*/
          `?taxon wdt:${getPID('taxon/rank')} wd:${getPID(
            'taxon/rank/family',
          )}.`
    }
    ?taxon ^wdt:${getPID('taxon/parent taxon')}* ?subTaxon.
  }
                     
  GROUP BY ?taxon ?name ?authority
  ORDER BY ASC(?name)`);

  return fetch(`${url}${queryOptions.breakCache ? '&nocache=true' : ''}`)
    .then(async response => {
      if (response.status !== 200) {
        throw new Error('Query failed');
      }
      const data = wbApi.simplify.sparqlResults(await response.json());
      console.log('taxon hierarchy', data);
      return data.map((row: any) => ({
        id: row.taxon,
        name: row.name,
        authority: row.authority,
        subTaxaCount: parseInt(row.numSubTaxa),
      }));
    })
    .catch(err => {
      console.error(err);
    });
}

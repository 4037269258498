import React, { useState, useEffect, ReactNode } from 'react';
import TextPlaceholder from 'components/text-placeholder';
import fetchEntityLabel from 'actions/wikibase/fetch-entity-label';

type Props = {
  id: string;
  children?: ReactNode;
  className?: string;
};
export default function EntityLabel({ id, children, className }: Props) {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [label, setLabel] = useState<string | ReactNode>('');
  useEffect(() => {
    setLabel(children || '');
    setLoaded(false);
    if (id) {
      fetchEntityLabel(id)
        .then(label => {
          setLabel(label);
          setLoaded(true);
        })
        .catch(e => {
          setLabel(id);
        });
    }
  }, [id]);
  return (
    <span className={className}>{isLoaded ? label : <TextPlaceholder />}</span>
  );
}

import { APIResponse } from './types';
import { FeatureCollection, Feature, Point } from 'geojson';

export function gbifOccurencesToGeoJSON(
  response: APIResponse,
): FeatureCollection {
  return {
    type: 'FeatureCollection',
    features: response.results.map(
      (res): Feature<Point> => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [res.decimalLongitude, res.decimalLatitude],
        },
        properties: res,
      }),
    ),
  };
}

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TaxonDetail } from 'actions/floracommons/get-taxon';
import { fetchImageryForTaxon } from 'actions/gbif/fetch-imagery-for-taxon';
import { TaxonOccurence, TaxonOccurenceMedia } from 'actions/gbif/types';
import {
  filterMediaToValidImages,
  getAllMediaFromOccurrences,
  getImageUrl,
} from 'actions/gbif/occurrence-imagery';

const Media = styled.div`
  /* height: 120px; */
  display: flex;
  flex-direction: horizontal;
  flex-wrap: no-wrap;
  overflow-y: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
const Image = styled.div`
  img {
    height: 200px;
    width: auto;
    margin-right: 5px;
  }
`;
const Container = styled.div``;
const AllImagesLink = styled.span``;
const FinalImageLink = styled.a`
  display: block;
  height: 200px;
  width: 300px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  color: #555;
  background: #aaa;
  text-align: center;
`;

type Props = {
  taxonData: TaxonDetail;
};

const maxPreviewImages = 10;
export default function TaxonMedia(props: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  // const [apiResults, setApiResults] = useState<TaxonOccurence[]>([]);
  const [images, setImages] = useState<TaxonOccurenceMedia[]>([]);
  const [totalImageCount, setTotalImageCount] = useState(0);

  useEffect(() => {
    if (props.taxonData.identifiers.gbif) {
      fetchImageryForTaxon(
        parseInt(props.taxonData.identifiers.gbif),
        maxPreviewImages,
      ).then(result => {
        setTotalImageCount(result.count);
        setImages(
          filterMediaToValidImages(getAllMediaFromOccurrences(result.results)),
        );
        setIsLoaded(true);
      });
    }
  }, []);
  return isLoaded ? (
    <Container>
      <Media>
        {images.length
          ? images
              .slice(0, Math.min(images.length, maxPreviewImages))
              .map(image => (
                <Image key={image.identifier}>
                  <img src={getImageUrl(image)} />
                </Image>
              ))
          : null}
        {images.length > 0 && totalImageCount > maxPreviewImages && (
          <FinalImageLink
            href={`https://www.gbif.org/occurrence/gallery?taxon_key=${props.taxonData.identifiers.gbif}`}
            target="_blank"
          >
            {totalImageCount - maxPreviewImages} more images
          </FinalImageLink>
        )}
      </Media>
      {images.length && totalImageCount > maxPreviewImages ? (
        <AllImagesLink>
          <a
            href={`https://www.gbif.org/occurrence/gallery?taxon_key=${props.taxonData.identifiers.gbif}`}
            target="_blank"
            rel="noreferrer"
          >
            View all {totalImageCount} images at GBIF.org
          </a>
        </AllImagesLink>
      ) : null}
    </Container>
  ) : (
    <span>Loading media...</span>
  );
}

import { APIResponse } from './types';

export async function fetchPagedResults(
  queryUrl: string,
  page: number = 0,
  perPage: number = 50,
): Promise<APIResponse> {
  const pages: APIResponse[] = [];

  return await fetch(
    `${queryUrl}&limit=${perPage}&offset=${page * perPage}`,
  ).then(res => res.json());
}

export async function fetchAllResults(
  queryUrl: string,
  limit: number = 300,
): Promise<APIResponse> {
  const firstPage: APIResponse = await fetch(`${queryUrl}&limit=${limit}`).then(
    res => res.json(),
  );
  if (firstPage.endOfRecords === true) {
    return firstPage;
  }
  if (firstPage.count + limit > 10000) {
    throw new Error('Result count exceeds API limit');
  }
  const pages: APIResponse[] = [];
  const numPages = Math.ceil(firstPage.count / limit);
  for (var page = 1; page < numPages; page++) {
    pages.push(
      await fetch(`${queryUrl}&limit=${limit}&offset=${page * limit}`).then(
        res => res.json(),
      ),
    );
  }
  return pages.reduce(
    (merged, page) => {
      merged.results.concat(page.results);
      merged.endOfRecords = true;
      merged.offset = 0;
      merged.limit += page.limit;
      return merged;
    },
    { ...firstPage },
  );
}

export default [
  {
    "id": "Q45",
    "label": "unranked",
    "order": -1,
    "uid": "taxon/rank/unranked"
  },
  {
    "id": "Q44",
    "label": "subgroup",
    "order": 10,
    "uid": "taxon/rank/subgroup"
  },
  {
    "id": "Q43",
    "label": "group",
    "order": 20,
    "uid": "taxon/rank/group"
  },
  {
    "id": "Q42",
    "label": "supergroup",
    "order": 30,
    "uid": "taxon/rank/supergroup"
  },
  {
    "id": "Q41",
    "label": "subforma",
    "order": 40,
    "uid": "taxon/rank/subforma"
  },
  {
    "id": "Q40",
    "label": "forma",
    "order": 50,
    "uid": "taxon/rank/forma"
  },
  {
    "id": "Q39",
    "label": "superforma",
    "order": 60,
    "uid": "taxon/rank/superforma"
  },
  {
    "id": "Q38",
    "label": "subvarietas",
    "order": 70,
    "uid": "taxon/rank/subvarietas"
  },
  {
    "id": "Q37",
    "label": "variety",
    "order": 80,
    "uid": "taxon/rank/variety"
  },
  {
    "id": "Q36",
    "label": "supervariety",
    "order": 90,
    "uid": "taxon/rank/supervariety"
  },
  {
    "id": "Q35",
    "label": "subtypestrain",
    "order": 100,
    "uid": "taxon/rank/subtypestrain"
  },
  {
    "id": "Q34",
    "label": "typestrain",
    "order": 110,
    "uid": "taxon/rank/typestrain"
  },
  {
    "id": "Q33",
    "label": "supertypestrain",
    "order": 120,
    "uid": "taxon/rank/supertypestrain"
  },
  {
    "id": "Q32",
    "label": "substrain",
    "order": 130,
    "uid": "taxon/rank/substrain"
  },
  {
    "id": "Q31",
    "label": "strain",
    "order": 140,
    "uid": "taxon/rank/strain"
  },
  {
    "id": "Q30",
    "label": "superstrain",
    "order": 150,
    "uid": "taxon/rank/superstrain"
  },
  {
    "id": "Q29",
    "label": "subspecies",
    "order": 160,
    "uid": "taxon/rank/subspecies"
  },
  {
    "id": "Q28",
    "label": "species",
    "order": 170,
    "uid": "taxon/rank/species"
  },
  {
    "id": "Q27",
    "label": "superspecies",
    "order": 180,
    "uid": "taxon/rank/superspecies"
  },
  {
    "id": "Q26",
    "label": "subseries",
    "order": 190,
    "uid": "taxon/rank/subseries"
  },
  {
    "id": "Q25",
    "label": "series",
    "order": 200,
    "uid": "taxon/rank/series"
  },
  {
    "id": "Q24",
    "label": "superseries",
    "order": 210,
    "uid": "taxon/rank/superseries"
  },
  {
    "id": "Q23",
    "label": "subsection",
    "order": 220,
    "uid": "taxon/rank/subsection"
  },
  {
    "id": "Q22",
    "label": "section",
    "order": 230,
    "uid": "taxon/rank/section"
  },
  {
    "id": "Q21",
    "label": "supersection",
    "order": 240,
    "uid": "taxon/rank/supersection"
  },
  {
    "id": "Q20",
    "label": "subgenus",
    "order": 250,
    "uid": "taxon/rank/subgenus"
  },
  {
    "id": "Q19",
    "label": "genus",
    "order": 260,
    "uid": "taxon/rank/genus"
  },
  {
    "id": "Q18",
    "label": "supergenus",
    "order": 270,
    "uid": "taxon/rank/supergenus"
  },
  {
    "id": "Q17",
    "label": "subtribe",
    "order": 280,
    "uid": "taxon/rank/subtribe"
  },
  {
    "id": "Q16",
    "label": "tribe",
    "order": 290,
    "uid": "taxon/rank/tribe"
  },
  {
    "id": "Q15",
    "label": "supertribe",
    "order": 300,
    "uid": "taxon/rank/supertribe"
  },
  {
    "id": "Q14",
    "label": "subfamily",
    "order": 310,
    "uid": "taxon/rank/subfamily"
  },
  {
    "id": "Q13",
    "label": "family",
    "order": 320,
    "uid": "taxon/rank/family"
  },
  {
    "id": "Q12",
    "label": "superfamily",
    "order": 330,
    "uid": "taxon/rank/superfamily"
  },
  {
    "id": "Q11",
    "label": "infraorder",
    "order": 340,
    "uid": "taxon/rank/infraorder"
  },
  {
    "id": "Q10",
    "label": "suborder",
    "order": 350,
    "uid": "taxon/rank/suborder"
  },
  {
    "id": "Q9",
    "label": "order",
    "order": 360,
    "uid": "taxon/rank/order"
  },
  {
    "id": "Q8",
    "label": "superorder",
    "order": 370,
    "uid": "taxon/rank/superorder"
  },
  {
    "id": "Q7",
    "label": "subclass",
    "order": 380,
    "uid": "taxon/rank/subclass"
  },
  {
    "id": "Q6",
    "label": "class",
    "order": 390,
    "uid": "taxon/rank/class"
  }
]
import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from 'semantic-ui-react';

import fetchTaxaInRadius from 'actions/gbif/fetch-taxa-in-radius';
import { getTaxaByGBIFKeys } from 'actions/floracommons/get-taxon';
import { getWikiDataImagesForTaxa } from 'actions/wikidata/taxon-images';

import LayoutWidth from 'components/layout-width';

import Home from './home';
import Browse from './browse';
import Map from './map';
import Identify from './identify';
// import Media from './media';
import Search from './faceted-search';
import Taxon from './taxon';
import ranks from 'cache/taxon-ranks';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'nav'
    'content';
  height: 100%;
`;
const Content = styled.main`
  grid-area: content;
  overflow-y: auto;
  height: 100%;
`;

const ModuleNav = styled(LayoutWidth)`
  grid-area: nav;
  display: flex;
  flex-direction: row;

  margin-bottom: 5px;
`;

const ModuleBaseNavItem = styled(NavLink)`
  margin-bottom: -2px;
  padding: 10px 20px;

  font-size: 16px;
  color: #555;

  &:hover {
    color: #333;
  }
`;

const ModuleNavItem = styled(({ className, ...props }) => (
  <ModuleBaseNavItem {...props} activeClassName={className} />
))`
  border-bottom: 2px solid #111;
  color: #111;
  font-weight: bold;
`;

const defaultCoords = { lng: -75.7168519, lat: 45.3950098 };

export default function NeighbourhoodFlora() {
  const [location, setLocation] = useState(false);
  const [radius, setRadius] = useState(10000);

  const [locationPermissionStatus, setLocationPermissionStatus] =
    useState(undefined);
  const [hasLocationError, setHasLocationError] = useState(0);
  const [isLoadingTaxa, setTaxaLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [isFetchingTaxaImages, setFetchingTaxaImages] = useState(true);
  const [taxaImages, setTaxaImages] = useState({});

  // const [gbifTaxa, setGbifTaxa] = useState([]);
  const [taxa, setTaxa] = useState([]);

  const { lng, lat } = location ? location : { lng: 0, lat: 0 };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocation({
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          });
        },
        function () {
          setHasLocationError(1);
          setLocation(defaultCoords);
        },
      );
    } else {
      //No browser support geolocation service
      setHasLocationError(-1);
      setLocation(defaultCoords);
    }
  }

  async function hasLocation() {
    const permissionStatus = await navigator?.permissions?.query({
      name: 'geolocation',
    });
    const p = permissionStatus?.state ?? false;
    setLocationPermissionStatus(p);
    return p;
  }

  useEffect(() => {
    // get location at load if it's already been granted
    // hasLocation().then(hasIt => hasIt === 'granted' && getLocation())
    getLocation();
  }, [hasLocationError, locationPermissionStatus]);

  useEffect(() => {
    if (location) {
      fetchTaxaInRadius(location, radius / 1000)
        .then(async gbifRows => {
          // console.log(gbifRows)
          // console.log('Getting FC taxa for GBIF keys')
          const taxa = await getTaxaByGBIFKeys(gbifRows.map(row => row.key));
          if (taxa.length) {
            setFetchingTaxaImages(true);
            const taxaImages = await getWikiDataImagesForTaxa(
              taxa.map(taxon => taxon.name),
            );
            setTaxaImages(taxaImages);
            setFetchingTaxaImages(false);
          }
          const indexOfSpeciesRank = ranks.indexOf(r => r.label === 'species');
          const taxaWithOccurrence = taxa
            // only genus and lower
            .filter(t => ranks.indexOf(t.rank) >= indexOfSpeciesRank)
            // add occurrence coult
            .map(
              t =>
                (t.occurrences = gbifRows.find(
                  r => r.key === t.gbifkey,
                )?.count) && t,
            );
          return taxaWithOccurrence;
        })
        .then(fcTaxa => {
          console.log(fcTaxa);
          setTaxa(fcTaxa);
          setTaxaLoading(false);
        })
        .catch(e => {
          setError(e);
        });
    }
  }, [lat, lng, radius]);

  const handleLocationChange = (coords, radius) => {
    setLocation(coords);
    setRadius(radius);
  };

  return (
    <Container>
      <ModuleNav direction="row">
        <ModuleNavItem to={``} exact>
          <Icon name="home" />
        </ModuleNavItem>
        <ModuleNavItem to={`map`}>
          <Icon name="map" />
          Map
        </ModuleNavItem>
        <ModuleNavItem to={`browse`}>
          <Icon name="tree" />
          Browse
        </ModuleNavItem>
        <ModuleNavItem to={`identify`}>
          <Icon name="eye" />
          Identify
        </ModuleNavItem>
        {/* <ModuleNavItem to={`search`}>
            <Icon name="search" />
            Properties
          </ModuleNavItem> */}
        {/* <ModuleNavItem to={`${path}/media`} >
                <Icon name="map" />Photos
              </ModuleNavItem> */}
      </ModuleNav>
      <Content>
        {hasError ? (
          <div>
            Error encountered. This MVP doesn&apos;t have great error handling
            yet, sorry! Please check console for details and refresh the page to
            try again.
            <div>{hasError}</div>
          </div>
        ) : (
          <Routes>
            <Route
              index
              element={
                <Home
                  onLocationChange={handleLocationChange}
                  {...{
                    location,
                    getLocation,
                    locationPermissionStatus,
                    hasLocationError,
                    taxa,
                    isLoadingTaxa,
                  }}
                />
              }
            />
            <Route
              path={`browse`}
              element={
                <Browse
                  {...{
                    location,
                    getLocation,
                    locationPermissionStatus,
                    hasLocationError,
                    taxa,
                    isLoadingTaxa,
                    isFetchingTaxaImages,
                    taxaImages,
                  }}
                />
              }
            />
            <Route
              path={`map`}
              element={
                <Map
                  onLocationChange={handleLocationChange}
                  {...{
                    location,
                    getLocation,
                    locationPermissionStatus,
                    hasLocationError,
                    taxa,
                    isLoadingTaxa,
                    isFetchingTaxaImages,
                    taxaImages,
                  }}
                />
              }
            />
            <Route
              path={`search`}
              element={
                <Search
                  {...{
                    location,
                    getLocation,
                    locationPermissionStatus,
                    hasLocationError,
                    allLocalTaxa: taxa,
                    isLoadingTaxa,
                    isFetchingTaxaImages,
                    taxaImages,
                  }}
                />
              }
            />
            <Route
              path={`identify`}
              element={
                <Identify
                  {...{
                    location,
                    getLocation,
                    locationPermissionStatus,
                    hasLocationError,
                    allLocalTaxa: taxa,
                    isLoadingTaxa,
                    isFetchingTaxaImages,
                    taxaImages,
                  }}
                />
              }
            />
            <Route
              path={`taxon/:id`}
              element={
                <Taxon
                  {...{
                    location,
                    getLocation,
                    locationPermissionStatus,
                    hasLocationError,
                    allLocalTaxa: taxa,
                    isLoadingTaxa,
                    isFetchingTaxaImages,
                    taxaImages,
                  }}
                />
              }
            />
            {/* <Route path={`media`}>
      <Media actions={actions} {...{location, getLocation, locationPermissionStatus, hasLocationError, taxa, isLoadingTaxa, isFetchingTaxaImages, taxaImages}} />
    </Route> */}
          </Routes>
        )}
      </Content>
    </Container>
  );
}

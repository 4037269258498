import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';

import {
  fetchHigherTaxa,
  fetchSubTaxa,
} from 'actions/floracommons/taxa-hierarchy';

import EntityLabel from 'components/wikibase-entity-label';
import LayoutWidth from 'components/layout-width';
import { getRankById } from 'actions/floracommons/taxon-ranks';
import { getQID } from 'actions/floracommons/pid-uid';

const Section = styled.div`
  /* background: #fff; */
  margin-bottom: 20px;
`;
const List = styled.ul`
  display: flex;
  flex-direction: row;
  justify-items: left;
  list-style: none;
  padding: 0;
  margin: 0;
`;
const Taxon = styled.li`
  padding: 0;
  margin: 0 2em 0 0;
`;
const Name = styled.div``;
const Rank = styled.div`
  font-size: 0.7em;
  text-transform: uppercase;
`;
const SubTaxaLink = styled.span`
  cursor: pointer;
  color: #4183c4;
`;
const SubTaxaList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const SubTaxon = styled.li`
  margin: 0;
  padding: 0;
`;

type Props = {
  taxonId: string;
};
type Hierarchy = { id: string; rank: string; distance: number }[];
type SubTaxa = { id: string; name: string; subTaxaCount: number }[];

export default function TaxonHierarchy(props: Props) {
  const { taxonId } = props;
  const [hierarchy, setHierarchy] = useState<Hierarchy | undefined>(undefined);
  const [subTaxa, setSubTaxa] = useState<SubTaxa | undefined>(undefined);

  useEffect(() => {
    fetchHigherTaxa(taxonId).then(hierarchy => {
      const familyId = getQID('taxon/rank/family');
      const familyRank = getRankById(familyId);
      const lowestRank = hierarchy.at(hierarchy.length - 1);
      if (!familyRank || !lowestRank) {
        setHierarchy(hierarchy);
      } else {
        if (lowestRank.rankOrder >= familyRank?.order) {
          setHierarchy(hierarchy);
        } else {
          setHierarchy(
            hierarchy.filter(
              hierarchy => hierarchy.rankOrder <= familyRank.order,
            ),
          );
        }
      }
    });
    fetchSubTaxa(taxonId).then(res => {
      setSubTaxa(res);
    });
    return () => {
      setHierarchy(undefined);
      setSubTaxa(undefined);
    };
  }, [taxonId]);
  return (
    <Section>
      <List>
        {hierarchy
          ? hierarchy.map(({ id, rank, distance }) => (
              <Taxon key={id}>
                <Rank>
                  <EntityLabel id={rank} />
                </Rank>
                <Name>
                  {id === taxonId ? (
                    <EntityLabel id={id} />
                  ) : (
                    <Link to={`/taxon/${id}`}>
                      <EntityLabel id={id} />
                    </Link>
                  )}
                </Name>
              </Taxon>
            ))
          : 'Loading'}
        <Taxon>
          <Rank>Lower Taxa</Rank>
          <Name>
            {subTaxa !== undefined ? <SubTaxaPopUp taxa={subTaxa} /> : '...'}
          </Name>
        </Taxon>
      </List>
    </Section>
  );
}

type SubTaxaPopUpProps = {
  taxa: SubTaxa;
};

function SubTaxaPopUp(props: SubTaxaPopUpProps) {
  const { taxa } = props;
  if (!taxa.length) {
    return <div>None</div>;
  }
  return (
    <Popup
      trigger={<SubTaxaLink>{`${taxa.length} lower taxa`}</SubTaxaLink>}
      on="click"
      position="bottom left"
    >
      <SubTaxaList>
        {taxa.map(taxon => (
          <SubTaxon key={taxon.id}>
            <Link to={`/taxon/${taxon.id}`}>{taxon.name}</Link>
          </SubTaxon>
        ))}
      </SubTaxaList>
    </Popup>
  );
}

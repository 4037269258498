export const fcEndpoint = {
  instance: process.env.REACT_APP_WIKI || 'http://base.floracommons.org',
  query: process.env.REACT_APP_QUERY || 'http://query.floracommons.org',
  tiles: process.env.REACT_APP_TILES || 'http://tiles.floracommons.org',
  images: process.env.REACT_APP_IMG || 'http://img.floracommons.org',
  sparqlEndpoint:
    process.env.REACT_APP_SPARQL ||
    'http://wdqs.floracommons.org/bigdata/sparql',
};

export const wikidataEndpoint = {
  instance: 'https://wikidata.org',
  sparqlEndpoint: 'https://query.wikidata.org/sparql',
};

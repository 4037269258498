import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  ReactNode,
} from 'react';
import MapLibre, {
  Map as MapLibreMap,
  GeoJSONSource,
  // LayerSpecification,
  LngLat,
  LngLatBounds,
  LngLatBoundsLike,
  LngLatLike,
  // LngLatLike,
  // MapMouseEvent,
  // NavigationControl,
  // Popup,
} from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';
import ReactMapLibre, {
  MapRef,
  Source,
  Layer,
  NavigationControl,
  ScaleControl,
  Popup,
  LayerProps,
  // LngLatBounds,
  // LngLatBoundsLike,
  // LngLatLike,
  HeatmapLayer,
  SymbolLayer,
  CircleLayer,
  ViewStateChangeEvent,
  MapLayerMouseEvent,
} from 'react-map-gl';

import {
  FeatureCollection as GeoJSONFeatureCollection,
  Feature as GeoJSONFeature,
  Point as GeoJSONPoint,
} from 'geojson';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { fcEndpoint } from 'constants/endpoints';

import { TaxonDetail } from 'actions/floracommons/get-taxon';

import gbifLayersJson from './gbif-layers.json';
import northAmericaRegionGeoJson from './na-regions.geo.json';
import fetchTaxonOccurencesInBounds from 'actions/gbif/fetch-taxon-occurrences-in-bounds';
import { gbifOccurencesToGeoJSON } from 'actions/gbif/gbif-occurrences-to-geojson';
import { TaxonOccurence } from 'actions/gbif/types';
import { map, popup } from 'leaflet';
import { getImageUrl } from 'actions/gbif/occurrence-imagery';

// import stripePng from './diagonal-stripe.png';

const initialBounds = new LngLatBounds([-145.51, 39.01], [-50.71, 71.99]);
const maxBounds = new LngLatBounds([-170.4, 12.4], [-33.1, 79.9]);
type Props = {
  taxa: TaxonDetail[];
  layerColors: string[];
};

export default function MapPage(props: Props) {
  const mapRef = React.useRef<MapRef>(null);

  const lastBounds = useRef<LngLatBounds | null>(null);
  const setLastBounds = (bounds: LngLatBounds) => (lastBounds.current = bounds);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);

  const [hiddenLayerIds, setHiddenLayerIds] = useState<string[]>([]);
  const [layerVisibility, setLayerVisibility] = useState<
    Record<string, boolean>
  >({
    presentInRegions: true,
    // occurrenceHeatmapCombined: true,
    occurrenceHeatmapSpecimen: true,
    occurrenceHeatmapObservation: true,
    occurrenceSpecimen: true,
    occurrenceObservation: true,
  });

  console.warn('re-rendering map component');

  const toggleLayerVisibility = (id: string) => {
    layerVisibility[id] = !layerVisibility[id];
    setLayerVisibility({ ...layerVisibility });
  };

  const [gbifOccurrenceGeoJSON, setGbifOccurrenceGeoJSON] =
    useState<GeoJSONFeatureCollection>({
      type: 'FeatureCollection',
      features: [],
    });

  const [cursor, setCursor] = useState<string>('auto');
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState<{
    lngLat: LngLat;
    content: ReactNode | null;
  }>({ lngLat: new LngLat(0, 0), content: null });

  function moveHandler(ev: ViewStateChangeEvent): void {
    if (mapRef.current) {
      //hide tooltip if it's leaving the screen
      if (!mapRef.current.getBounds().contains(popupData.lngLat)) {
        setShowPopup(false);
      }
    }
  }

  // update layers when map is moved
  // - load detailed occurrence data when map is zoomed in
  // - hide it again when zooming out
  function moveEndHandler(ev: ViewStateChangeEvent): void {
    if (mapRef.current) {
      const zoom = mapRef.current.getZoom();
    }
  }

  const isLayerHidden = (id: string) => layerVisibility[id] === false;
  const isLayerVisible = (id: string) => layerVisibility[id] === true;

  const isLayerRendered = (id: string) => {
    if (mapRef.current) {
      const layer = mapRef.current.getLayer(id);
      const zoom = mapRef.current.getZoom();
      //@ts-ignore
      if (layer.maxzoom && zoom > layer.maxzoom) return false;
      //@ts-ignore
      if (layer.minzoom && zoom < layer.minzoom) return false;
    }
    return true;
  };

  return (
    <Container>
      <MapLibreContainer>
        <ReactMapLibre
          mapLib={MapLibre}
          mapStyle={`${fcEndpoint.tiles}/styles/fc-taxon-dist/style.json`}
          ref={mapRef}
          minZoom={2}
          initialViewState={{
            //@ts-ignore
            bounds: initialBounds,
            bearing: 0,
          }}
          //@ts-ignore
          maxBounds={maxBounds}
          maxPitch={0}
          bearingSnap={360}
          cursor={cursor}
          onMove={moveHandler}
          // interactiveLayerIds={interactiveLayerIds}
          style={{ overflow: 'visible' }}
        >
          <NavigationControl showCompass={false} visualizePitch={false} />
          <ScaleControl />

          {props.taxa.map((taxonData, i) => (
            <TaxonLayers
              key={taxonData.id}
              taxonData={taxonData}
              color={props.layerColors[i]}
            />
          ))}
        </ReactMapLibre>
      </MapLibreContainer>
    </Container>
  );
}

type TaxonLayerProps = {
  taxonData: TaxonDetail;
  color: string;
};
function TaxonLayers(props: TaxonLayerProps) {
  return props.taxonData?.identifiers?.gbif !== undefined ? (
    <>
      <Source
        id={`gbif-source-${props.taxonData.id}`}
        type="vector"
        tiles={[
          `https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}.mvt?&taxonKey=${props.taxonData.identifiers.gbif}`,
        ]}
      >
        <Layer
          {...GBIFLayers.occurrenceHeatmapObservation}
          id={`gbif-layer-${props.taxonData.id}`}
          type="circle"
          paint={{
            'circle-color': props.color,
            'circle-opacity': 0.6,
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              // // when zoom is 0, base radius on min(20, total)
              // 0,
              // ['min', ['get', 'total'], 20],
              // when zoom in 5, radius is min(15, total*3)
              5.9,
              ['min', ['max', 3, ['get', 'total']], 15],
              // when zoom is 6, set radius to 15 as circle background to geojson icon
              12,
              ['min', ['max', 5, ['get', 'total']], 15],
            ],
          }}
          // layout={{
          //   visibility: isLayerHidden('occurrenceHeatmapObservation')
          //     ? 'none'
          //     : 'visible',
          // }}
        />
      </Source>
      {/* <Source
        id="gbif-oc-specimen"
        type="vector"
        tiles={[
          `https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}.mvt?&taxonKey=${props.taxonData.identifiers.gbif}&basisOfRecord=MATERIAL_SAMPLE&basisOfRecord=PRESERVED_SPECIMEN&basisOfRecord=FOSSIL_SPECIMEN&basisOfRecord=LIVING_SPECIMEN&basisOfRecord=MATERIAL_CITATION&basisOfRecord=OCCURRENCE`,
        ]}
      >
        <Layer
          {...GBIFLayers.occurrenceHeatmapSpecimen}
          // layout={{
          //   visibility: isLayerHidden('occurrenceHeatmapSpecimen')
          //     ? 'none'
          //     : 'visible',
          // }}
        />
      </Source> */}
    </>
  ) : null;
}

function LayerToggle(props: {
  label: string;
  id: string;
  isChecked: boolean;
  isDisabled: boolean;
  keyEl?: any;
  onChange: (id: string) => void;
}) {
  const { label, id, isChecked, isDisabled, onChange, keyEl } = props;
  return (
    <LayerToggleLabel isDisabled={isDisabled}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => onChange(id)}
        disabled={isDisabled}
      />
      {keyEl} {label}
    </LayerToggleLabel>
  );
}

const Container = styled.div`
  height: 100%;
`;
const MapLibreContainer = styled.div`
  flex: 1;
  height: 100%;
`;
const PopupImage = styled.div`
  height: 200px;
  background-size: cover;
`;

const LayerToggles = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
`;
const LayerToggleLabel = styled.label<{ isDisabled: boolean }>`
  margin-right: 20px;
  vertical-align: center;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

const KeyColor = styled.span<{ color: string }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  border-radius: 4px;
  background-color: ${props => props.color};
`;

function ObservationPopup(props: {
  occurrence: any;
  image: { references: string; rightsHolder: string };
  imageUrl: string;
}) {
  const { occurrence, image, imageUrl } = props;
  return (
    <div>
      {image && imageUrl.length ? (
        <div>
          <PopupImage style={{ backgroundImage: `url(${imageUrl})` }} />
          <small>
            <a href={image.references} target="_blank" rel="noreferrer">
              <em>Photo by</em> ${image.rightsHolder}
            </a>
          </small>
        </div>
      ) : (
        <div>
          Image not available, please see{' '}
          <a
            href={`https://www.gbif.org/occurrence/${occurrence?.key}`}
            target="_blank"
            rel="noreferrer"
          >
            source record
          </a>
        </div>
      )}
      <a
        href={`https://www.gbif.org/occurrence/${occurrence?.key}`}
        target="_blank"
        rel="noreferrer"
      >
        {occurrence?.basisOfRecord} via GBIF
      </a>
      <br />
      {occurrence?.institutionCode} /{' '}
      {dayjs(occurrence?.eventDate).format('DD/MMM/YYYY')}
    </div>
  );
}

function SpecimenPopup(props: { occurrence: any }) {
  const { occurrence } = props;
  return (
    <div>
      <strong>{occurrence?.acceptedScientificName}</strong>
      <br />
      <a
        href={`https://www.gbif.org/occurrence/${occurrence?.key}`}
        target="_blank"
        rel="noreferrer"
      >
        {occurrence?.basisOfRecord} via GBIF
      </a>
      <br />
      {occurrence?.institutionCode}
      <br />
      {occurrence?.eventDate}
    </div>
  );
}

const GBIFLayers: {
  // occurrenceHeatmapCombined: CircleLayer;
  occurrenceHeatmapSpecimen: CircleLayer;
  occurrenceHeatmapObservation: CircleLayer;
  occurrenceSpecimen: SymbolLayer;
  occurrenceObservation: SymbolLayer;
} = {
  // occurrenceHeatmapCombined: {
  //   id: 'occurrenceHeatmapCombined',
  //   type: 'circle',
  //   'source-layer': 'occurrence',
  //   maxzoom: 10,
  //   layout: {
  //     visibility: 'visible',
  //   },
  //   paint: {
  //     'circle-color': 'rgb(203, 30, 30)',
  //     'circle-radius': {
  //       stops: [
  //         [1, 3],
  //         [10, 6],
  //       ],
  //     },
  //   },
  // },
  occurrenceHeatmapSpecimen: {
    id: 'occurrenceHeatmapSpecimen',
    type: 'circle',
    'source-layer': 'occurrence',
    // maxzoom: 10,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'circle-color': 'rgba(255, 153, 0, 0.684)',
      // 'circle-opacity': {
      //   stops: [
      //     [1, 1],
      //     [9, 1],
      //     [10, 0],
      //   ],
      // },
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // // when zoom is 0, base radius on min(20, total)
        // 0,
        // ['min', ['get', 'total'], 20],
        // when zoom in 5, radius is min(15, total*3)
        5.9,
        ['min', ['max', 3, ['get', 'total']], 15],
        // when zoom is 6, set radius to 15 as circle background to geojson icon
        6,
        15,
      ],
      'circle-stroke-width': {
        stops: [
          [5.9, 0],
          [6, 2],
          [10, 3],
        ],
      },
      'circle-stroke-color': 'rgba(255, 255, 255, 1)',
    },
  },
  occurrenceHeatmapObservation: {
    id: 'occurrenceHeatmapObservation',
    type: 'circle',
    'source-layer': 'occurrence',
    // maxzoom: 10,
    paint: {
      'circle-color': 'rgb(59, 145, 232)',
      // 'circle-opacity': {
      //   stops: [
      //     [1, 1],
      //     [9, 1],
      //     [10, 0],
      //   ],
      // },
      // 'circle-radius': [
      //   'interpolate',
      //   ['linear'],
      //   ['zoom'],
      //   // // when zoom is 0, base radius on min(20, total)
      //   // 0,
      //   // ['min', ['get', 'total'], 20],
      //   // when zoom in 5, radius is min(15, total*3)
      //   5.9,
      //   ['min', ['max', 3, ['get', 'total']], 15],
      //   // when zoom is 6, set radius to 15 as circle background to geojson icon
      //   6,
      //   15,
      // ],
      // 'circle-stroke-width': {
      //   stops: [
      //     [5.9, 0],
      //     [6, 2],
      //     [10, 3],
      //   ],
      // },
      // 'circle-stroke-color': 'rgba(255, 255, 255, 1)',
    },
  },
  occurrenceSpecimen: {
    id: 'occurrenceSpecimen',
    type: 'symbol',
    source: 'occurrence-instances',
    filter: ['all', ['!=', 'basisOfRecord', 'HUMAN_OBSERVATION']],
    layout: {
      'icon-image': 'clipboard-notes-white',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
    },
    minzoom: 6,
  },
  occurrenceObservation: {
    id: 'occurrenceObservation',
    type: 'symbol',
    source: 'occurrence-instances',
    filter: ['all', ['==', 'basisOfRecord', 'HUMAN_OBSERVATION']],
    layout: {
      'icon-image': 'camera-white',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
    },
    minzoom: 6,
  },
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import LayoutWidth from 'components/layout-width';

import { Menu, Header, Placeholder, Card, Icon } from 'semantic-ui-react';
import { TaxonViaGBIF } from 'actions/floracommons/get-taxon';
import { IUCNRedlistLabels } from 'constants/iucn-redlist-categories';

const Container = styled.div``;

type Taxon = {
  entity: string;
  uid: string;
  rank: string;
  name: string;
  gbifkey: string;
  occurrences: number;
  iucnCategory?: string;
};

type Props = {
  locationPermissionStatus: string | false;
  hasLocationError: boolean;
  location: [number, number];
  getLocation: () => [number, number];
  isLoadingTaxa: boolean;
  taxa: Taxon[];
  isFetchingTaxaImages: boolean;
  taxaImages: any;
};

export default function NeighbourhoodBrowse(props: Props) {
  const { isLoadingTaxa } = props;

  return (
    <Container>
      <LayoutWidth>
        {isLoadingTaxa ? <Loading /> : <RenderTaxa {...props} />}
      </LayoutWidth>
    </Container>
  );
}

function Loading() {
  return <div>Loading...</div>;
}

const IUCNCategories = Object.keys(IUCNRedlistLabels);

const NoTaxonImage = () => (
  <NoTaxonImageContainer>
    <Icon name="file image outline" size="huge" color="grey" />
  </NoTaxonImageContainer>
);

function RenderTaxa(props: Props) {
  const { taxa, isFetchingTaxaImages, taxaImages } = props;

  const [facet, setFacet] = useState('none');

  const numVulnerableTaxa = taxa.reduce((count, taxon) => {
    if (taxon.iucnCategory && IUCNCategories.indexOf(taxon.iucnCategory) > 3) {
      count++;
    }
    return count;
  }, 0);

  const facetFilters = {
    none: (taxa: Taxon[]) => taxa.sort((a, b) => (a.name > b.name ? 1 : -1)),
    common: (taxa: Taxon[]) =>
      taxa
        .filter((taxon: Taxon) => taxon.occurrences > 19)
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    rare: (taxa: Taxon[]) =>
      taxa
        .filter((taxon: Taxon) => taxon.occurrences < 20)
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    // iucn category above Vulnerable
    iucn: (taxa: Taxon[]) =>
      taxa
        .filter(
          (taxon: Taxon) =>
            taxon.iucnCategory !== undefined &&
            IUCNCategories.indexOf(taxon.iucnCategory) > 3,
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
  };

  const filteredTaxa = facetFilters[facet as keyof typeof facetFilters](taxa);

  return (
    <FacetedBrowser>
      <FacetList vertical>
        <Facet onClick={() => setFacet('none')} active={facet === 'none'}>
          <FacetTitle>Everything</FacetTitle>
          <FacetDescription>
            All plants spotted in your neighbourhood
          </FacetDescription>
        </Facet>
        <Facet onClick={() => setFacet('common')} active={facet === 'common'}>
          <FacetTitle>Common Plants</FacetTitle>
          <FacetDescription>
            Plants you&apos;re sure to see around your neighbourhood
          </FacetDescription>
        </Facet>
        <Facet onClick={() => setFacet('rare')} active={facet === 'rare'}>
          <FacetTitle>Less Common Plants</FacetTitle>
          <FacetDescription>
            Plants with fewer recorded observations in your area
          </FacetDescription>
        </Facet>
        <Facet
          onClick={() => setFacet('iucn')}
          active={facet === 'iucn'}
          // disabled={numVulnerableTaxa === 0}
        >
          <FacetTitle>Endangered</FacetTitle>
          <FacetDescription>
            Plants marked vulnerable or higher on the IUCN Red List
          </FacetDescription>
        </Facet>
      </FacetList>
      <Taxa>
        <Card.Group>
          {filteredTaxa.length ? (
            filteredTaxa.map(taxon => (
              <Card
                key={taxon.entity}
                link
                href={`/my-neighbourhood-flora/taxon/${taxon.entity}`}
              >
                {isFetchingTaxaImages ? (
                  <Placeholder>
                    <Placeholder.Image square />
                  </Placeholder>
                ) : taxaImages[taxon.name] ? (
                  <TaxonImage imageUrl={taxaImages[taxon.name]} />
                ) : (
                  <NoTaxonImage />
                )}
                <Card.Content>
                  <Card.Header>{taxon.name}</Card.Header>
                  <Card.Description>
                    Spotted {taxon.occurrences} times
                  </Card.Description>
                </Card.Content>
              </Card>
            ))
          ) : (
            <NoTaxa>No plants match that filter.</NoTaxa>
          )}
        </Card.Group>
      </Taxa>
    </FacetedBrowser>
  );
}

const FacetedBrowser = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-areas: 'facets results';
`;
const FacetList = styled(Menu)`
  grid-area: facets;
  /* list-style: none;
  padding: 0; */
`;
const Facet = styled(Menu.Item)``;
const FacetTitle = styled(Header)``;
const FacetDescription = styled.p``;

const Taxa = styled.ul`
  grid-area: results;
  list-style: none;
  padding: 0;
`;
const TaxonCard = styled(Card)``;
const TaxonName = styled.h3``;
const TaxonOccurrences = styled.div``;

const TaxonImageBase = styled.div`
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
`;
const TaxonImage = styled(TaxonImageBase)<{ imageUrl: string }>`
  background-image: url(${props => props.imageUrl});
`;

const NoTaxonImageContainer = styled(TaxonImageBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  i {
    opacity: 0.5;
  }
`;

const NoTaxa = styled.div``;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import NavFrame from 'components/nav-frame';
import ErrorBoundary from 'components/error-boundary';
import TaxonNameSearch from 'components/taxon-name-search';

import TaxaMap from './map';
import { fetchTaxonById } from 'actions/floracommons/get-taxon';

type Props = {};

export default function Map(props: Props) {
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [taxaIds, setTaxaIds] = useState<string[]>([]);
  const [taxaData, setTaxaData] = useState<
    Awaited<ReturnType<typeof fetchTaxonById>>[]
  >([]);
  const [provenanceFilters, setProvenanceFilters] = useState({});

  useEffect(() => {
    setLoading(true);
    Promise.all(
      taxaIds.map(id => {
        if (taxaData.find(taxonData => taxonData.id === id)) {
          return Promise.resolve();
        }
        return fetchTaxonById(id as string)
          .then(data => {
            setTaxaData(taxaData.concat(data));
          })
          .catch(e => setError(true))
          .finally(() => setLoading(false));
      }),
    ).then(all => {
      setLoading(false);
    });
  }, [taxaIds]);

  return (
    <NavFrame>
      <Container>
        <ErrorBoundary>
          <TaxaMap taxa={taxaData} layerColors={markerColors} />
        </ErrorBoundary>
        <TaxonAdder>
          <Taxa>
            {taxaData.map((taxonData, i) => (
              <Taxon key={taxonData.id} color={markerColors[i]}>
                <span>{taxonData.name}</span>
              </Taxon>
            ))}
          </Taxa>
          <TaxonNameSearch
            onSelect={result => setTaxaIds(taxaIds.concat(result.id))}
            placeholder="Add taxon to map"
          />
        </TaxonAdder>
      </Container>
    </NavFrame>
  );
}

const markerColors = [
  '#ff0984',
  '#21409a',
  '#e48873',
  '#66d313',
  '#04adff',
  '#f44546',
  '#03a8a0',
  '#f16623',
  '#039c4b',
  '#fedf17',
];

const Container = styled.div`
  height: 100%;
  position: relative;
`;
const TaxonAdder = styled.div`
  position: absolute;
  z-index: 9999;
  left: 10px;
  top: 10px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
const Taxa = styled.div`
  display: flex;
  flex-direction: row;
`;
const Taxon = styled.div<{ color: string }>`
  background: ${props => props.color};
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 5px;
  font-weight: bold;
  span {
    color: ${props => getTextColorForBackground(props.color)};
  }
`;

function getTextColorForBackground(
  color: string,
  light: string = '#fff',
  dark: string = '#000',
) {
  let rgb: [number, number, number] = [0, 0, 0];

  //  match rgba(0,0,0,1)
  if (color.match(/^rgba/)) {
    let matches = color
      .toLowerCase()
      .match(
        /^rgba\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1})\s*\)/,
      );
    if (matches && matches.length) {
      rgb = [parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3])];
    }
    // match rgb(0,0,0)
  } else if (color.match(/^rgb/)) {
    let matches = color
      .toLowerCase()
      .match(
        /^rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/,
      );
    if (matches && matches.length) {
      rgb = [parseInt(matches[1]), parseInt(matches[2]), parseInt(matches[3])];
    }
    // match #000000
  } else if (color.match(/^#/)) {
    let matches = color
      .toLowerCase()
      .match(/^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/);
    if (matches && matches.length) {
      rgb = [
        parseInt(matches[1], 16),
        parseInt(matches[2], 16),
        parseInt(matches[3], 16),
      ];
    }
  } else {
    throw new Error('Invalid color string');
  }

  // const rgb = hex_to_rgb(hex);
  const luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
  return luminance < 140 ? light : dark;
}

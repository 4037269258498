import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Tab, Menu } from 'semantic-ui-react';

import EntityLabel from 'components/wikibase-entity-label';
import {
  getClaimProvenances,
  groupClaimsByProvenance,
} from 'actions/floracommons/provenance';
import {
  Claim,
  ClaimSnakEntityValue,
  ClaimSnakQuantityValue,
} from 'wikibase-types/source';

interface Props {
  initialActiveProvenance?: string;
  showCombined?: boolean;
  hideProvenances?: string[];
  paneComponent?: ReactNode;
  children?: ReactNode;
}
interface AutomaticProps extends Props {
  claims: Claim[];
  allClaims?: Record<string, Claim[]>;
  provenances?: never;
}
interface ManualProps extends Props {
  claims?: never;
  allClaims: Record<string, Claim[]>;
  provenances: string[];
}
/**
 * Renders an inline list of claims, grouped and filterable by provenance
 * @param {*} props
 */
export default function TabbedProvenance(props: AutomaticProps | ManualProps) {
  if (!props.allClaims && !props.provenances && !props.claims) {
    console.error('One of allClaims and provenances, or claims is required');
    return <Empty />;
  }

  let groupedClaims: Record<string, Claim[]> = {};
  let provenances: string[] = [];
  const hideProvenances = props.hideProvenances ?? [];

  if (!props.provenances) {
    groupedClaims = groupClaimsByProvenance(props.claims);
    provenances = Object.keys(groupedClaims).sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }),
    );
  } else {
    provenances = props.provenances;
  }

  const [activeProvenance, setActiveProvenance] = useState(
    props.initialActiveProvenance &&
      provenances.indexOf(props.initialActiveProvenance) > -1
      ? props.initialActiveProvenance
      : undefined,
  );

  const PaneComponent =
    typeof props.paneComponent === 'function' ? props.paneComponent : TabPane;

  let panes;
  if (props.claims) {
    const allProvsHidden = provenances.reduce(
      (hide, prov) => hide && hideProvenances.indexOf(prov) > -1,
      true,
    );
    panes = Object.entries(groupedClaims)
      .filter(([prov]) => hideProvenances.indexOf(prov) === -1)
      .map(([prov, provClaims]) => ({
        menuItem: (
          <Menu.Item key={prov}>
            <EntityLabel id={prov} />
          </Menu.Item>
        ),
        render: () => (
          <PaneComponent
            provenance={prov}
            provenances={props.provenances}
            hideProvenances={props.hideProvenances}
            allClaims={props.allClaims}
            claims={provClaims}
            showCombined={props.showCombined}
          />
        ),
      }));
  } else {
    panes = props.provenances
      .filter(prov => hideProvenances.indexOf(prov) === -1)
      .map(prov => ({
        menuItem: (
          <Menu.Item key={prov}>
            <EntityLabel id={prov} />
          </Menu.Item>
        ),
        render: () => (
          <PaneComponent
            provenance={prov}
            allClaims={props.allClaims}
            provenances={props.provenances}
            showCombined={props.showCombined}
            hideProvenances={props.hideProvenances}
          />
        ),
      }));
  }

  return (
    <Tab
      menu={{ attached: 'bottom' }}
      defaultActiveIndex={
        activeProvenance ? provenances.indexOf(activeProvenance) : 0
      }
      panes={panes}
    />
  );
}

export type TabPaneProps = {
  provenance: string;
  provenances?: string[];
  claims?: Claim[];
  allClaims?: Record<string, Claim[]>;
  showCombined?: boolean;
  hideProvenances?: string[];
};

export function TabPane(props: TabPaneProps) {
  const { claims } = props;
  if (!claims) {
    throw new Error('Default TabPane requires a claims array');
  }
  return (
    <Tab.Pane attached="top">
      <ClaimGroup>
        {claims.map(claim => (
          <ProvenanceClaim key={claim.id} claim={claim} />
        ))}
      </ClaimGroup>
    </Tab.Pane>
  );
}

type ProvenanceClaimProps = {
  claim: Claim;
};
export function ProvenanceClaim(props: ProvenanceClaimProps) {
  const { claim } = props;
  switch (claim?.mainsnak?.datatype) {
    case 'wikibase-item':
      return (
        <ClaimContainer>
          <Label>
            <EntityLabel
              id={
                (claim?.mainsnak?.datavalue as ClaimSnakEntityValue)?.value?.id
              }
            />
          </Label>
        </ClaimContainer>
      );
    case 'quantity':
      return (
        <ClaimContainer>
          <Label>
            {
              (claim?.mainsnak?.datavalue as ClaimSnakQuantityValue)?.value
                ?.amount
            }
          </Label>
        </ClaimContainer>
      );
    case 'string':
    default:
      return (
        <ClaimContainer>
          <Label>{claim?.mainsnak?.datavalue?.value}</Label>
        </ClaimContainer>
      );
  }
}

function Empty() {
  return <Tab.Pane attached="top">None.</Tab.Pane>;
}

const Container = styled.div``;
const TabContent = styled.div``;
const TabBar = styled.nav``;
const TabButton = styled.button``;

const ClaimGroup = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
const ClaimContainer = styled.li`
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;

  &:after {
    content: ', ';
  }
  &:after:last-child {
    content: '.';
  }
`;
const Label = styled.span``;
const Ref = styled.span`
  padding: 0 4px;
  border-radius: 50%;
  background: #ccc;
`;

import { fcEndpoint } from 'constants/endpoints';
import { TaxonOccurence, TaxonOccurenceMedia } from './types';

export function getAllMediaFromOccurrences(occurrences: TaxonOccurence[]) {
  return occurrences.reduce(
    (media, occurrence) => media.concat(occurrence.media),
    [] as TaxonOccurenceMedia[],
  );
}
export function filterMediaToValidImages(media: TaxonOccurenceMedia[]) {
  return media.filter(m => {
    if (m.type !== 'StillImage') return false;
    if (!m.identifier.match('http')) return false;
    return true;
  });
}
export function getImageUrl(image: TaxonOccurenceMedia) {
  if (image) {
    let match;
    switch (image.publisher) {
      case 'iNaturalist':
        match = image.identifier.match(/\/([0-9]+)\/original.(\w+)$/);
        if (match) {
          return `${fcEndpoint.images}/inat/${match[1]}/small.${match[2]}`;
        }
        break;
      default:
        return image.identifier;
    }
  }
  throw new Error('Invalid image object');
}

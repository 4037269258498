import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';

import LayoutWidth from 'components/layout-width';
import NavFrame from 'components/nav-frame';

// import MorphBranch from './morph';

import {
  fetchStructureHierarchy,
  Structure,
  StructureTree,
} from 'actions/floracommons/morphology';

import StructurePanel from './structure';

export function structureTreeHasStatements(structure: Structure): boolean {
  if (structure.statementCount > 0) {
    return true;
  }
  if (structure.substructureCount === 0) {
    return structure.statementCount > 0;
  } else {
    return structure.substructures.some(structureTreeHasStatements);
  }
}

type Props = {};

export default function MorphHierarchy(props: Props) {
  // data state
  const [tree, setTree] = useState<StructureTree>([]);
  // filter state
  const [showEmptyBranches, setShowEmptyBranches] = useState(false);
  const [showUnexpectedOrphans, setShowUnexpectedOrphans] = useState(false);
  const [simplifyHierarchy, setSimplifyHierarchy] = useState(true);
  const [showUnusedStructures, setShowUnusedStructures] = useState(false);
  // ui state
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedStructure, setSelectedStructure] = useState<
    Structure | undefined
  >(undefined);
  const [selectedSubstructure, setSelectedSubstructure] = useState<
    Structure | undefined
  >(undefined);

  const setStructures = (structure: Structure, substructure?: Structure) => {
    setSelectedStructure(structure);
    if (substructure) {
      setSelectedSubstructure(substructure);
    } else {
      setSelectedSubstructure(undefined);
    }
  };

  // fetch data on load
  useEffect(() => {
    fetchStructureHierarchy().then(tree => {
      setTree(tree);
      setIsLoaded(true);
    });
  }, []);

  if (!isLoaded) {
    return (
      <NavFrame>
        <LoadingPlaceholder></LoadingPlaceholder>
      </NavFrame>
    );
  }
  return (
    <NavFrame>
      <HierarchyLayoutWidth>
        <StructureList>
          {tree
            .filter(structure => {
              if (
                !showUnusedStructures &&
                !structureTreeHasStatements(structure)
              ) {
                return false;
              }
              return (
                (showEmptyBranches && showUnexpectedOrphans) ||
                (showEmptyBranches && structure.substructureCount === 0) ||
                (showUnexpectedOrphans && !structure.isSuper) ||
                structure.isSuper
              );
            })
            .map(structure => (
              <StructureItem
                key={structure.id}
                onClick={() => setStructures(structure)}
                isActive={
                  !!selectedStructure && structure.id === selectedStructure.id
                }
              >
                <StructureName>{structure.label}</StructureName>
                {structure.substructureCount > 0 && <SubTaxaIndicator />}
                {!structure.isSuper && <OrphanIndicator />}
              </StructureItem>
            ))}
          {/* <FilterControl>
            <label>
              <input
                type="checkbox"
                checked={showUnexpectedOrphans}
                onChange={ev => setShowUnexpectedOrphans(ev.target.checked)}
              />
              Show Orphans
            </label>
          </FilterControl> */}
          <FilterControl>
            <label>
              <input
                type="checkbox"
                checked={showUnusedStructures}
                onChange={ev => setShowUnusedStructures(ev.target.checked)}
              />
              Show Unused Structures
            </label>
          </FilterControl>
          <FilterControl>
            <label>
              <input
                type="checkbox"
                checked={simplifyHierarchy}
                onChange={ev => setSimplifyHierarchy(ev.target.checked)}
              />
              Simplify Hierarchy
            </label>
          </FilterControl>
          {/* <FilterControl>
            <label>
              <input
                type="checkbox"
                checked={showEmptyBranches}
                onChange={ev => setShowEmptyBranches(ev.target.checked)}
              />
              Show Terminal Branches
            </label>
          </FilterControl> */}
        </StructureList>
        <StructureInfo>
          {selectedStructure && (
            <StructurePanel
              structure={selectedStructure}
              selectedSubstructure={selectedSubstructure}
              onSelectSubstructure={substructure =>
                setStructures(selectedStructure, substructure)
              }
              simplifyHierarchy={simplifyHierarchy}
              showUnusedStructures={showUnusedStructures}
            />
          )}
          {!selectedStructure && (
            <EmptyStructure>Select a structure</EmptyStructure>
          )}
        </StructureInfo>
      </HierarchyLayoutWidth>
    </NavFrame>
  );
}

const LoadingPlaceholder = styled(
  ({ className }: { className?: string; children?: ReactNode }) => (
    <Placeholder fluid className={className}>
      {new Array(20).fill(true).map((a, i) => (
        <Placeholder.Header key={i}>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      ))}
    </Placeholder>
  ),
)``;

const HierarchyLayoutWidth = styled(LayoutWidth)`
  font-size: 1.3em;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: 'structures info';
  min-height: 100%;
`;
const StructureList = styled.div`
  grid-area: structures;
  padding: 40px 0px;
  background: rgba(220, 220, 220, 1);
`;
const StructureItem = styled.div<{ isActive: boolean }>`
  padding: 8px 15px;
  margin: -4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${props => props.isActive && `background-color: white;`}
`;
const StructureName = styled.span`
  flex: 1;
`;
const SubTaxaIndicator = styled.i`
  &:after {
    content: '▶';
    font-style: normal;
    font-size: 0.5em;
    justify-self: flex-end;
    opacity: 0.5;
  }
`;
const OrphanIndicator = styled.i`
  order: -1;

  &:after {
    content: '┗';
    font-style: normal;
    font-size: 0.5em;
    opacity: 0.5;
  }
`;
const FilterControl = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  padding: 8px 15px;
`;
const StructureInfo = styled.div``;
const EmptyStructure = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em;
  color: rgba(0, 0, 0, 0.1);
`;

import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useNavigate,
  useMatch,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Segment,
  Dimmer,
  Loader,
  Image,
  Menu,
  Modal,
  Input,
  Icon,
} from 'semantic-ui-react';
import styled, { createGlobalStyle } from 'styled-components';
import { Main, Box, FillBox } from 'components/ui/Box';
import theme from './theme';

import 'fomantic-ui-css/semantic.min.css';

import actions from './actions/index';

import HeaderSearch from 'components/taxon-name-search';
import LayoutWidth from 'components/layout-width';
import Sidebar from 'components/sidebar';

import Home from 'pages/home';
import StructureCharacterSearch from 'pages/structure-character';
import FacetedSearch from 'pages/faceted-search';
import Taxon from 'pages/taxon';
import TaxonHierarchy from 'pages/taxon-hierarchy';

import Neighbourhood from 'projects/neighbourhood-flora/pages';
import MorphExplorer from 'pages/morph-explorer';
import Map from 'pages/map';

function App() {
  const [isFetching, setIsFetching] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [sidebarIsOpen, setSidebarOpen] = useState(false);

  function toggleSidebar() {
    setSidebarOpen(!sidebarIsOpen);
  }

  return (
    <BrowserRouter>
      <Helmet>
        <title>Canadian Flora Commons</title>
      </Helmet>
      <SidebarGlobalCSS />
      <Sidebar isOpen={sidebarIsOpen} onClose={() => setSidebarOpen(false)}>
        <Module isFetching={isFetching} toggleSidebar={toggleSidebar} />
      </Sidebar>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        basic
        size="fullscreen"
      >
        <Modal.Content>This is a modal yo</Modal.Content>
        {/* <Modal.Actions>
          <Button color='green' onClick={this.handleClose} inverted>
            <Icon name='checkmark' /> Got it
          </Button>
        </Modal.Actions> */}
      </Modal>
    </BrowserRouter>
  );
}

export default App;

type ModuleProps = {
  isFetching: boolean;
  toggleSidebar: () => void;
};
function Module(props: ModuleProps) {
  const { isFetching, toggleSidebar } = props;
  let { pathname } = useLocation();
  const navigate = useNavigate();

  // quick hack for module based on url path
  const modulesByPath = [
    {
      path: '/my-neighbourhood-flora',
      title: 'My Neighbourhood Flora',
      headerBackgroundColor: '#97c7ec',
    },
  ];
  const { path, title, headerBackgroundColor } = modulesByPath.find(
    ({ path, title }) => (pathname.match(path) ? true : false),
  ) || {
    title: 'Canadian Flora Commons',
    path: '/',
    headerBackgroundColor: undefined,
  };

  return (
    <AppGrid>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href="/logo.svg" type="image/svg+xml"></link>
      </Helmet>
      <AppHeader backgroundColor={headerBackgroundColor}>
        <HeaderLayoutWidth>
          <SidebarToggle onClick={() => toggleSidebar()} />
          <AppTitle>
            <Link to="/">
              <img src="/logo.svg" height="40px" />
            </Link>
          </AppTitle>
          <ModuleTitle>
            <Link to={path}>{title}</Link>
          </ModuleTitle>
          {/* <HeaderNav direction="row">
        <NavItem to="/" exact>
          <Icon name="home" />Home
        </NavItem>
        <NavItem to="/faceted-search" >
          <Icon name="search" />Faceted Search
        </NavItem>
        <NavItem to="/taxon-hierarchy" >
          <Icon name="tree" />Hierarchy
        </NavItem>
        <NavItem to="/map-test" >
          <Icon name="map" />Map Test
        </NavItem>
      </HeaderNav> */}
          <AppSearch>
            <HeaderSearch
              onSelect={result => navigate(`/taxon/${result.id}`)}
            />
          </AppSearch>
        </HeaderLayoutWidth>
      </AppHeader>
      <AppMain>
        {isFetching && (
          <Segment>
            <Dimmer active={isFetching} page>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route
            path="/morphology-facets"
            element={<StructureCharacterSearch />}
          /> */}
          <Route path="faceted-search" element={<FacetedSearch />} />
          <Route path="taxon-hierarchy" element={<TaxonHierarchy />} />
          <Route path="morphology-explorer" element={<MorphExplorer />} />
          <Route path="map" element={<Map />} />
          <Route path="taxon/:id" element={<Taxon />} />

          <Route path="my-neighbourhood-flora/*" element={<Neighbourhood />} />
        </Routes>
      </AppMain>
    </AppGrid>
  );
}

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'main';
  height: 100%;
`;

const AppHeader = styled.header<{ backgroundColor?: string }>`
  grid-area: header;

  /* border-bottom: 2px solid #ccc; */
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#b5d8a3'};

  /* @media only screen and (min-width: 1400px) {
    width: 1200px;
  } */
`;

const HeaderLayoutWidth = styled(LayoutWidth)`
  display: flex;
  justify-content: center;
  padding: 10px 10px 0 10px;
`;

const AppMain = styled.div`
  grid-area: main;

  width: 100vw;
  min-height: 100%;
  overflow: hidden;
`;

const AppTitle = styled.h1`
  font-size: 1.4em;
  white-space: nowrap;
  margin: 0 20px 0 0;

  a {
    color: #333;
  }
`;

const ModuleTitle = styled.h1`
  font-size: 1.4em;
  white-space: nowrap;
  margin: 0 20px 0 20px;
  flex: 1 0 auto;
  text-align: center;

  a {
    color: #333;
  }
`;

const AppSearch = styled.div``;
const AppSearchInput = styled(Input)``;

const HeaderNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-self: center;
  flex: 1;
`;

const BaseNavItem = styled(NavLink)`
  margin-bottom: -2px;
  padding: 10px 20px;

  font-size: 16px;
  color: #555;

  &:hover {
    color: #333;
  }
`;

const NavItem = styled(({ className, ...props }) => (
  <BaseNavItem {...props} activeClassName={className} />
))`
  border-bottom: 2px solid #111;
  color: #111;
  font-weight: bold;
`;

const ModuleNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-self: center;
  flex: 1;
`;

const ModuleBaseNavItem = styled(NavLink)`
  margin-bottom: -2px;
  padding: 10px 20px;

  font-size: 16px;
  color: #555;

  &:hover {
    color: #333;
  }
`;

const ModuleNavItem = styled(({ className, ...props }) => (
  <BaseNavItem {...props} activeClassName={className} />
))`
  border-bottom: 2px solid #111;
  color: #111;
  font-weight: bold;
`;

const SidebarToggle = styled(({ className, onClick }) => (
  <Icon name="sidebar" size="big" className={className} onClick={onClick} />
))`
  cursor: pointer;
`;

const SidebarGlobalCSS = createGlobalStyle`
  .pusher {
    height: 100%;
  }
`;

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  ReactNode,
} from 'react';
import MapLibre, {
  Map as MapLibreMap,
  GeoJSONSource,
  // LayerSpecification,
  LngLat,
  LngLatBounds,
  LngLatBoundsLike,
  LngLatLike,
  // LngLatLike,
  // MapMouseEvent,
  // NavigationControl,
  // Popup,
} from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';
import ReactMapLibre, {
  MapRef,
  Source,
  Layer,
  NavigationControl,
  ScaleControl,
  Popup,
  LayerProps,
  // LngLatBounds,
  // LngLatBoundsLike,
  // LngLatLike,
  HeatmapLayer,
  SymbolLayer,
  CircleLayer,
  ViewStateChangeEvent,
  MapLayerMouseEvent,
} from 'react-map-gl';

import {
  FeatureCollection as GeoJSONFeatureCollection,
  Feature as GeoJSONFeature,
  Point as GeoJSONPoint,
} from 'geojson';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { fcEndpoint } from 'constants/endpoints';

import { TaxonDetail } from 'actions/floracommons/get-taxon';

import gbifLayersJson from './gbif-layers.json';
import northAmericaRegionGeoJson from './na-regions.geo.json';
import fetchTaxonOccurencesInBounds from 'actions/gbif/fetch-taxon-occurrences-in-bounds';
import { gbifOccurencesToGeoJSON } from 'actions/gbif/gbif-occurrences-to-geojson';
import { TaxonOccurence } from 'actions/gbif/types';
import { map, popup } from 'leaflet';
import { getImageUrl } from 'actions/gbif/occurrence-imagery';
import { getQID, getUID } from 'actions/floracommons/pid-uid';

// import stripePng from './diagonal-stripe.png';

const initialBounds = new LngLatBounds([-145.51, 39.01], [-50.71, 71.99]);
const maxBounds = new LngLatBounds([-170.4, 12.4], [-33.1, 79.9]);
type Props = {
  hideProvenances: string[];
  taxonData: TaxonDetail;
  presentIn: string[];
};

export default function MapPage(props: Props) {
  const { taxonData, presentIn } = props;

  const mapRef = React.useRef<MapRef>(null);

  const lastBounds = useRef<LngLatBounds | null>(null);
  const setLastBounds = (bounds: LngLatBounds) => (lastBounds.current = bounds);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState<string[]>([]);

  const [hiddenLayerIds, setHiddenLayerIds] = useState<string[]>([]);
  const [layerVisibility, setLayerVisibility] = useState<
    Record<string, boolean>
  >({
    presentInRegions: true,
    // occurrenceHeatmapCombined: true,
    occurrenceHeatmapSpecimen: true,
    occurrenceHeatmapObservation: true,
    occurrenceSpecimen: true,
    occurrenceObservation: true,
  });
  const toggleLayerVisibility = (id: string) => {
    layerVisibility[id] = !layerVisibility[id];
    setLayerVisibility({ ...layerVisibility });
  };

  const [gbifOccurrenceGeoJSON, setGbifOccurrenceGeoJSON] =
    useState<GeoJSONFeatureCollection>({
      type: 'FeatureCollection',
      features: [],
    });

  const [cursor, setCursor] = useState<string>('auto');
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState<{
    lngLat: LngLat;
    content: ReactNode | null;
  }>({ lngLat: new LngLat(0, 0), content: null });

  const onMapLoad = React.useCallback(() => {
    if (!mapRef.current) return;
    if (taxonData.identifiers.gbif !== undefined) {
      setInteractiveLayerIds(['occurrenceSpecimen', 'occurrenceObservation']);
    }
  }, []);

  const mapClickHandler = (e: MapLayerMouseEvent) => {
    if (e.features && e.features.length) {
      const feature = e.features[0];
      if (feature?.layer === undefined) {
        return;
      }
      if (feature.layer.id === 'occurrenceObservation') {
        //@ts-ignore
        const coordinates = feature.geometry.coordinates.slice();
        const p = feature!.properties;

        //geojson properties are encoded
        const media = p?.media ? JSON.parse(p.media) : [];

        const image =
          media?.length &&
          media.find((img: any) => img?.license.match('creativecommons'));
        let imageUrl = '';
        if (image) {
          imageUrl = getImageUrl(image);
        }
        setPopupData({
          //@ts-ignore
          lngLat: { lng: coordinates[0], lat: coordinates[1] },
          content: (
            <ObservationPopup
              imageUrl={imageUrl}
              occurrence={p}
              image={image}
            />
          ),
        });
        setShowPopup(true);
        return;
      }
      if (feature.layer.id === 'occurrenceSpecimen') {
        const feature = e.features[0]; // as GeoJSONFeature<GeoJSONPoint>;
        //@ts-ignore
        const coordinates = feature.geometry.coordinates.slice();
        const p = feature!.properties;

        console.log(feature);

        setPopupData({
          //@ts-ignore
          lngLat: { lng: coordinates[0], lat: coordinates[1] },
          content: <SpecimenPopup occurrence={p} />,
        });
        setShowPopup(true);
      }
    }
  };

  function moveHandler(ev: ViewStateChangeEvent): void {
    if (mapRef.current) {
      //hide tooltip if it's leaving the screen
      if (!mapRef.current.getBounds().contains(popupData.lngLat)) {
        setShowPopup(false);
      }
    }
  }

  // update layers when map is moved
  // - load detailed occurrence data when map is zoomed in
  // - hide it again when zooming out
  function moveEndHandler(ev: ViewStateChangeEvent): void {
    if (mapRef.current) {
      const zoom = mapRef.current.getZoom();

      if (zoom >= 6) {
        const bounds = mapRef.current.getBounds() as unknown as LngLatBounds;
        if (
          lastBounds.current &&
          lastBounds.current.contains(bounds.getNorthEast()) &&
          lastBounds.current.contains(bounds.getSouthWest())
        ) {
          console.log('new bounds in old bounds, skipping api request');
          // if the map is being zoomed in, there's no need to update occurrence geometry
          return;
        }
        //update the cached bounds
        setLastBounds(bounds);

        if (taxonData?.identifiers?.gbif) {
          fetchTaxonOccurencesInBounds(
            parseInt(taxonData.identifiers.gbif),
            bounds,
          )
            .then(gbifOccurencesToGeoJSON)
            .then(geojson => {
              setGbifOccurrenceGeoJSON(geojson);
            });
        }
      } else {
        // Below zoomlevel 6, hide detailed GeoJSON
        setGbifOccurrenceGeoJSON({
          type: 'FeatureCollection',
          features: [],
        });
        setShowPopup(false);
        lastBounds.current = null;
      }
    }
  }

  const regionFilters = [
    'any',
    ...props.presentIn.map(regionIso => ['==', 'iso-3166–2', regionIso]),
  ];

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const isLayerHidden = (id: string) => layerVisibility[id] === false;
  const isLayerVisible = (id: string) => layerVisibility[id] === true;

  const isLayerRendered = (id: string) => {
    if (mapRef.current) {
      try {
        const layer = mapRef.current.getLayer(id);
        const zoom = mapRef.current.getZoom();
        //@ts-ignore
        if (layer.maxzoom && zoom > layer.maxzoom) return false;
        //@ts-ignore
        if (layer.minzoom && zoom < layer.minzoom) return false;
      } catch (e) {
        //layer not defined
      }
    }
    return true;
  };

  return (
    <Container>
      <MapLibreContainer>
        <ReactMapLibre
          mapLib={MapLibre}
          mapStyle={`${fcEndpoint.tiles}/styles/fc-taxon-dist/style.json`}
          ref={mapRef}
          onLoad={onMapLoad}
          minZoom={2}
          initialViewState={{
            //@ts-ignore
            bounds: initialBounds,
            bearing: 0,
          }}
          scrollZoom={false}
          touchZoom={false}
          touchRotate={false}
          //@ts-ignore
          maxBounds={maxBounds}
          maxPitch={0}
          bearingSnap={360}
          cursor={cursor}
          // show pointer on mouse enter an interactive feature
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={mapClickHandler}
          onMove={moveHandler}
          onMoveEnd={moveEndHandler}
          interactiveLayerIds={interactiveLayerIds}
          style={{ overflow: 'visible' }}
        >
          <NavigationControl showCompass={false} visualizePitch={false} />
          <ScaleControl />

          <>
            <Source
              id="na-regions"
              type="geojson"
              data={northAmericaRegionGeoJson as GeoJSONFeatureCollection}
            >
              <Layer
                id="presentInRegion"
                type="fill"
                filter={regionFilters}
                maxzoom={6}
                paint={{
                  'fill-color': '#99f67f',
                  'fill-outline-color': 'rgba(102, 102, 102, 0.5)',
                  'fill-opacity': {
                    stops: [
                      [5, 0.5],
                      [6, 0],
                    ],
                  },
                }}
                layout={{
                  visibility: isLayerHidden('presentInRegion')
                    ? 'none'
                    : 'visible',
                }}
              />
            </Source>

            {/* <Source
                id="gbif-oc-combined"
                type="vector"
                tiles={[
                  `https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}.mvt?&taxonKey=${taxonData.identifiers.gbif}`,
                ]}
              >
                <Layer
                  {...GBIFLayers.occurrenceHeatmapCombined}
                  layout={{
                    visibility: isLayerHidden('occurrenceHeatmapCombined')
                      ? 'none'
                      : 'visible',
                  }}
                />
              </Source> */}
            {taxonData?.identifiers?.gbif !== undefined &&
              props.hideProvenances.indexOf(getQID('provenance/gbif')) < 0 && (
                <>
                  <Source
                    id="gbif-oc-observation"
                    type="vector"
                    tiles={[
                      `https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}.mvt?&taxonKey=${taxonData.identifiers.gbif}&basisOfRecord=OBSERVATION&basisOfRecord=HUMAN_OBSERVATION&basisOfRecord=MACHINE_OBSERVATION`,
                    ]}
                  >
                    <Layer
                      {...GBIFLayers.occurrenceHeatmapObservation}
                      layout={{
                        visibility: isLayerHidden(
                          'occurrenceHeatmapObservation',
                        )
                          ? 'none'
                          : 'visible',
                      }}
                    />
                  </Source>
                  <Source
                    id="gbif-oc-specimen"
                    type="vector"
                    tiles={[
                      `https://api.gbif.org/v2/map/occurrence/density/{z}/{x}/{y}.mvt?&taxonKey=${taxonData.identifiers.gbif}&basisOfRecord=MATERIAL_SAMPLE&basisOfRecord=PRESERVED_SPECIMEN&basisOfRecord=FOSSIL_SPECIMEN&basisOfRecord=LIVING_SPECIMEN&basisOfRecord=MATERIAL_CITATION&basisOfRecord=OCCURRENCE`,
                    ]}
                  >
                    <Layer
                      {...GBIFLayers.occurrenceHeatmapSpecimen}
                      layout={{
                        visibility: isLayerHidden('occurrenceHeatmapSpecimen')
                          ? 'none'
                          : 'visible',
                      }}
                    />
                  </Source>
                  <Source
                    id="occurrence-instances"
                    type="geojson"
                    data={gbifOccurrenceGeoJSON}
                    // cluster={true}
                    // clusterRadius={10}
                  >
                    <Layer
                      {...GBIFLayers.occurrenceObservation}
                      layout={{
                        ...GBIFLayers.occurrenceObservation.layout,
                        visibility: isLayerHidden('occurrenceObservation')
                          ? 'none'
                          : 'visible',
                      }}
                    />
                    <Layer
                      {...GBIFLayers.occurrenceSpecimen}
                      layout={{
                        ...GBIFLayers.occurrenceSpecimen.layout,
                        visibility: isLayerHidden('occurrenceSpecimen')
                          ? 'none'
                          : 'visible',
                      }}
                    />
                  </Source>
                </>
              )}
          </>

          {showPopup && (
            <Popup
              longitude={popupData.lngLat.lng}
              latitude={popupData.lngLat.lat}
              anchor="bottom"
              offset={[0, -10]}
              maxWidth="400px"
              onClose={() => setShowPopup(false)}
              closeOnClick={false}
            >
              {popupData.content}
            </Popup>
          )}

          <LayerToggles>
            <LayerToggle
              id="presentInRegion"
              label="Regional Distribution"
              isChecked={!isLayerHidden('presentInRegion')}
              isDisabled={!isLayerRendered('presentInRegion')}
              onChange={id => toggleLayerVisibility(id)}
              keyEl={<KeyColor color="rgb(95, 193, 68)" />}
            />
            {taxonData.identifiers.gbif !== undefined && (
              <>
                <LayerToggle
                  id="occurrenceHeatmapObservation"
                  label="Human Observation"
                  isChecked={isLayerVisible('occurrenceHeatmapObservation')}
                  isDisabled={!isLayerRendered('occurrenceHeatmapObservation')}
                  onChange={() => {
                    toggleLayerVisibility('occurrenceHeatmapObservation');
                    toggleLayerVisibility('occurrenceObservation');
                  }}
                  keyEl={<KeyColor color="rgb(59, 145, 232)" />}
                />
                <LayerToggle
                  id="occurrenceHeatmapSpecimen"
                  label="Specimen Occurrence"
                  isChecked={isLayerVisible('occurrenceHeatmapSpecimen')}
                  isDisabled={!isLayerRendered('occurrenceHeatmapSpecimen')}
                  onChange={() => {
                    toggleLayerVisibility('occurrenceHeatmapSpecimen');
                    toggleLayerVisibility('occurrenceSpecimen');
                  }}
                  keyEl={<KeyColor color="orange" />}
                />
              </>
            )}
            {/* <LayerToggle
              id="occurrenceHeatmapCombined"
              label="Combined Heatmap"
              isChecked={isLayerVisible('occurrenceHeatmapCombined')}
              isDisabled={!isLayerRendered('occurrenceHeatmapCombined')}
              onChange={id => toggleLayerVisibility(id)}
              keyEl={<KeyColor color="blue" />}
            /> */}
            {/* <LayerToggle
              id="occurrenceObservation"
              label="Human Observation Details"
              isChecked={!isLayerHidden('occurrenceObservation')}
              isDisabled={!isLayerRendered('occurrenceObservation')}
              onChange={id => toggleLayerVisibility(id)}
            />
            <LayerToggle
              id="occurrenceSpecimen"
              label="Specimen Occurrence Details"
              isChecked={!isLayerHidden('occurrenceSpecimen')}
              isDisabled={!isLayerRendered('occurrenceSpecimen')}
              onChange={id => toggleLayerVisibility(id)}
            /> */}
          </LayerToggles>
        </ReactMapLibre>
      </MapLibreContainer>
    </Container>
  );
}

function LayerToggle(props: {
  label: string;
  id: string;
  isChecked: boolean;
  isDisabled: boolean;
  keyEl?: any;
  onChange: (id: string) => void;
}) {
  const { label, id, isChecked, isDisabled, onChange, keyEl } = props;
  return (
    <LayerToggleLabel isDisabled={isDisabled}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => onChange(id)}
        disabled={isDisabled}
      />
      {keyEl} {label}
    </LayerToggleLabel>
  );
}

const Container = styled.div``;
const MapLibreContainer = styled.div`
  flex: 1;
  height: 500px;
`;
const PopupImage = styled.div`
  height: 200px;
  background-size: cover;
`;

const LayerToggles = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
`;
const LayerToggleLabel = styled.label<{ isDisabled: boolean }>`
  margin-right: 20px;
  vertical-align: center;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;

const KeyColor = styled.span<{ color: string }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 3px;
  border-radius: 4px;
  background-color: ${props => props.color};
`;

function ObservationPopup(props: {
  occurrence: any;
  image: { references: string; rightsHolder: string };
  imageUrl: string;
}) {
  const { occurrence, image, imageUrl } = props;
  return (
    <div>
      {image && imageUrl.length ? (
        <div>
          <PopupImage style={{ backgroundImage: `url(${imageUrl})` }} />
          <small>
            <a href={image.references} target="_blank" rel="noreferrer">
              <em>Photo by</em> ${image.rightsHolder}
            </a>
          </small>
        </div>
      ) : (
        <div>
          Image not available, please see{' '}
          <a
            href={`https://www.gbif.org/occurrence/${occurrence?.key}`}
            target="_blank"
            rel="noreferrer"
          >
            source record
          </a>
        </div>
      )}
      <a
        href={`https://www.gbif.org/occurrence/${occurrence?.key}`}
        target="_blank"
        rel="noreferrer"
      >
        {occurrence?.basisOfRecord} via GBIF
      </a>
      <br />
      {occurrence?.institutionCode} /{' '}
      {dayjs(occurrence?.eventDate).format('DD/MMM/YYYY')}
    </div>
  );
}

function SpecimenPopup(props: { occurrence: any }) {
  const { occurrence } = props;
  return (
    <div>
      <strong>{occurrence?.acceptedScientificName}</strong>
      <br />
      <a
        href={`https://www.gbif.org/occurrence/${occurrence?.key}`}
        target="_blank"
        rel="noreferrer"
      >
        {occurrence?.basisOfRecord} via GBIF
      </a>
      <br />
      {occurrence?.institutionCode}
      <br />
      {occurrence?.eventDate}
    </div>
  );
}

const GBIFLayers: {
  // occurrenceHeatmapCombined: CircleLayer;
  occurrenceHeatmapSpecimen: CircleLayer;
  occurrenceHeatmapObservation: CircleLayer;
  occurrenceSpecimen: SymbolLayer;
  occurrenceObservation: SymbolLayer;
} = {
  // occurrenceHeatmapCombined: {
  //   id: 'occurrenceHeatmapCombined',
  //   type: 'circle',
  //   'source-layer': 'occurrence',
  //   maxzoom: 10,
  //   layout: {
  //     visibility: 'visible',
  //   },
  //   paint: {
  //     'circle-color': 'rgb(203, 30, 30)',
  //     'circle-radius': {
  //       stops: [
  //         [1, 3],
  //         [10, 6],
  //       ],
  //     },
  //   },
  // },
  occurrenceHeatmapSpecimen: {
    id: 'occurrenceHeatmapSpecimen',
    type: 'circle',
    'source-layer': 'occurrence',
    // maxzoom: 10,
    layout: {
      visibility: 'visible',
    },
    paint: {
      'circle-color': 'rgba(255, 153, 0, 0.684)',
      // 'circle-opacity': {
      //   stops: [
      //     [1, 1],
      //     [9, 1],
      //     [10, 0],
      //   ],
      // },
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // // when zoom is 0, base radius on min(20, total)
        // 0,
        // ['min', ['get', 'total'], 20],
        // when zoom in 5, radius is min(15, total*3)
        5.9,
        ['min', ['max', 3, ['get', 'total']], 15],
        // when zoom is 6, set radius to 15 as circle background to geojson icon
        6,
        15,
      ],
      'circle-stroke-width': {
        stops: [
          [5.9, 0],
          [6, 2],
          [10, 3],
        ],
      },
      'circle-stroke-color': 'rgba(255, 255, 255, 1)',
    },
  },
  occurrenceHeatmapObservation: {
    id: 'occurrenceHeatmapObservation',
    type: 'circle',
    'source-layer': 'occurrence',
    // maxzoom: 10,
    paint: {
      'circle-color': 'rgb(59, 145, 232)',
      // 'circle-opacity': {
      //   stops: [
      //     [1, 1],
      //     [9, 1],
      //     [10, 0],
      //   ],
      // },
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // // when zoom is 0, base radius on min(20, total)
        // 0,
        // ['min', ['get', 'total'], 20],
        // when zoom in 5, radius is min(15, total*3)
        5.9,
        ['min', ['max', 3, ['get', 'total']], 15],
        // when zoom is 6, set radius to 15 as circle background to geojson icon
        6,
        15,
      ],
      'circle-stroke-width': {
        stops: [
          [5.9, 0],
          [6, 2],
          [10, 3],
        ],
      },
      'circle-stroke-color': 'rgba(255, 255, 255, 1)',
    },
  },
  occurrenceSpecimen: {
    id: 'occurrenceSpecimen',
    type: 'symbol',
    source: 'occurrence-instances',
    filter: ['all', ['!=', 'basisOfRecord', 'HUMAN_OBSERVATION']],
    layout: {
      'icon-image': 'clipboard-notes-white',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
    },
    minzoom: 6,
  },
  occurrenceObservation: {
    id: 'occurrenceObservation',
    type: 'symbol',
    source: 'occurrence-instances',
    filter: ['all', ['==', 'basisOfRecord', 'HUMAN_OBSERVATION']],
    layout: {
      'icon-image': 'camera-white',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
    },
    minzoom: 6,
  },
};

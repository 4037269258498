import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Placeholder, Loader, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { fetchSubTaxa } from 'actions/floracommons/taxa-hierarchy';

type Props = {
  of?: string;
  depth?: number;
};

export default function SubTaxa(props: Props) {
  const { of, depth = 1 } = props;

  useEffect(() => {
    setTaxa(undefined);
    fetchSubTaxa(of).then(res => {
      setTaxa(res);
    });
  }, [of]);

  const [taxa, setTaxa] = useState<
    | { id: string; name: string; authority: string; subTaxaCount: number }[]
    | undefined
  >(undefined);
  const [openTaxa, setOpenTaxa] = useState<string[]>([]);

  const updateOpenTaxa = (taxon: { id: string; name: string }) => {
    setOpenTaxa(oldOpenTaxa => {
      const openTaxa = [...oldOpenTaxa];
      if (openTaxa.indexOf(taxon.id) > -1) {
        openTaxa.splice(openTaxa.indexOf(taxon.id));
      } else {
        openTaxa.push(taxon.id);
      }
      console.log(openTaxa);
      return openTaxa;
    });
  };

  const handleTaxonClick = (ev: any, taxon: any) => {
    updateOpenTaxa(taxon);
  };

  return (
    <Wrapper>
      {taxa === undefined ? (
        <Loader />
      ) : (
        <Taxa>
          {taxa.map(taxon => (
            <Taxon key={taxon.id} depth={depth}>
              <TaxonLeaf>
                {taxon.subTaxaCount ? (
                  <NumSubTaxa onClick={ev => handleTaxonClick(ev, taxon)}>
                    {/*taxon.numSubTaxa*/} <Icon name="angle down" />
                  </NumSubTaxa>
                ) : (
                  <NumSubTaxa />
                )}
                <Link to={`/taxon/${taxon.id}`}>
                  {taxon.name} <Authority>{taxon.authority}</Authority>
                </Link>
              </TaxonLeaf>
              {openTaxa.indexOf(taxon.id) > -1 && taxon.subTaxaCount > 0 && (
                <SubTaxa of={taxon.id} depth={depth + 1} />
              )}
            </Taxon>
          ))}
        </Taxa>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 1em;
`;
const Taxa = styled.ul`
  /* margin-bottom: 1.5em; */
  list-style: none;
`;
const Taxon = styled.li<{ depth: number }>`
  font-size: 1em;
  ${({ depth }) => depth < 2 && `margin-bottom: 1em;`}
`;
const TaxonLeaf = styled.div``;
const NumSubTaxa = styled.span`
  display: inline-block;
  cursor: pointer;
  border-radius: 50%;

  width: 20px;
  text-align: center;

  color: #aaa;

  &:hover {
    background: #ddd;
  }
`;
const Authority = styled.span`
  font-size: 0.8em;
`;

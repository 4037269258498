import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, SearchProps, SearchResultData } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { searchTaxaByName } from 'actions/floracommons/search-taxa';

type Props = {
  onSelect: (item: { id: string; title: string; description: string }) => void;
  placeholder?: string;
};

type ListItem = {
  id: string;
  title: string;
  description: string;
};

export default function TaxonNameSearch(props: Props) {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [results, setResults] = useState<ListItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchChange = (e: any, data: SearchProps) => {
    if (data.value === undefined) {
      return;
    }
    setSearchValue(data.value);
    if (data.value.length > 3) {
      setIsFetching(true);
      debounceSearch(data.value);
    } else {
      // reset results from a previous search
      if (results.length) {
        setResults([]);
      }
    }
  };
  const doSearch = async (value: string) => {
    console.log('Searching', value);
    const data = await searchTaxaByName(value);
    console.log('Search res', data);
    setResults(
      data.map(result => ({
        id: result.taxon.value,
        title: `${result.taxon.name} ${result.authority}`,
        description: result.taxon.value,
      })),
    );
    setIsFetching(false);
  };
  const debounceSearch = useCallback(debounce(doSearch, 600), []);

  const handleResultSelect = (e: any, { result }: { result: ListItem }) => {
    // debouncedSearchChangeHandler.cancel();
    setSearchValue('');
    setResults([]);
    props.onSelect(result);
  };

  return (
    <Search
      loading={isFetching}
      minCharacters={4}
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      results={results}
      value={searchValue}
      selectFirstResult={true}
      showNoResults={!isFetching && !results.length}
      placeholder={props.placeholder || 'Search by taxon name'}
    />
  );
}

import {
  Claim,
  ClaimSnakEntityValue,
  ClaimSnakQuantityValue,
  ClaimSnakStringValue,
  Entity,
} from 'wikibase-types/source';

import { wbApi } from '../_init';
import { getPID, getUID } from './pid-uid';
import { getClaimProvenances } from './provenance';
import { fcEndpoint } from '../../constants/endpoints';

export type TaxonDetail = {
  id: string;
  uid: string;
  identifiers: {
    gbif?: string;
    vascan?: string;
  };
  name: string;
  description: {
    text: string;
    id: string;
    order: number;
    provenance: Provenance;
  }[];
  distribution: MultiTextValue[];
  synonyms: MultiTextValue[];
  commonNames: MultiTextValue[];
  discussion: MultiTextValue[];
  provenances: string[];
  'taxon/parent taxon'?: SingleEntityValue;
  'taxon/authority'?: SingleTextValue;
  'taxon/rank': SingleTextValue;
  claims: Record<string, Claim[]>;
  simpleClaims: any;
};

type SingleEntityValue = {
  id: string;
};

type SingleTextValue = {
  value: string;
};

type MultiTextValue = {
  text: string;
  provenance: Provenance;
};

type Provenance = {
  id: string;
  ref: any; //wtf is this??
};

export async function fetchTaxonById(taxonId: string): Promise<TaxonDetail> {
  const url = wbApi.getEntities({
    ids: [taxonId],
    languages: ['en', 'fr'], // returns all languages if not specified
    //props: [ 'info', 'claims' ], // returns all data if not specified
    redirections: true, // defaults to true
  });
  return await fetch(url)
    .then(async response => {
      const resJson = await response.json();
      return await parseTaxonData(resJson);
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

/**
 * Replace all Wikibase Entity IDs in Claims with FC UIDs
 * @param claims Claims data from the WikiBase Entity API
 * @returns
 */
async function parseClaims(
  claims: Record<string, Claim[]> | undefined,
): Promise<Record<string, Claim[]>> {
  if (!claims) {
    throw new Error(`No claims for entity`);
  }

  return Object.fromEntries(
    Object.entries(claims).map(([key, value]) => [getUID(key), value]),
  );
}

/**
 * Consume Taxon data and output an object of data easier to use
 * in the frontend, by flattening the deep WikiBase data structure
 * @param json JSON data from the WikiBase Entity API
 */
async function parseTaxonData(json: any): Promise<TaxonDetail> {
  const data = wbApi.simplify.entities(json, {
    keepRichValues: true,
    keepQualifiers: true,
    keepReferences: true,
    keepIds: true,
    keepRanks: true,
  });

  if (!json.success) {
    throw new Error('Wikibase API request failed');
  }
  const entities: Entity[] = Object.values(json.entities);
  if (!entities.length) {
    throw new Error(`No entities returned by API for taxon`);
  }
  // const claimLabels = await addClaimLabels(entity.claims);
  if (entities.length) {
    const entity = entities[0];
    console.log(`Entity`, entity);

    const claims = await parseClaims(entity.claims as Record<string, Claim[]>);
    console.log(`Claims`, claims);
    const simpleClaims = wbApi.simplify.claims(claims, { keepAll: true });
    console.log(`Simplified claims`, simpleClaims);

    const getClaims = (uid: string): Claim[] | undefined =>
      claims[uid] ? claims[uid] : undefined;
    const getClaim = (uid: string): Claim | undefined =>
      getClaims(uid) && (getClaims(uid) as Claim[]).length
        ? (getClaims(uid) as Claim[])[0]
        : undefined;
    // const getLabel = (pid) => claimLabels.has(pid) ? claimLabels.get(pid).label : pid;
    const mapClaims = (uid: string, mapFn: (claim: Claim) => any) => {
      const selectedClaims = getClaims(uid);
      if (!selectedClaims || !selectedClaims.length) return [];
      return selectedClaims.map(mapFn);
    };

    const datatypes = {};

    const hasClaim = (uid: string): boolean => {
      if (getClaim(uid)) {
        return true;
      }
      return false;
    };

    const singleValue = (uid: string): SingleEntityValue | SingleTextValue => {
      const claimObj = getClaim(uid);
      // const prov = claimObj.references?.[0].snaks?.[getPID('core/provenance')]?.
      if (claimObj) {
        switch (claimObj?.mainsnak?.datatype) {
          case 'wikibase-item':
            return {
              id: (claimObj?.mainsnak?.datavalue as ClaimSnakEntityValue).value
                .id,
            };
          case 'external-id':
            return {
              value: (claimObj?.mainsnak?.datavalue as ClaimSnakStringValue)
                ?.value,
            };
          case 'quantity':
            return {
              value: (claimObj?.mainsnak?.datavalue as ClaimSnakQuantityValue)
                ?.value.amount,
            };
          case 'string':
          default:
            return {
              value: (claimObj?.mainsnak?.datavalue as ClaimSnakStringValue)
                ?.value,
            };
        }
      }
      throw new Error(`Claim is undefined ${uid}`);
    };

    const multiTextValue = (uid: string): MultiTextValue[] => {
      return mapClaims(uid, (claim: Claim) => ({
        text: claim.mainsnak?.datavalue?.value,
        provenance: getClaimProvenances(claim),
        claim: claim,
      }));
    };

    const statementIdRegexp = /^d0_s([0-9]+)$/;
    const getOrderFromStatementId = (id: string) =>
      id && statementIdRegexp.test(id)
        ? parseInt((id.match(statementIdRegexp) as RegExpMatchArray)[1])
        : 9999;
    const description = mapClaims(
      'taxon/description/fragment',
      (
        f: Claim,
      ): { text: string; id: string; order: number; provenance: any } => {
        const id = (
          f?.qualifiers?.[getPID('taxon/description/fragment id')][0]
            ?.datavalue as ClaimSnakStringValue
        )?.value;
        const numericId = getOrderFromStatementId(id);
        return {
          text: (f.mainsnak?.datavalue as ClaimSnakStringValue).value,
          id,
          order: numericId,
          provenance: getClaimProvenances(f),
        };
      },
    ).sort((a, b) => {
      a = a.order;
      b = b.order;
      return a > b ? 1 : a < b ? -1 : 0;
    });

    const provenances = Array.prototype
      .concat(...Object.values(claims))
      .reduce((uniqueProvs, claim: Claim) => {
        getClaimProvenances(claim).forEach(
          p => uniqueProvs.indexOf(p.id) < 0 && uniqueProvs.push(p.id),
        );
        return uniqueProvs;
      }, [] as string[]);

    // const allProvenances = Object.values(simpleClaims).reduce
    const name = getClaim('taxon/name')
      ? (getClaim('taxon/name')?.mainsnak?.datavalue as ClaimSnakStringValue)
          .value
      : 'Name missing';

    const taxon = {
      id: entity.id,
      uid: (singleValue('fc-uid') as SingleTextValue).value,
      identifiers: {
        gbif: hasClaim('identifiers/gbif')
          ? (singleValue('identifiers/gbif') as SingleTextValue).value
          : undefined,
        vascan: hasClaim('identifiers/vascan')
          ? (singleValue('identifiers/vascan') as SingleTextValue).value
          : undefined,
      },
      name: name,
      description: description,
      distribution: multiTextValue('taxon/distribution'),
      synonyms: multiTextValue('taxon/synonym'),
      commonNames: multiTextValue('taxon/common name'),
      discussion: multiTextValue('taxon/discussion'),
      provenances: provenances,
      'taxon/parent taxon': hasClaim('taxon/parent taxon')
        ? (singleValue('taxon/parent taxon') as SingleEntityValue)
        : undefined,
      'taxon/authority': hasClaim('taxon/authority')
        ? (singleValue('taxon/authority') as SingleTextValue)
        : undefined,
      'taxon/rank': singleValue('taxon/rank') as SingleTextValue,
      claims: claims,
      simpleClaims: simpleClaims,
    };

    console.log('Taxon', taxon);
    return taxon;
  } else {
    throw new Error('Invalid taxon data');
  }
}

export async function getCommonDistributionValues(
  queryOptions: { breakCache: boolean } = { breakCache: false },
) {
  const url = wbApi.sparqlQuery(`SELECT ?dist (COUNT(?dist) AS ?count)
  WHERE #
  {
     ?taxon wdt:${getPID('taxon/distribution')} ?dist.
  }
  GROUP BY ?dist
  HAVING(?count > 100)
  ORDER BY DESC(?count)`);

  return await fetch(`${url}${queryOptions.breakCache ? '&nocache=true' : ''}`)
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      // return data.map(row => ({name: row.dist}))
      // console.log(``, data)
      return data
        .map((row: { taxon: string; dist: string }) => row.dist)
        .sort();
    })
    .catch(err => {
      console.error(err);
    });
}

export type TaxonViaGBIF = {
  entity: string;
  uid: string;
  rank: string;
  name: string;
  gbifkey: string;
  uicnCategory: string;
};
export async function getTaxaByGBIFKeys(
  gbifKeys: string[],
): Promise<TaxonViaGBIF[]> {
  // const url = wbApi.sparqlQuery();

  return await fetch(fcEndpoint.sparqlEndpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/sparql-query',
      'User-Agent': 'floracommons-1',
      Accept: 'application/sparql-results+json',
    },
    body: `SELECT ?entity ?uid ?rank ?name ?gbifkey ?uicnCategory WHERE {
      VALUES ?keys {${gbifKeys.map(key => `'${key}'`).join(' ')}} 
      ?entity wdt:${getPID('core/instance of')} "taxon";
              wdt:${getPID('identifiers/gbif')} ?keys;
              wdt:${getPID('identifiers/gbif')} ?gbifkey;
              wdt:${getPID('fc-uid')} ?uid;
              wdt:${getPID('taxon/name')} ?name;
              wdt:${getPID('taxon/rank')}/rdfs:label ?rank;
              wdt:${getPID('taxon/iucn redlist category')} ?uicnCategory.
    }`,
  })
    .then(async response => {
      const data = wbApi.simplify.sparqlResults(await response.json());
      return data;
    })
    .catch(err => {
      console.error(err);
      throw new Error(err);
    });
}

import React from 'react';
import { getPID, getUID } from 'actions/floracommons/pid-uid';
import {
  Claim,
  ClaimSnakEntityValue,
  ClaimSnakStringValue,
} from 'wikibase-types/source';
import { isHuntsTerm } from 'actions/floracommons/hunts';
import styled from 'styled-components';
import HuntsTerm from '../../../components/hunts/HuntsTerm';

export function MorphValue(props: { claim: Claim }) {
  const claim = props.claim;
  if (!claim.qualifiers) {
    console.error('Claim does not have any qualifiers', claim);
    return null;
  }
  // the value of the statement is the value type
  // the actual value is stored as a qualifier
  const type = (claim.mainsnak?.datavalue as ClaimSnakEntityValue)?.value?.id;
  const typeUid = getUID(type);

  // for a simple value, just output the value
  if (typeUid === 'taxon/simple value') {
    const value = (
      claim.qualifiers[getPID('taxon/morphology statement value')]?.[0]
        ?.datavalue as ClaimSnakStringValue
    )?.value;
    const hasDefinition = isHuntsTerm(value);
    return (
      <span>
        {claim.qualifiers[getPID('taxon/morphology statement value modifier')]
          ?.length && (
          <em>
            {
              (
                claim.qualifiers[
                  getPID('taxon/morphology statement value modifier')
                ]?.[0]?.datavalue as ClaimSnakStringValue
              )?.value
            }
            &nbsp;
          </em>
        )}
        {hasDefinition ? <HuntsTerm>{value}</HuntsTerm> : value}
      </span>
    );
  }

  // for a range value, format with the correct text
  if (typeUid === 'taxon/range value') {
    const from = (
      claim.qualifiers[getPID('taxon/morphology statement value from')]?.[0]
        ?.datavalue as ClaimSnakStringValue
    )?.value;
    const to = (
      claim.qualifiers[getPID('taxon/morphology statement value to')]?.[0]
        ?.datavalue as ClaimSnakStringValue
    )?.value;
    if (from !== undefined && to !== undefined) {
      return (
        <span>
          <span>{from}</span> - <span>{to}</span>
        </span>
      );
    }
    if (from === undefined && to !== undefined) {
      return (
        <span>
          up to <span>{to}</span>
        </span>
      );
    }
    if (from !== undefined && to === undefined) {
      return (
        <span>
          from <span>{from}</span>
        </span>
      );
    }
    return <span>Unspecified range value</span>;
  }
  return <em>Error rendering morph value</em>;
}

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getAllValuesForStructureAndCharacter } from 'actions/floracommons/morphology';
import TextPlaceholder from 'components/text-placeholder';
import { isHuntsTerm } from 'actions/floracommons/hunts';
import HuntsTerm from 'components/hunts/HuntsTerm';

type Props = {
  structureId: string;
  characterId: string;
};

export default function StructureCharacterValues(props: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    setValues([]);
    getAllValuesForStructureAndCharacter(
      props.structureId,
      props.characterId,
    ).then(values => {
      if (mounted) {
        setIsLoading(false);
        setValues(values.map(row => row.value));
      }
    });
    return () => {
      mounted = false;
    };
  }, [props.structureId, props.characterId]);

  if (isLoading) {
    return (
      <Container>
        <TextPlaceholder />
      </Container>
    );
  }
  return values.length ? (
    <Container>
      <ValueList>
        {values.map(v => (
          <Value key={v}>
            {isHuntsTerm(v) ? <HuntsTerm>{v}</HuntsTerm> : v}
          </Value>
        ))}
      </ValueList>{' '}
    </Container>
  ) : (
    <Container>No values defined.</Container>
  );
}

const Container = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: #999;
`;
const ValueList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const Value = styled.span`
  font-size: 0.9em;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 3px;
  margin-bottom: 3px;
  background-color: white;
`;

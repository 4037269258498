export const IUCNRedlistLabels = {
  NE: 'Not Evaluated',
  DD: 'Data Deficient',
  LC: 'Least Concern',
  NT: 'Near Threatened',
  VU: 'Vulnerable',
  EN: 'Endangered',
  CR: 'Critically Endangered',
  EW: 'Extinct in the Wild',
  EX: 'Extinct',
};
export enum IUCNRedlistCategories {
  NE,
  DD,
  LC,
  NT,
  VU,
  EN,
  CR,
  EW,
  EX,
}

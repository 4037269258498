import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import { Segment, Dropdown, List, Placeholder, Loader, Label, Button, Popup } from "semantic-ui-react";

import LayoutWidth from 'components/layout-width';
import NavFrame, { Nav } from 'components/nav-frame';

import SubTaxa from './subtaxa';

const HierarchyLayoutWidth = styled(LayoutWidth)`
  font-size: 1.3em;
`;

type Props = {};

export default function TaxonHierarchy(props: Props) {
  return (
    <NavFrame>
      <HierarchyLayoutWidth>
        <SubTaxa />
      </HierarchyLayoutWidth>
    </NavFrame>
  );
}
